import styled from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async";

export const AsyncPicker = styled(AsyncSelect)`
  font-size: 14px;

  div {
    border-color: #e2e8f0;
  }

  &:active {
    background-color: yellow;
  }
`;

export const Picker = styled(Select)`
  font-size: 14px;

  div {
    border-color: #e2e8f0;
  }

  &:active {
    background-color: yellow;
  }
`;
