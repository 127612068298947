import React, { useEffect, useMemo, useState } from "react";
import { SimpleGrid, FormControl } from "@chakra-ui/react";
import { Label } from "./styles";
import { SelectOptions, User, AddressAndContactForm } from "types";
import { parseAddressAndContactForm } from "helper/user";
import { useSaveUser } from "hooks/saveUser";
import { api } from "shared/api";
import { Select } from "components/Form/Select";
import { Form } from "components/Form";
import { Input } from "components/Form/Input";

const initialValues: AddressAndContactForm = {
  logradouro: "",
  bairro: "",
  cep: "",
  paisId: 0,
  estadoId: 0,
  cidadeId: 0,
  foneFixo: "",
  foneCelular: "",
  skype: "",
  linkedin: "",
};

type Props = {
  user?: User;
  onClose: () => void;
};

export const AddressAndContact = ({ user, onClose }: Props) => {
  const [initial, setInitial] = useState<AddressAndContactForm>(initialValues)
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [currentCountryId, setCurrentCountryId] = useState<number>();
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [currentStateId, setCurrentStateId] = useState<number>();
  const [cities, setCities] = useState<SelectOptions[]>([]);
  const doRequest = useSaveUser();

  useEffect(() => {
    api.country.list().then(setCountries);
    api.state.list().then(setStates);
  }, []);

  useEffect(() => {
    if (!user) return;
    setInitial({
      ...user,
      paisId: user?.pais?.id,
      estadoId: user?.estado?.id,
    });
    if (user.pais) {
      setCurrentCountryId(user.pais.id);
    }
    if (user.estado) {
      setCurrentStateId(user.estado.id);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
    if (!currentStateId) return;
    api.state.getCities(currentStateId).then((data) => {
      setCities(data);
      setInitial((old) => {
        return { ...old, cidadeId: user?.cidade.id}
      })
    });
  }, [user, currentStateId]);

  const isBrazilSelected = useMemo(
    () => Number(currentCountryId) === 33,
    [currentCountryId]
  );

  const handleSubmit  = (values: any) => {
    setLoading(true);
    const parsed = parseAddressAndContactForm(values);
    doRequest(Number(user?.id), parsed, setLoading, onClose);
  }

  return (
    <Form
      initial={initial}
      onSubmit={handleSubmit}
      okLabel="Salvar"
      isLoading={loading}
    >
      <SimpleGrid columns={2} spacing="10px">
        <FormControl>
          <Label>Logradouro</Label>
          <Input name="logradouro" />
        </FormControl>
        <FormControl>
          <Label>Bairro</Label>
          <Input name="bairro" />
        </FormControl>
        <FormControl>
          <Label>CEP</Label>
          <Input name="cep" />
        </FormControl>
        <FormControl>
          <Label>País</Label>
          <Select
            options={countries}
            name="paisId"
          />
        </FormControl>
        {isBrazilSelected && (
          <FormControl>
            <Label>Estado</Label>
            <Select
              options={states}
              name="estadoId"
            />
          </FormControl>
        )}
        {isBrazilSelected && (
          <FormControl>
            <Label>Cidade</Label>
            <Select
              options={cities}
              name="cidadeId"
            />
          </FormControl>
        )}
        <FormControl>
          <Label>Telefone fixo</Label>
          <Input name="foneFixo" />
        </FormControl>
        <FormControl isRequired>
          <Label>Telefone celular</Label>
          <Input name="foneCelular" />
        </FormControl>
        <FormControl>
          <Label>Skype</Label>
          <Input name="skype" />
        </FormControl>
        <FormControl>
          <Label>Linkedin</Label>
          <Input name="linkedin" />
        </FormControl>
      </SimpleGrid>
    </Form>      
  );
};
