import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Subtheme1, Theme } from 'types'
import { Subtheme1Modal } from './modal'

export const Subtheme1Page = () => {
  const initalState = { nome: '', tema: { id: 0 } }
  const [loading, isLoading] = useState(false)
  const [themes, setThemes] = useState([] as Theme[])
  const [subthemes1, setSubthemes1] = useState([] as Subtheme1[])
  const [newSubthemes1, setNewSubthemes1] = useState(initalState)
  const [pickedSubTheme1, setPickedSubTheme1] = useState({} as Subtheme1)
  const [isDialogOpen, openDialog] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const toast = useSnackbar()

  const fetchThemes = () => {
    api.theme.list().then(setThemes)
  }

  const fetchSubthemes1 = async () => {
    isLoading(true)
    const subthemes1 = await api.subtheme1.list()
    setSubthemes1(subthemes1)
    isLoading(false)
  }

  const createTheme = async () => {
    isLoading(true)
    try {
      await api.subtheme1.post(newSubthemes1)
      toast({
        title: `Tema ${newSubthemes1.nome} cadastrado com sucesso`,
        status: 'success'
      })
      fetchSubthemes1()
      setNewSubthemes1(initalState)
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar o subtema1.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  const deleteTheme = async (id: number) => {
    isLoading(true)
    try {
      await api.subtheme1.delete(id)
      toast({
        title: `Tema ${newSubthemes1.nome} removido com sucesso`,
        status: 'success'
      })
      fetchSubthemes1()
      setNewSubthemes1(initalState)
    } catch (error) {
      toast({
        title: 'Não foi possível remover o subtema1.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    fetchThemes()
    fetchSubthemes1()
  }, [])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='50%' mt={4} style={{ marginLeft: '25%' }}>
        <Flex justify='space-between' mb='40px' w='100%' gap={4}>
          <FormControl>
            <FormLabel>Temas</FormLabel>
            <Select
              name='temas'
              placeholder='Selecione...'
              value={newSubthemes1.tema.id}
              onChange={({ target }) =>
                setNewSubthemes1({
                  ...newSubthemes1,
                  tema: { id: parseInt(target.value) }
                })
              }
            >
              {themes.map(themes => (
                <option key={themes.id} value={themes.id}>
                  {themes.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl w='80%'>
            <FormLabel>Nome</FormLabel>
            <Input
              value={newSubthemes1.nome}
              onChange={({ target }) =>
                setNewSubthemes1({ ...newSubthemes1, nome: target.value })
              }
            />
          </FormControl>

          <Flex direction='column' justify='flex-end'>
            <Button
              colorScheme='yellow'
              size='md'
              onClick={createTheme}
              disabled={newSubthemes1.nome === ''}
            >
              Criar
            </Button>
          </Flex>
        </Flex>

        {loading ? (
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='md'
            style={{ marginLeft: '50%' }}
          />
        ) : (
          <Flex justify='center' w='100%' mb='40px'>
            <TableContainer>
              <Table variant='simple' size='lg'>
                <TableCaption placement='top'>
                  <b>{themes.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Tema</Th>
                    <Th>Nome</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subthemes1.map(subtheme1 => (
                    <Tr key={subtheme1.id}>
                      <Td>{subtheme1.tema.nome}</Td>
                      <Td>{subtheme1.nome}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color='gray'
                          icon={faPenToSquare}
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          title='Editar'
                          onClick={() => {
                            setPickedSubTheme1(subtheme1)
                            openModal(true)
                          }}
                        />
                        <FontAwesomeIcon
                          color='red'
                          icon={faTrashCan}
                          title='Remover'
                          onClick={() => {
                            setPickedSubTheme1(subtheme1)
                            openDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>

      {pickedSubTheme1.id && (
        <RemovalConfirmationDialog
          targetName='Subtema1'
          confirmationMessage={`Deseja realmente remover o subtema1 <strong>${pickedSubTheme1.nome}</strong>?`}
          targetId={pickedSubTheme1.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
          }}
          onRemoveTarget={deleteTheme}
        />
      )}

      <Subtheme1Modal
        open={isModalOpen}
        handleClose={() => {
          fetchSubthemes1()
          openModal(false)
        }}
        subtheme1ToEdit={pickedSubTheme1}
      />
    </Layout>
  )
}
