import { Select as ChakraSelect, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { SelectOptions } from "types";
import { Field } from "formik";

type Props = {
  name: string;
  placeholder?: string;
  options: SelectOptions[];
  required?: boolean;
  validate?: (value: string | number) => boolean;
  onChange?: (id: number | string) => void;
}

export const Select = ({
  placeholder,
  options,
  name,
  required,
  validate,
  onChange,
}: Props) => {

  const Component = ({ field, form }: any) => {
    const isInvalid = () => form.errors[name] && form.touched[name];

    const errorMessage = () => form.errors[name]

    return (
      <FormControl 
        isRequired={required}
        isInvalid={isInvalid()}
        >
        <ChakraSelect 
          {...field }
          value={field.value}
          placeholder={placeholder}
          name={name}
          validate={validate}
          onChange={(event) => {
            form.setFieldValue(name, event.target.value)
            onChange && onChange(event.target.value)}
          }
        >
          {options.map((option) => (
            <option value={option.id} key={option.id}>{option.nome}</option>
          ))}
        </ChakraSelect>
        <FormErrorMessage> {errorMessage()} </FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Field 
      name={name} 
      placeholder={placeholder} 
      component={Component} 
    />
  )
};
