import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useSnackbar } from "hooks";
import { api } from "shared/api";
import { EducationLevel } from "types/scholarity";

export const NewCourse = () => {
  const toast = useSnackbar();
  const initialType: EducationLevel = "MEDIO";

  const [name, setName] = useState("");
  const [type, setType] = useState(initialType);
  const [level, setLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const createCourse = async () => {
    const user = {
      nome: name,
      grau: type,
      peso: level,
    };

    try {
      setIsLoading(true);
      await api.course.create(user);
      toast({
        title: `Curso ${name} criado com sucesso`,
        description: "",
        status: "success",
      });
      resetValues();
    } catch (error) {
      toast({
        title: `Não foi possível criar o curso ${name}`,
        description: "",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetValues = () => {
    setName("");
    setType(initialType);
    setLevel(0);
  };

  const handleTypeChange = ({ target }: any) => {
    const { value } = target;
    setType(value);
  };
  return (
    <Flex direction="column" w="80%" mx="auto">
      <Text fontSize="2xl" mx="auto" my="20px">
        Gerenciamento de Cursos
      </Text>
      <Flex direction="row" justify="space-around" w="100%">
        <FormControl isRequired mr="20px">
          <FormLabel>Nome</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(input) => setName(input.target.value)}
          />
        </FormControl>
        <FormControl isRequired mr="20px">
          <FormLabel>Grau</FormLabel>
          <Select value={type} onChange={handleTypeChange}>
            <option value="MEDIO">Médio</option>
            <option value="SUPERIOR">Superior</option>
          </Select>
        </FormControl>
        <FormControl isRequired mr="20px">
          <FormLabel>Peso</FormLabel>
          <Select
            value={level}
            onChange={(input) => setLevel(Number(input.target.value))}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Select>
        </FormControl>
        <Button
          colorScheme="teal"
          style={{ width: "130px" }}
          mt="30px"
          onClick={createCourse}
        >
          Criar
        </Button>
      </Flex>
      {isLoading && (
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="md"
          style={{ margin: "auto" }}
        />
      )}
    </Flex>
  );
};
