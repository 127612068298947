import { DefaultDataset } from "../types";

export type PieChartProps = {
  title: string;
  labels: string[];
  datasets: DefaultDataset[];
  formatter?: (value: number, context: any) => string;
};

export const initialDatasetState: DefaultDataset = {
  backgroundColor: [""],
  data: [0],
};
