import styled from "styled-components";

type ContainerProps = {
  bgColor: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin: 10px 0px;
  border: 1px solid gainsboro;
`;

export const AvatarBox = styled.div`
  display: flex;
  height: 130px;
  width: 125px;
  justify-content: center;
  align-items: center;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 5px 10px;
  height: 130px;
  justify-content: center;
  font-size: 14px;
`;

export const ActionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;

  svg {
    cursor: pointer;
  }
`;

export const Anchor = styled.a`
  color: blue;
  font-weight: bold;
`;
