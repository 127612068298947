import { Input } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { Props } from "./types";

export const Chooser = ({ setFile }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Input paddingTop="4px" type="file" size="sm" onChange={handleChange} />
  );
};
