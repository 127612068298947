import {
  Flex,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Layout } from "components/Layout";
import { useSnackbar } from "hooks";
import { useContext, useState } from "react";
import { api } from "shared/api";
import { InstitutionFormValue } from "types";
import { InstitutionForm } from "./InstitutionForm";
import { InstitutionsContext } from "./InstitutionsProvider";
import { SearchResult } from "./SearchResult";
import { initialState } from "./types";

export const Institutions = () => {
  const toast = useSnackbar();
  const [institution, setInstitution] = useState(initialState);
  const {
    uploadImage: handleImageUpload,
    fetchInstitutions,
    institutions,
    setInstitutions,
    loading,
    isLoading,
  } = useContext(InstitutionsContext);

  const create = async (institution: InstitutionFormValue) => {
    isLoading(true);
    try {
      const newInstitution = await api.institution.create(institution);
      setInstitution(newInstitution);
      toast({
        title: `Instituição ${institution.nome} cadastrada com sucesso`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível cadastrar a instituição.",
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  return (
    <Layout header menu footer>
      <Flex direction={"column"} w="100%">
        <Flex
          direction="column"
          w="40%"
          style={{ marginRight: "30%", marginLeft: "30%" }}
        >
          <Text fontSize="2xl" mx="auto" my="20px">
            Gerenciamento de Instituições
          </Text>
          <Tabs>
            <TabList>
              <Tab>Pesquisar</Tab>
              <Tab>Cadastrar</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <InstitutionForm
                  okLabel="Pesquisar"
                  cancelLabel="Limpar"
                  loading={loading}
                  search={fetchInstitutions}
                  onResetResult={() => setInstitutions([])}
                />
              </TabPanel>
              <TabPanel>
                <InstitutionForm
                  okLabel="Cadastrar"
                  cancelLabel="Limpar"
                  loading={loading}
                  uploadImage={(event) =>
                    handleImageUpload(institution.id!!, event)
                  }
                  create={create}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        {institutions.length > 0 && (
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            <b>{institutions.length}</b> instituições encontradas
          </p>
        )}

        <SimpleGrid columns={4} spacing={5} padding={5}>
          {institutions.length > 0 && (
            <SearchResult institutions={institutions} />
          )}
        </SimpleGrid>
      </Flex>
    </Layout>
  );
};
