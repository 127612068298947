import styled from "styled-components";
import { theme } from "../../../shared/theme";

export const Wrapper = styled.div`
  height: 75px;
  width: 100%;
  background-color: ${theme.colors.green};
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 500;
  margin-left: 10px;
  font-size: 20px;
  color: ${theme.colors.yellow};
`;
