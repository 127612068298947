import React, { useEffect, useState } from "react";
import { PieChart } from "components/Chart/PieChart";
import { api } from "../../../../shared/api";
import { useSnackbar } from "hooks";
import { Flex } from "@chakra-ui/react";
import { StatsTable } from "components/Table/StatsTable";
import { AditionalInfo } from "./AditionalInfo";
import { initialDatasetState } from "components/Chart/PieChart/types";
import { initialStatState } from "../types";
import { initialInformedEthnicityState, bgColor } from "./types";

export const RaceChart = () => {
  const toast = useSnackbar();
  const [ethnicities, setEthnicities] = useState([initialStatState]);
  const [datasets, setDatasets] = useState([initialDatasetState]);
  const [labels, setLabels] = useState([""]);
  const [informedEthnicity, setInformedEthnicity] = useState(
    initialInformedEthnicityState
  );

  useEffect(() => {
    async function fetchEthnicities() {
      const ethnicities = await api.stats.ethnicitiesTotals();
      return ethnicities;
    }

    fetchEthnicities()
      .then((ethnicities) => {
        setLabels(ethnicities.etnias.map((ethnicity: any) => ethnicity.nome));
        setDatasets([
          {
            backgroundColor: bgColor,
            data: ethnicities.etnias.map((ethnicity: any) => ethnicity.total),
          },
        ]);
        setEthnicities(
          ethnicities.etnias.map((ethnicity: any) => ({
            label: ethnicity.nome,
            total: ethnicity.total,
          }))
        );
        setInformedEthnicity(ethnicities.informaram);
      })
      .catch((error) => {
        toast({
          title: "Não foi possível atualizar estatísticas de etnia",
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  function calculatePercentage(value: number, context: any): string {
    return `${((value / informedEthnicity.sim.total) * 100).toFixed(1)}%`;
  }

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="15px" mb="20px">
      <PieChart
        title={"Usuários por raça ou cor - Gráfico"}
        labels={labels}
        datasets={datasets}
        formatter={calculatePercentage}
      />
      <Flex direction={"column"} alignItems={"center"}>
        <StatsTable
          title="Usuários por raça ou cor - Totais"
          headers={["Raça ou cor", "Total"]}
          values={ethnicities}
        />
        <AditionalInfo
          yesTotal={informedEthnicity.sim.total}
          yesPercentage={informedEthnicity.sim.percentual}
          noTotal={informedEthnicity.nao.total}
          noPercentage={informedEthnicity.nao.percentual}
        />
      </Flex>
    </Flex>
  );
};
