import { Flex } from '@chakra-ui/react'
import { Layout } from 'components/Layout'
import { CustomSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { GroupedTrail } from 'types/trail'
import { GroupedTrailsList } from './GroupedTrailsList'
import { NewTrail } from './NewTrail'

export const TrailPage = () => {
  const [loading, isLoading] = useState(false)
  const [groupedTrails, setGroupedTrails] = useState([] as GroupedTrail[])

  const fetchGrouped = async () => {
    isLoading(true)
    const result = await api.trail.grouped()
    setGroupedTrails(result)
    isLoading(false)
  }

  useEffect(() => {
    fetchGrouped()
  }, [])

  return (
    <Layout header menu footer>
      <Flex w='100%' direction='column'>
        <NewTrail refreshGroupedTrails={fetchGrouped} />

        <CustomSpinner loading={loading}>
          <GroupedTrailsList
            trails={groupedTrails}
            refreshTrails={fetchGrouped}
          />
        </CustomSpinner>
      </Flex>
    </Layout>
  )
}
