import { format, parseISO } from "date-fns";
import { useSnackbar } from "hooks";
import React, { useEffect, useState } from "react";
import { api } from "../../../../shared/api";
import { SuitableProfileChart } from "./SuitableProfile";

export const SuitableProfilesChart = () => {
  const toast = useSnackbar();
  const [labels, setLabels] = useState([]);
  const [totals, setTotals] = useState([]);
  const [percentages, setPercentages] = useState([]);

  useEffect(() => {
    async function fetchSuitableTotals() {
      const suitableTotals = await api.stats.suitableTotals();
      return suitableTotals;
    }

    fetchSuitableTotals()
      .then((data) => {
        setLabels(data.map((el: any) => format(parseISO(el.data), "dd/MM")));
        setTotals(data.map((el: any) => el.total));
        setPercentages(data.map((el: any) => el.percentual));
      })
      .catch((error) => {
        toast({
          title: `Não foi possível atualizar estatísticas para adequação de perfis`,
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <>
      <SuitableProfileChart
        title="Percentual de adequação dos perfis"
        legend="Percentual"
        labels={labels}
        values={percentages}
      />
      <SuitableProfileChart
        title="Total de perfis adequados"
        legend="Total"
        labels={labels}
        values={totals}
      />
    </>
  );
};
