import React, { useContext, useState } from "react";
import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Course } from "types";
import { ConfirmatinDialog } from "./ConfirmDialog";
import { EditModal } from "./EditModal";
import { SearchResultProps } from "./types";
import { SearchContext } from "../Provider";

export const SearchResult = ({ courses, editCourse }: SearchResultProps) => {
  const { setCourse } = useContext(SearchContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEdit = (courseToEdit: Course) => {
    setCourse(courseToEdit);
    setShowEditModal(true);
  };

  const handleRemove = (courseToRemove: Course) => {
    setCourse(courseToRemove);
    onOpen();
  };

  return (
    <Box w="60%" m="auto" mb="30px">
      <EditModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
      />
      <ConfirmatinDialog isOpen={isOpen} onClose={onClose} />
      {courses.length > 0 ? (
        <TableContainer>
          <Table variant="striped" size="sm">
            <TableCaption placement="top">
              <strong>{courses.length}</strong> cursos encontrados
            </TableCaption>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Nome</Th>
                <Th>Peso</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {courses.map((course) => (
                <Tr key={course.id}>
                  <Td>{course.id}</Td>
                  <Td>{course.nome}</Td>
                  <Td>{course.peso}</Td>
                  <Td>
                    <FontAwesomeIcon
                      color="gray"
                      icon={faPenToSquare}
                      style={{ marginRight: "15px", cursor: "pointer" }}
                      title="Editar"
                      onClick={() => handleEdit(course)}
                    />
                    <FontAwesomeIcon
                      color="red"
                      icon={faTrashCan}
                      title="Remover"
                      onClick={() => handleRemove(course)}
                      style={{ cursor: "pointer" }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
};
