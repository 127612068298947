import { useDispatch, useSelector } from "react-redux";
import {
  setUser as setUserAction,
  setToken as setTokenAction,
} from "store/slices/user";
import { Logged, User } from "types";

export const useUser = () => {
  const dispatch = useDispatch();

  const logged: Logged = useSelector((state: any) => state.user);

  const setUser = (user: User) => {
    dispatch(setUserAction(user));
  };

  const setToken = (token: string) => {
    dispatch(setTokenAction(token));
  };

  return {
    logged,
    setUser,
    setToken,
  };
};
