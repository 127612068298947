import { Flex, Spinner } from '@chakra-ui/react'

type SpinnerProps = {
  loading: boolean
  children: JSX.Element
}
export const CustomSpinner = ({ loading, children }: SpinnerProps) => {
  return (
    <>
      {loading ? (
        <Flex justify={'center'} mt={5}>
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='lg'
          />
        </Flex>
      ) : (
        children
      )}
    </>
  )
}
