const { REACT_APP_ENV } = process.env;

const envs = {
  dev: {
    baseUrl: "http://174.138.38.199:7000",
  },
  hml: {
    baseUrl: "http://174.138.38.199:7000",
  },
  prd: {
    baseUrl: "https://clientgateway.comunidadeempodera.com.br",
  },
};

export const config = {
  api: {
    baseUrl: envs[REACT_APP_ENV].baseUrl,
  },
  imagesPath: `${envs[REACT_APP_ENV].baseUrl}/static/images`,
  tmpPath: `${envs[REACT_APP_ENV].baseUrl}/static/tmp`,
  curriculosPath: `${envs[REACT_APP_ENV].baseUrl}/static/curriculos`,
  pareceresPath: `${envs[REACT_APP_ENV].baseUrl}/static/pareceres`,
  perfisComportamentaisPath: `${envs[REACT_APP_ENV].baseUrl}/static/etalent`,
};
