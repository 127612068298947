import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import { Counter } from "components/Counter";
import { Props } from "./types";

export const Section = ({ lines }: Props) => {
  return (
    <Accordion defaultIndex={[0]} w={"100%"}>
      {lines.map((line, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box textAlign="left">{line.title}</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} mr={3}>
            <Flex
              direction={"row"}
              wrap={"wrap"}
              justify={"flex-start"}
              gap={5}
              p={6}
            >
              {line.items.map((item) => (
                <Counter
                  key={item.current}
                  current={item.current}
                  aim={item.aim}
                  showAim={item.showAim}
                  label={item.label}
                />
              ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
