import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex
} from '@chakra-ui/react'
import {} from '@fortawesome/free-solid-svg-icons'
import { CustomSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Combo } from 'types/trail'
import { EditCombo } from './EditCombo'
import { ItemsTable } from './ItemsTable'
import { NewItem } from './NewITem'

type ComboModalProps = {
  isOpen: boolean
  onClose: () => void
  combo: Combo
}

export const ComboModal = ({ isOpen, onClose, combo }: ComboModalProps) => {
  const [loading, isLoading] = useState(false)
  const [items, setItems] = useState([] as any[])

  const fetchItems = async () => {
    isLoading(true)
    const result = await api.combo.getItems(combo.id)
    setItems(result)
    isLoading(false)
  }

  useEffect(() => {
    fetchItems()
    //api.trail.getPositions().then(setPositions)

    /* setComboToUpdate({
      id: combo.id,
      name: combo.nome,
      isHidden: combo.oculto,
      position: combo.cargo,
      challenge: combo.desafio
    }) */
  }, [])

  return (
    <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Combo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={5}>
            <EditCombo combo={combo} onClose={onClose} />
            <NewItem comboId={combo.id} refreshItems={fetchItems} />
          </Flex>

          <CustomSpinner loading={loading}>
            <ItemsTable
              comboId={combo.id}
              items={items}
              refreshItems={fetchItems}
              onClose={onClose}
            />
          </CustomSpinner>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
