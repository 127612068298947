import { Item } from "types"

export type SearchParams = {
  id?: number
  titulo?: string
  tituloReduzido?: string
  media?: string
  habilitado?: boolean
  categoria?: number
  tema?: number
  subtema1?: number
  subtema2?: number
  subtema3?: number
}

export const initialState: Item = {
  titulo: '',
  tituloReduzido: '',
  habilitado: false,
  sinopse: '',
  foto: 'foto',
  link: '',
  media: '',
  nota: 0,
  recomendado: false
}