import React from "react";

import { Flex } from "@chakra-ui/react";
import { LineChart } from "components/Chart/LineChart";
import { SuitableProfilesProps } from "../types";

export const SuitableProfileChart = ({
  title,
  legend,
  labels,
  values,
}: SuitableProfilesProps) => {
  const datasets = [
    {
      label: legend,
      borderColor: "blue",
      backgroundColor: "lightblue",
      data: values,
    },
  ];

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="25px">
      <LineChart title={title} labels={labels} datasets={datasets} />
    </Flex>
  );
};
