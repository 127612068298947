import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react'
import {
  faCalendarDay,
  faFont,
  faImagePortrait,
  faLink
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSnackbar } from 'hooks'
import { useContext, useEffect, useMemo, useState } from 'react'
import { api } from 'shared/api'
import { Category, Item, Subtheme1, Subtheme2, Subtheme3, Theme } from 'types'
import { ItemContext } from './Provider'
import { initialState } from './types'

type ContentModalProps = {
  isOpen: boolean
  onClose: () => void
  itemToEdit?: Item
}

export const ContentModal = ({
  isOpen,
  onClose,
  itemToEdit
}: ContentModalProps) => {
  const toast = useSnackbar()
  const [themes, setThemes] = useState([] as Theme[])
  const [categories, setCategories] = useState([] as Category[])
  const [content, setContent] = useState(initialState)
  const [subtheme1, setSubtheme1] = useState([] as Subtheme1[])
  const [subtheme2, setSubtheme2] = useState([] as Subtheme2[])
  const [subtheme3, setSubtheme3] = useState([] as Subtheme3[])
  const [photo, setPhoto] = useState(new FormData())
  const action = content.id ? 'Editar' : 'Cadastrar'
  const { fetchItems } = useContext(ItemContext)
  const linkLabel = useMemo(
    () =>
      content.media === 'VIDEO'
        ? '(apenas o código do vídeo no YouTube)'
        : '(link para o documento)',
    [content.media]
  )

  const handleSave = () => {
    if (content.id) {
      onEdit()
    } else {
      onSave()
    }
  }

  const onEdit = async () => {
    try {
      delete content.categoria
      delete content.tema
      delete content.subtema1
      delete content.subtema2
      delete content.subtema3

      await api.items.put(Number(content.id), content)

      if (photo.has('foto')) {
        await api.items.upload(content.id!!, photo)
      }
      fetchItems()
      toast({
        title: 'Área atualizada com sucesso',
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar a área',
        status: 'error'
      })
    }
  }

  const onSave = async () => {
    try {
      const { id } = await api.items.post(content)
      if (photo.has('foto')) {
        await api.items.upload(id!!, photo)
      }
      toast({
        title: `Conteúdo cadastrado com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar o conteúdo.',
        status: 'error'
      })
    } finally {
      onClose()
    }
  }

  function handleClose () {
    setContent(initialState)
    onClose()
  }

  function onThemeChange ({ target }: any) {
    setSubtheme1([])
    setSubtheme2([])
    setSubtheme3([])

    const themeId = parseInt(target.value)
    if (!isNaN(themeId)) {
      api.subtheme1.getSubthemes(themeId).then(setSubtheme1)
      setContent({ ...content, temaId: themeId })
      return
    }

    setContent({ ...content, temaId: 0 })
  }

  function onSubTheme1Change ({ target }: any) {
    const subtheme1Id = parseInt(target.value)
    if (!isNaN(subtheme1Id)) {
      api.subtheme2.getSubthemes(subtheme1Id).then(setSubtheme2)
      setContent({ ...content, subtema1Id: subtheme1Id })
      return
    }

    setContent({ ...content, subtema1Id: 0 })
  }

  function onSubTheme2Change ({ target }: any) {
    const subtheme2Id = parseInt(target.value)
    if (!isNaN(subtheme2Id)) {
      api.subtheme3.get(subtheme2Id).then(setSubtheme3)
      setContent({ ...content, subtema2Id: subtheme2Id })
      return
    }

    setContent({ ...content, subtema2Id: 0 })
  }

  function onSubTheme3Change ({ target }: any) {
    const subtheme3Id = parseInt(target.value)
    if (!isNaN(subtheme3Id)) {
      setContent({ ...content, subtema3Id: subtheme3Id })
    }

    setContent({ ...content, subtema3Id: 0 })
  }

  useEffect(() => {
    api.theme.list().then(setThemes)
    api.categories.list().then(setCategories)

    if (itemToEdit) {
      const item = {
        ...itemToEdit
      }

      if (itemToEdit.categoria) {
        item.categoriaId = itemToEdit.categoria.id
      }

      if (itemToEdit.tema) {
        item.temaId = itemToEdit.tema.id
      }

      setContent(item)
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{action} Conteúdo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt={2}>
            <FormLabel>Título</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<FontAwesomeIcon color='lightGray' icon={faFont} />}
              />
              <Input
                type='text'
                value={content.titulo}
                onChange={({ target }) =>
                  setContent({ ...content, titulo: target.value })
                }
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Título Reduzido</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<FontAwesomeIcon color='lightGray' icon={faFont} />}
              />
              <Input
                type='text'
                value={content.tituloReduzido}
                onChange={({ target }) =>
                  setContent({ ...content, tituloReduzido: target.value })
                }
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Sinopse</FormLabel>
            <Textarea
              value={content.sinopse}
              onChange={({ target }: any) =>
                setContent({
                  ...content,
                  sinopse: target.value
                })
              }
              size='sm'
            />
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Data ou Ano</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={
                  <FontAwesomeIcon color='lightGray' icon={faCalendarDay} />
                }
              />
              <Input
                type='text'
                value={content.data}
                onChange={({ target }) =>
                  setContent({ ...content, data: target.value })
                }
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Categoria</FormLabel>
            <Select
              name='categoria'
              placeholder='Selecione...'
              value={content.categoriaId || ''}
              onChange={({ target }) =>
                setContent({
                  ...content,
                  categoriaId: parseInt(target.value)
                })
              }
            >
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Tema</FormLabel>
            <Select
              name='tema'
              placeholder='Selecione...'
              value={content.temaId || ''}
              onChange={onThemeChange}
            >
              {themes.map(theme => (
                <option key={theme.id} value={theme.id}>
                  {theme.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          {subtheme1.length > 0 && (
            <FormControl mt={2}>
              <FormLabel>Subtema 1</FormLabel>
              <Select
                name='subtema1'
                placeholder='Selecione...'
                value={content.subtema1Id || ''}
                onChange={onSubTheme1Change}
              >
                {subtheme1.map(subtheme1 => (
                  <option key={subtheme1.id} value={subtheme1.id}>
                    {subtheme1.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {subtheme2.length > 0 && (
            <FormControl mt={2}>
              <FormLabel>Subtema 2</FormLabel>
              <Select
                name='subtema2'
                placeholder='Selecione...'
                value={content.subtema2Id || ''}
                onChange={onSubTheme2Change}
              >
                {subtheme2.map(subtheme2 => (
                  <option key={subtheme2.id} value={subtheme2.id}>
                    {subtheme2.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {subtheme3.length > 0 && (
            <FormControl mt={2}>
              <FormLabel>Subtema 3</FormLabel>
              <Select
                name='subtema3'
                placeholder='Selecione...'
                value={content.subtema3Id || ''}
                onChange={onSubTheme3Change}
              >
                {subtheme3.map(subtheme3 => (
                  <option key={subtheme3.id} value={subtheme3.id}>
                    {subtheme3.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl mt={2}>
            <FormLabel>Foto</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={
                  <FontAwesomeIcon color='lightGray' icon={faImagePortrait} />
                }
              />
              <Input
                name='foto'
                type='file'
                pt='8px'
                onChange={({ target }: any) => {
                  const file = target.files[0]
                  const formData = new FormData()
                  formData.append('foto', file, file.name)
                  setPhoto(formData)
                }}
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Tipo de Media</FormLabel>
            <Select
              name='media'
              placeholder='Selecione...'
              value={content.media}
              onChange={({ target }) =>
                setContent({
                  ...content,
                  media: target.value
                })
              }
            >
              <option value='VIDEO'>Vídeo</option>
              <option value='PDF'>PDF</option>
            </Select>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>
              <span style={{ fontSize: '13px' }}>
                <strong>{content.media && linkLabel}</strong>
              </span>
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<FontAwesomeIcon color='lightGray' icon={faLink} />}
              />
              <Input
                type='url'
                value={content.link}
                onChange={({ target }) => {
                  setContent({ ...content, link: target.value })
                }}
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={2}>
            <Checkbox
              isChecked={content.habilitado}
              onChange={({ target }) =>
                setContent({
                  ...content,
                  habilitado: target.checked
                })
              }
            >
              Habilitado
            </Checkbox>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='yellow'
            variant='outline'
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button colorScheme='yellow' onClick={handleSave}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
