import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";

export const storeName = "@empodera/backoffice";
const localStorageItem = localStorage.getItem(storeName);

const preloadedState = localStorageItem ? JSON.parse(localStorageItem) : {};

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState,
});

store.subscribe(() =>
  localStorage.setItem(storeName, JSON.stringify(store.getState()))
);
