import React, { useEffect, useState } from "react";
import { Layout } from "components/Layout";
import { useSnackbar } from "hooks";
import { api } from "shared/api";
import { Section } from "./components/Section";
import { Item, Line } from "./types";

export const Dashboard = () => {
  const toast = useSnackbar();
  const [lines, setLines] = useState<Line[]>([]);

  async function fetchStats() {
    const freshStats = await api.stats.totals();
    return freshStats;
  }

  useEffect(() => {
    fetchStats()
      .then((freshStats) => {
        const output = [];
        output.push({
          title: "Usuários",
          items: getFirstLineItems(freshStats),
        });
        output.push({
          title: "Adequação",
          items: getSecondLineItems(freshStats),
        });
        output.push({
          title: "Escolaridade",
          items: getThirdLineItems(freshStats),
        });
        output.push({ title: "Outros", items: getForthLineItems(freshStats) });
        return output;
      })
      .then((newStats) => setLines(newStats))
      .catch((error) => {
        toast({
          title: "Não foi possível atualizar estatísticas",
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  function getFirstLineItems(stats: any): Item[] {
    const totalUsers = stats.usuarios;
    return [
      { current: totalUsers, aim: 100000, label: "Usuários" },
      { current: stats.curriculos, aim: totalUsers, label: "Currículos" },
      { current: stats.logica, aim: totalUsers, label: "Avaliações de Lógica" },
      { current: stats.ingles, aim: totalUsers, label: "Avaliações de Inglês" },
    ];
  }

  function getSecondLineItems(stats: any): Item[] {
    const totalUsers = stats.usuarios;
    return [
      { current: stats.totalAdequados, aim: totalUsers, label: "Adequados" },
      {
        current: stats.totalInadequadosComCurriculo,
        aim: totalUsers,
        showAim: false,
        label: "Inadequados Com Currículo",
      },
      {
        current: stats.totalAdequadosSemCurriculo,
        aim: totalUsers,
        showAim: false,
        label: "Adequados Sem Currículo",
      },
      { current: stats.desativadas, label: "Contas Desativadas" },
      { current: stats.encerradas, label: "Contas Encerradas" },
    ];
  }

  function getThirdLineItems(stats: any): Item[] {
    const totalUsers = stats.usuarios;
    return [
      {
        current: stats.fundamental,
        aim: totalUsers,
        showAim: false,
        label: "Nível Fundamental",
      },
      {
        current: stats.medio,
        aim: totalUsers,
        showAim: false,
        label: "Níivel Médio",
      },
      {
        current: stats.superior,
        aim: totalUsers,
        showAim: false,
        label: "Nível Superior",
      },
      {
        current: stats.posgraduacao,
        aim: totalUsers,
        showAim: false,
        label: "Pós Graduação",
      },
      {
        current: stats.mestrado,
        aim: totalUsers,
        showAim: false,
        label: "Mestrado",
      },
      {
        current: stats.doutorado,
        aim: totalUsers,
        showAim: false,
        label: "Doutorado",
      },
    ];
  }

  function getForthLineItems(stats: any): Item[] {
    return [
      { current: stats.itens, label: "Itens" },
      { current: stats.comentarios, label: "Comentários" },
      { current: stats.questoes, label: "Questões" },
      { current: stats.respostas, label: "Respostas" },
    ];
  }

  return (
    <Layout header menu footer>
      <Section lines={lines} />
    </Layout>
  );
};
