import {
  Flex,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Layout } from "components/Layout";
import { useSnackbar } from "hooks";
import { useContext, useState } from "react";
import { api } from "shared/api";
import { CompanyForm } from "./CompanyForm";
import { CompaniesContext } from "./CompaniesProvider";
import { SearchResult } from "./SearchResult";
import { initialState } from "./types";
import { CompanyFormValue } from "types/company";

export const Companies = () => {
  const toast = useSnackbar();
  const [company, setCompany] = useState(initialState);
  const {
    uploadImage: handleImageUpload,
    fetchCompanies,
    companies,
    setCompanies,
    loading,
    isLoading,
  } = useContext(CompaniesContext);

  const create = async (company: CompanyFormValue) => {
    isLoading(true);
    try {
      const newCompany = await api.company.post(company);
      setCompany(newCompany);
      toast({
        title: `Empresa ${company.nome} cadastrada com sucesso`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível cadastrar a empresa.",
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  return (
    <Layout header menu footer>
      <Flex direction={"column"} w="100%">
        <Flex
          direction="column"
          w="40%"
          style={{ marginRight: "30%", marginLeft: "30%" }}
        >
          <Text fontSize="2xl" mx="auto" my="20px">
            Gerenciamento de Empresas
          </Text>
          <Tabs>
            <TabList>
              <Tab>Pesquisar</Tab>
              <Tab>Cadastrar</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <CompanyForm
                  okLabel="Pesquisar"
                  cancelLabel="Limpar"
                  loading={loading}
                  search={fetchCompanies}
                  onResetResult={() => setCompanies([])}
                />
              </TabPanel>
              <TabPanel>
                <CompanyForm
                  okLabel="Cadastrar"
                  cancelLabel="Limpar"
                  loading={loading}
                  uploadImage={(event) =>
                    handleImageUpload(company.id!!, event)
                  }
                  create={create}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        {companies.length > 0 && (
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            <b>{companies.length}</b> empresa(s) encontrada(s)
          </p>
        )}

        <SimpleGrid columns={4} spacing={5} padding={5}>
          {companies.length > 0 && <SearchResult companies={companies} />}
        </SimpleGrid>
      </Flex>
    </Layout>
  );
};
