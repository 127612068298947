import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'
import {
  faArrowDown,
  faArrowUp,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { api } from 'shared/api'
import { Trail } from 'types/trail'
import { TrailModal } from './TrailModal'

type TrailsTableProps = {
  trails: Trail[]
  refreshTrails: () => void
}

export const TrailsTable = ({ trails, refreshTrails }: TrailsTableProps) => {
  const [isEditModalOpen, openEditModal] = useState(false)
  const [pickedTrail, setPickedTrail] = useState({} as Trail)

  const moveTrailUp = async (trail: any, otherTrail: any) => {
    const { id, posicao } = trail

    api.trail.move(id, { id, posicao: posicao - 1 })
    api.trail.move(otherTrail.id, {
      id: otherTrail.id,
      posicao: otherTrail.posicao + 1
    })
    refreshTrails()
  }

  const moveTrailDown = async (trail: any, otherTrail: any) => {
    const { id, posicao } = trail

    api.trail.move(id, { id, posicao: posicao + 1 })
    api.trail.move(otherTrail.id, {
      id: otherTrail.id,
      posicao: otherTrail.posicao - 1
    })
    refreshTrails()
  }
  
  return (
    <>
      <TableContainer>
        <Table variant='striped' size={'sm'}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Nome</Th>
              <Th>Criada por</Th>
              <Th>Ordenar</Th>
              <Th>Enterprise</Th>
              <Th>Oculta</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trails.map((trail, index) => (
              <Tr key={trail.id}>
                <Td>{trail.id}</Td>
                <Td>{trail.nome}</Td>
                <Td>{trail.criadaPor?.nome}</Td>
                <Td textAlign='center'>
                  {index !== trails.length - 1 && (
                    <FontAwesomeIcon
                      color='gray'
                      icon={faArrowDown}
                      style={{ marginRight: '10px', cursor: 'pointer' }}
                      title='Para baixo'
                      onClick={() =>
                        moveTrailDown(trail, trails[index + 1])
                      }
                    />
                  )}
                  {index > 0 && (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faArrowUp}
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                          title='Para cima'
                          onClick={() =>
                            moveTrailUp(trail, trails[index - 1])
                          }
                        />
                      )}
                </Td>
                <Td textAlign='center'>{trail.enterprise ? 'Sim' : 'Não'}</Td>
                <Td>{trail.oculto ? 'Sim' : 'Não'}</Td>
                <Td>
                  <FontAwesomeIcon
                    color='gray'
                    icon={faPenToSquare}
                    style={{ marginRight: '10px', cursor: 'pointer' }}
                    title='Editar'
                    onClick={() => {
                      setPickedTrail(trail)
                      openEditModal(true)
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {pickedTrail.id && (
        <TrailModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setPickedTrail({} as Trail)
            openEditModal(false)
            //refreshTrails()
          }}
          trail={pickedTrail}
        />
      )}
    </>
  )
}
