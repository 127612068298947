import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Category } from 'types/category'
import { CategoryModal } from './modal'

export const CategoryPage = () => {
  const [loading, isLoading] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [categories, setCategories] = useState([] as Category[])
  const [pickedCategory, setPickedCategory] = useState({} as Category)
  const [isDialogOpen, openDialog] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const toast = useSnackbar()

  const fetchCategories = async () => {
    isLoading(true)
    const result = await api.category.list()
    setCategories(result)
    isLoading(false)
  }

  const createCategory = async () => {
    isLoading(true)
    try {
      const payload = { nome: categoryName, pontuacao: 0, questoes: 0 }
      await api.category.post(payload)
      toast({
        title: `Categoria ${categoryName} cadastrada com sucesso`,
        status: 'success'
      })
      setCategoryName('')
      await fetchCategories()
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar a categoria.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  const deleteCategory = async (id: number) => {
    isLoading(true)
    try {
      await api.category.delete(id)
      toast({
        title: `Categoria ${categoryName} removida com sucesso`,
        status: 'success'
      })
      setCategoryName('')
      fetchCategories()
    } catch (error) {
      toast({
        title: 'Não foi possível remover a categoria.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='50%' mt={4} style={{ marginLeft: '25%' }}>
        <Flex
          justify='space-between'
          mb='40px'
          w='80%'
          gap={4}
          style={{ marginLeft: '10%' }}
        >
          <FormControl w='80%'>
            <FormLabel>Nome</FormLabel>
            <Input
              value={categoryName}
              onChange={({ target }) => setCategoryName(target.value)}
            />
          </FormControl>

          <Flex direction='column' justify='flex-end'>
            <Button
              colorScheme='yellow'
              size='md'
              onClick={createCategory}
              disabled={categoryName === ''}
            >
              Criar
            </Button>
          </Flex>
        </Flex>

        {loading ? (
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='md'
            style={{ marginLeft: '50%' }}
          />
        ) : (
          <Flex
            justify='center'
            mb='40px'
          >
            <TableContainer>
              <Table variant='simple' size='lg'>
                <TableCaption placement='top'>
                  <b>{categories.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>Pontuação</Th>
                    <Th>Questões</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {categories.map(category => (
                    <Tr key={category.id}>
                      <Td>{category.nome}</Td>
                      <Td>{category.pontuacao}</Td>
                      <Td>{category.questoes}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color='gray'
                          icon={faPenToSquare}
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          title='Editar'
                          onClick={() => {
                            setPickedCategory(category)
                            openModal(true)
                          }}
                        />
                        <FontAwesomeIcon
                          color='red'
                          icon={faTrashCan}
                          title='Remover'
                          onClick={() => {
                            setPickedCategory(category)
                            openDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>

      {pickedCategory.id && (
        <RemovalConfirmationDialog
          targetName='Categoria'
          confirmationMessage={`Deseja realmente remover a categoria <strong>${pickedCategory.nome}</strong>?`}
          targetId={pickedCategory.id}
          isOpen={isDialogOpen}
          onClose={() => {
            setPickedCategory({} as Category)
            openDialog(false)
          }}
          onRemoveTarget={deleteCategory}
        />
      )}
      <CategoryModal
        open={isModalOpen}
        handleClose={() => {
          fetchCategories()
          openModal(false)
        }}
        categoryToEdit={pickedCategory}
      />
    </Layout>
  )
}
