import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { StatsTable } from "components/Table/StatsTable";
import { useSnackbar } from "hooks";
import { api } from "shared/api";
import { initialStatState } from "../types";

export const UsersByState = () => {
  const toast = useSnackbar();
  const [values, setValues] = useState([initialStatState]);

  useEffect(() => {
    async function fetchUsers() {
      const users = await api.stats.stateTotals();
      return users;
    }

    fetchUsers()
      .then((users) => {
        setValues(
          users.map((user: any) => ({
            label: user.local,
            total: user.total,
          }))
        );
      })
      .catch((error) => {
        toast({
          title:
            "Não foi possível atualizar estatísticas de usuários por estado",
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <Flex direction="row" justify="center" mb="10px">
      <StatsTable
        title={"Quantidade de Usuários por Localização"}
        headers={["Estado", "Quantidade"]}
        values={values}
      />
    </Flex>
  );
};
