import { Modal } from "components/Modal"
import { Form } from "components/Form"
import { Company, SelectiveProcessForm } from "types";
import { useEffect, useState } from "react";
import { Input } from "components/Form/Input";
import { api } from "shared/api";
import { Select } from "components/Form/Select";
import { Box, SimpleGrid, Spinner } from "@chakra-ui/react";
import { Textarea } from "components/Form/Textarea";
import { Checkbox } from "components/Form/Checkbox";
import { Datepicker } from "components/Form/Datepicker";

type Props = {
  id?: number;
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const initialValues: SelectiveProcessForm = {
  nome: '',
  empresaId: 1,
  opportunityId: 1,
  linkExterno: '',
  fechamentoEm: '',
  requerAvaliacao: true,
  requerPerfilCompleto: true,
  requiresCurriculum: true,
  banco: true,
  conferencia: true,
  prioritario: true,
  habilitado: true,
  descricao: '',
  bolsa: '',
  beneficios: '',
  local: '',
  triagem: false,
  coletiva: false,
  provaonline: false,
  skype: false,
  dinamica: false,
  painel: false,
  entrevista: false,
};


export const Formulary = ({ id, open, onSubmit, onClose }: Props) => {
  const [initial, setInitial] = useState(initialValues)
  const [companies, setCompanies] = useState<Company[]>([])
  const [opportunities, setOpportunities] = useState<any[]>([])
  const [loadingGet, setLoadingGet] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if(open) {
      setInitial(initialValues)
      setLoadingSubmit(false)
      api.company.list().then(setCompanies)
      api.opportunity.list().then(setOpportunities)
    }
  }, [open])
 
  useEffect(() => {
    if(id && open) {
      setLoadingGet(true)
      api.selectiveProcess.get(id)
      .then(data => {
        setInitial({
          nome: data.nome,
          empresaId: data.empresa.id,
          opportunityId: data.opportunity.id,
          linkExterno: data.linkExterno,
          fechamentoEm: data.fechamentoEm,
          requerAvaliacao: data.requerAvaliacao,
          requerPerfilCompleto: data.requerPerfilCompleto,
          requiresCurriculum: data.requiresCurriculum,
          banco: data.banco,
          conferencia: data.conferencia,
          prioritario: data.prioritario,
          habilitado: data.habilitado,
          descricao: data.descricao,
          bolsa: data.bolsa,
          beneficios: data.beneficios,
          local: data.local,
          triagem: data.triagem,
          coletiva: data.coletiva,
          provaonline: data.provaonline,
          skype: data.skype,
          dinamica: data.dinamica,
          painel: data.painel,
          entrevista: data.entrevista,
        })
      })
      .finally(() => setLoadingGet(false))
    }
  }, [id, open])

  const handleSubmit = async (data: SelectiveProcessForm) => {
    const payload = {
      nome: data.nome,
      empresa: {
        id: data.empresaId
      },
      opportunity: {
        id: data.opportunityId
      },
      linkExterno: data.linkExterno,
      fechamentoEm: data.fechamentoEm,
      requerAvaliacao: data.requerAvaliacao,
      prioritario: data.prioritario,
      habilitado: data.habilitado,
      descricao: data.descricao,
      bolsa: data.bolsa,
      beneficios: data.beneficios,
      local: data.local,
      triagem: data.triagem,
      coletiva: data.coletiva,
      provaonline: data.provaonline,
      skype: data.skype,
      dinamica: data.dinamica,
      painel: data.painel,
      entrevista: data.entrevista,
    }
    setLoadingSubmit(true)
    if(id) {      
      await api.selectiveProcess.put(id, payload)
    }
    onSubmit()
  }

  return (
    <Modal 
      title="Formulário de Processo Seletivo"
      open={open} 
      onClose={onClose}
      minW="800px"
    >
      { loadingGet && (
        <Box textAlign='center' display='flex' alignItems='center' justifyContent='center' height='200px'>
          <Spinner thickness='3px' color='#334925' size='xl' speed='0.5s'/>
        </Box>
      )}
      { !loadingGet && (
        <Form 
          initial={initial}
          onSubmit={handleSubmit}
          onReset={onClose}
          okLabel="Salvar"
          isLoading={loadingSubmit}
        >
          <SimpleGrid columns={1} marginBottom={2}>
            <Input 
              name="nome"
              placeholder="Nome"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} spacing={2} marginBottom={2}>
            <Select 
              name="empresaId"
              placeholder="Empresa"
              options={companies}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} spacing={2} marginBottom={2}>
            <Select 
              name="opportunityId"
              placeholder="Oportunidade"
              options={opportunities}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Input 
              name="linkExterno"
              placeholder="Link externo (recrutadora)"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={3}>
            <Datepicker 
              name="fechamentoEm"
              placeholder="Prazo de inscrição"
            />
          </SimpleGrid>
          <SimpleGrid columns={3} marginBottom={3}>
            <Checkbox 
              name="habilitado"
              placeholder="Habilitado"
            />
            <Checkbox 
              name="requerAvaliacao"
              placeholder="Requer avaliações"
            />
            <Checkbox 
              name="requerPerfilCompleto"
              placeholder="Requer perfil completo"
            />
          </SimpleGrid>
          <SimpleGrid columns={3} marginBottom={3}>
            <Checkbox 
              name="prioritario"
              placeholder="Prioritário"
            />
            <Checkbox 
              name="requiresCurriculum"
              placeholder="Requer currículo"
            />
            <Checkbox 
              name="banco"
              placeholder="Banco de talentos"
            />
            
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={3}>
            <Checkbox 
              name="conferencia"
              placeholder="É conferência"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Textarea 
              name="descricao"
              placeholder="Descrição"
              rows={7}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Input 
              name="bolsa"
              placeholder="Bolsa"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Input 
              name="beneficios"
              placeholder="Benefícios (separado por vírgula)"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Input 
              name="local"
              placeholder="Local"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="triagem"
              placeholder="Triagem"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="provaonline"
              placeholder="Prova online"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="skype"
              placeholder="Skype"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="coletiva"
              placeholder="Coletiva"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="dinamica"
              placeholder="Dinâmica"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="painel"
              placeholder="Painel"
            />
          </SimpleGrid>
          <SimpleGrid columns={1} marginBottom={2}>
            <Checkbox 
              name="entrevista"
              placeholder="Entrevista"
            />
          </SimpleGrid>
        </Form>
      )}
    </Modal>
  )
}