import { Flex, Button } from '@chakra-ui/react'
import { useState } from 'react'
import { FaEye, FaFileExcel } from 'react-icons/fa'
import { Company, GroupedTrail } from 'types/trail'
import { AudienceModal } from './AudienceModal'

type ActionButtonsProps = {
  trail: GroupedTrail
}

export const ActionButtons = ({ trail }: ActionButtonsProps) => {
  const [isAudienceModalOpen, openAudienceModal] = useState(false)
  const [pickedCompany, setPickedCompany] = useState({} as Company)

  return (
    <>
      <Flex justify='center' gap={3}>
        <Button
          size='sm'
          leftIcon={<FaEye />}
          colorScheme='linkedin'
          onClick={() => {
            openAudienceModal(true)
            setPickedCompany(trail.empresa)
          }}
        >
          Espectadores
        </Button>
        <Button size='sm' leftIcon={<FaFileExcel />} colorScheme='teal'>
          Planilha
        </Button>
      </Flex>

      {pickedCompany.id && (
        <AudienceModal
          isOpen={isAudienceModalOpen}
          onClose={() => {
            setPickedCompany({} as Company)
            openAudienceModal(false)
          }}
          company={pickedCompany}
        />
      )}
    </>
  )
}
