import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { Box, Subtitle, Title, Wrapper } from "./style";
import { api } from "../../shared/api";
import { FormControl, Input } from "@chakra-ui/react";
import { Form } from "../../components/Form";
import { Field } from "formik";
import { useSnackbar, useUser } from "hooks";

export const Login = () => {
  const [initial, setInitial] = useState({
    email: '',
    senha: '',
    perfil: 'administrador'
  })
  const [loading, isLoading] = useState(false)
  const { setUser, setToken } = useUser()
  const toast = useSnackbar()
  const navigate = useNavigate();

  const auth = async () => {
    isLoading(true);
    api.auth.login(initial)
      .then(({ user, token }) => {
        setUser(user);
        setToken(token);
        navigate("/dashboard");
      })
      .catch(error => {
        const description = error.code === 'ERR_NETWORK' ? 'A API está fora do ar' : error.response.data.msg
        toast({
          title: "Realizar Login",
          description,
          status: 'warning',
        })
      })
      .finally(() => isLoading(false));
  }

  const handleInput = (event: any) => {
    const { name, value } = event.target;
    setInitial({
      ...initial,
      [name]: value,
    });
  };

  return (
    <Layout>
      <Wrapper>
        <Box>
          <Title> Comunidade Empodera </Title>
          <Subtitle> Backoffice</Subtitle>
          <Form initial={initial} buttonWidth='100px' onSubmit={auth} isLoading={loading} okLabel='Entrar'>
            <Field name="email">
              {({ field }: any) => (
                <FormControl isRequired>
                  <Input {...field}
                    placeholder='E-mail'
                    name='email'
                    type='email'
                    value={initial.email}
                    onChange={handleInput}
                  />
                </FormControl>
              )}
            </Field>
            <Field name='senha'>
              {({ field }: any) => (
                <FormControl isRequired>
                  <Input  {...field}
                    placeholder='Senha'
                    name='senha'
                    type='password'
                    marginTop={5}
                    value={initial.senha}
                    onChange={handleInput}
                  />
                </FormControl>
              )}
            </Field>
          </Form>
        </Box>
      </Wrapper>
    </Layout >
  )
}