import React from "react";
import { Wrapper } from "./style";

export const Footer = () => {
  return (
    <Wrapper>
      <span> 2022 - Comunidade Empodera </span>
    </Wrapper>
  );
};
