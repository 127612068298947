import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Card,
  CardHeader,
  Heading,
  CardBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
  Checkbox,
  Button,
  TableContainer,
  Thead,
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableCaption
} from '@chakra-ui/react'
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { CustomSpinner } from 'components/Spinner'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useEffect, useState } from 'react'
import { FaPlus, FaSave, FaTrash } from 'react-icons/fa'
import { api } from 'shared/api'
import { Company, Position, Subtrail, Trail } from 'types/trail'
import { SubtrailModal } from '../Subtrail'

type TrailModalProps = {
  isOpen: boolean
  onClose: () => void
  trail: Trail
}

type TrailToUpdate = {
  id: number
  name: string
  companyId: number
  isEnterprise: boolean
  isHidden: boolean
}

export const TrailModal = ({ isOpen, onClose, trail }: TrailModalProps) => {
  const toast = useSnackbar()
  const [loading, isLoading] = useState(false)
  const [isDialogOpen, openDialog] = useState(false)
  const [isSubtrailModalOpen, openSubtrailModal] = useState(false)
  const loggedUser = useLocalStorage()
  const [companies, setCompanies] = useState([] as Company[])
  const [subtrails, setSubtrails] = useState([] as Subtrail[])
  const [trailToUpdate, setTrailToUpdate] = useState({} as TrailToUpdate)
  const [newSubTrail, setNewSubTrail] = useState({} as any)
  const [positions, setPositions] = useState([] as Position[])
  const [pickedSubtrail, setPickedSubtrail] = useState({} as Subtrail)

  const updateTrail = async () => {
    const payload: Trail = {
      ...trail,
      nome: trailToUpdate.name,
      empresa: { id: trailToUpdate.companyId },
      enterprise: trailToUpdate.isEnterprise,
      oculto: trailToUpdate.isHidden,
      criadaPor: loggedUser
    }

    api.trail.update(trail.id, payload)
  }

  const createSubTrail = async () => {
    try {
      const payload = {
        nome: newSubTrail.nome,
        cargo: { id: newSubTrail.cargo.id },
        criadaPor: { id: loggedUser?.id },
        empresa: { id: trail.empresa?.id },
        trilha: { id: trail.id }
      }
      isLoading(true)
      await api.subtrail.create(payload)
      toast({
        title: `Subtrilha criada com sucesso`,
        status: 'success'
      })
      fetchSubtrails()
    } catch (error) {
      toast({
        title: 'Não foi possível criar subtrilha.',
        status: 'error'
      })
    } finally {
      isLoading(false)
      setNewSubTrail({ nome: '', cargo: { id: 0 } })
    }
  }

  const setHidden = async (subtrailId: number, hide: boolean) => {
    await api.subtrail.hide(subtrailId, { id: subtrailId, oculto: hide })
    fetchSubtrails()
  }

  const moveSubTrailUp = async (subtrail: any, otherSubTrail: any) => {
    const { id, posicao } = subtrail

    await api.subtrail.move(id, { id, posicao: posicao - 1 })
    await api.subtrail.move(otherSubTrail.id, {
      id: otherSubTrail.id,
      posicao: otherSubTrail.posicao + 1
    })
    fetchSubtrails()
  }

  const moveSubTrailDown = async (subtrail: any, otherSubTrail: any) => {
    const { id, posicao } = subtrail

    await api.subtrail.move(id, { id, posicao: posicao + 1 })
    await api.subtrail.move(otherSubTrail.id, {
      id: otherSubTrail.id,
      posicao: otherSubTrail.posicao - 1
    })
    fetchSubtrails()
  }

  const fetchSubtrails = async () => {
    const result = await api.trail.getSubtrails(trail.id)
    setSubtrails(result)
  }

  const remove = async () => {
    try {
      await api.trail.delete(trail.id)
      toast({
        title: `Trilha removida com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível remover trilha.',
        status: 'error'
      })
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    api.company.list().then(setCompanies)
    fetchSubtrails()
    api.trail.getPositions().then(setPositions)

    setTrailToUpdate({
      id: trail.id,
      name: trail.nome,
      companyId: trail.empresa.id,
      isEnterprise: trail.enterprise,
      isHidden: trail.oculto
    })
  }, [])

  return (
    <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Trilha</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={5}>
            <Card w='50%'>
              <CardHeader>
                <Heading size='sm'>Trilha {trail.nome}</Heading>
              </CardHeader>
              <CardBody>
                <Flex direction='column' gap={3}>
                  <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      type='text'
                      value={trailToUpdate.name}
                      onChange={({ target }) =>
                        setTrailToUpdate({
                          ...trailToUpdate,
                          name: target.value
                        })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Empresa</FormLabel>
                    <Select
                      placeholder='Selecione...'
                      value={trailToUpdate.companyId}
                      onChange={({ target }) =>
                        setTrailToUpdate({
                          ...trailToUpdate,
                          companyId: parseInt(target.value)
                        })
                      }
                    >
                      {companies.map(company => (
                        <option key={company.id} value={company.id}>
                          {company.nome}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      isChecked={trailToUpdate.isEnterprise}
                      onChange={({ target }) =>
                        setTrailToUpdate({
                          ...trailToUpdate,
                          isEnterprise: Boolean(target.checked)
                        })
                      }
                    >
                      Enterprise
                    </Checkbox>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      isChecked={trailToUpdate.isHidden}
                      onChange={({ target }) =>
                        setTrailToUpdate({
                          ...trailToUpdate,
                          isHidden: Boolean(target.checked)
                        })
                      }
                    >
                      Oculta
                    </Checkbox>
                  </FormControl>
                  <Flex justify='flex-end'>
                    <Button
                      leftIcon={<FaTrash />}
                      ml='2'
                      size={'sm'}
                      onClick={() => {
                        openDialog(true)
                      }}
                    >
                      Excluir
                    </Button>
                    <Button
                      leftIcon={<FaSave />}
                      colorScheme='yellow'
                      ml='2'
                      size={'sm'}
                      onClick={updateTrail}
                    >
                      Salvar
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>

            <Card w='50%'>
              <CardHeader>
                <Heading size='sm'>Criar Subtrilha</Heading>
              </CardHeader>
              <CardBody>
                <Flex direction='column' gap={3}>
                  <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      type='text'
                      value={newSubTrail.nome}
                      onChange={({ target }) =>
                        setNewSubTrail({ ...newSubTrail, nome: target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Cargo</FormLabel>
                    <Select
                      name='position'
                      placeholder='Selecione...'
                      value={newSubTrail.cargo?.id}
                      onChange={({ target }) =>
                        setNewSubTrail({
                          ...newSubTrail,
                          cargo: { id: parseInt(target.value), nome: '' }
                        })
                      }
                    >
                      {positions.map(position => (
                        <option key={position.id} value={position.id}>
                          {position.nome}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <Flex justify='flex-end'>
                    <Button
                      leftIcon={<FaPlus />}
                      colorScheme='yellow'
                      ml='2'
                      size={'sm'}
                      onClick={createSubTrail}
                    >
                      Criar
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
          <Flex mt={5} mb={5} justify='center'>
            <CustomSpinner loading={loading}>
              <Card w='100%'>
                <CardHeader>
                  <Heading size='sm'>Subtrilhas Criadas</Heading>
                </CardHeader>
                <CardBody>
                  <TableContainer>
                    <Table variant='striped' size='sm'>
                      <TableCaption placement='top'>
                        <b>{subtrails.length}</b> resultado(s) encontrado(s)
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>Nome</Th>
                          <Th>Cargo</Th>
                          <Th>Criada por</Th>
                          <Th>Ordernar</Th>
                          <Th>Ocultar</Th>
                          <Th>#</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {subtrails.map((subtrail, index) => (
                          <Tr key={subtrail.id}>
                            <Td>{subtrail.id}</Td>
                            <Td>{subtrail.nome}</Td>
                            <Td>{subtrail.cargo?.nome}</Td>
                            <Td>{subtrail.criadaPor?.nome}</Td>
                            <Td textAlign='center'>
                              {index !== subtrails.length - 1 && (
                                <FontAwesomeIcon
                                  color='gray'
                                  icon={faArrowDown}
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                  }}
                                  title='Para baixo'
                                  onClick={() =>
                                    moveSubTrailDown(
                                      subtrail,
                                      subtrails[index + 1]
                                    )
                                  }
                                />
                              )}
                              {index > 0 && (
                                <FontAwesomeIcon
                                  color='gray'
                                  icon={faArrowUp}
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                  }}
                                  title='Para cima'
                                  onClick={() =>
                                    moveSubTrailUp(
                                      subtrail,
                                      subtrails[index - 1]
                                    )
                                  }
                                />
                              )}
                            </Td>
                            <Td textAlign='center'>
                              {subtrail.oculto ? (
                                <FontAwesomeIcon
                                  color='gray'
                                  icon={faEyeSlash}
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                  }}
                                  title='Ocultar'
                                  onClick={() => {
                                    setHidden(subtrail.id, false)
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  color='gray'
                                  icon={faEye}
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                  }}
                                  title='Ocultar'
                                  onClick={() => {
                                    setHidden(subtrail.id, true)
                                  }}
                                />
                              )}
                            </Td>
                            <Td>
                              <FontAwesomeIcon
                                color='gray'
                                icon={faPenToSquare}
                                style={{
                                  marginRight: '10px',
                                  cursor: 'pointer'
                                }}
                                title='Não Oculta'
                                onClick={() => {
                                  setPickedSubtrail(subtrail)
                                  openSubtrailModal(true)
                                }}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </CustomSpinner>
          </Flex>
        </ModalBody>
      </ModalContent>

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName='Trilha'
          confirmationMessage={`Deseja realmente remover a trilha <strong>${trail.nome}</strong>?`}
          targetId={trail.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
            onClose()
          }}
          onRemoveTarget={remove}
        />
      )}

      {isSubtrailModalOpen && (
        <SubtrailModal
          isOpen={isSubtrailModalOpen}
          onClose={() => {
            openSubtrailModal(false)
            setPickedSubtrail({} as Subtrail)
            fetchSubtrails()
          }}
          subtrail={pickedSubtrail}
        />
      )}
    </Modal>
  )
}
