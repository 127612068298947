import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Theme } from 'types'
import { ThemeModal } from './modal'

export const ThemePage = () => {
  const [loading, isLoading] = useState(false)
  const [themeName, setThemeName] = useState('')
  const [themes, setThemes] = useState([] as Theme[])
  const [pickedTheme, setPickedTheme] = useState({} as Theme)
  const [isDialogOpen, openDialog] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const toast = useSnackbar()

  const fetchThemes = async () => {
    isLoading(true)
    const themes = await api.theme.list()
    setThemes(themes)
    isLoading(false)
  }

  const createTheme = async () => {
    isLoading(true)
    try {
      await api.theme.post(themeName)
      toast({
        title: `Tema ${themeName} cadastrado com sucesso`,
        status: 'success'
      })
      setThemeName('')
      await fetchThemes()
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar o tema.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  const deleteTheme = async (id: number) => {
    isLoading(true)
    try {
      await api.theme.delete(id)
      toast({
        title: `Tema ${themeName} removido com sucesso`,
        status: 'success'
      })
      setThemeName('')
      fetchThemes()
    } catch (error) {
      toast({
        title: 'Não foi possível remover o tema.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    fetchThemes()
  }, [])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='50%' mt={4} style={{ marginLeft: '25%' }}>
        <Flex
          justify='space-between'
          mb='40px'
          w='80%'
          gap={4}
          style={{ marginLeft: '10%' }}
        >
          <FormControl w='80%'>
            <FormLabel>Nome</FormLabel>
            <Input
              value={themeName}
              onChange={({ target }) => setThemeName(target.value)}
            />
          </FormControl>

          <Flex direction='column' justify='flex-end'>
            <Button
              colorScheme='yellow'
              size='md'
              onClick={createTheme}
              disabled={themeName === ''}
            >
              Criar
            </Button>
          </Flex>
        </Flex>

        {loading ? (
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='md'
            style={{ marginLeft: '50%' }}
          />
        ) : (
          <Flex justify='center' w='80%' mb='40px' style={{ marginLeft: '10%' }}>
            <TableContainer>
              <Table variant='simple' size='lg'>
                <TableCaption placement='top'>
                  <b>{themes.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {themes.map(theme => (
                    <Tr key={theme.id}>
                      <Td>{theme.nome}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color='gray'
                          icon={faPenToSquare}
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          title='Editar'
                          onClick={() => {
                            setPickedTheme(theme)
                            openModal(true)
                          }}
                        />
                        <FontAwesomeIcon
                          color='red'
                          icon={faTrashCan}
                          title='Remover'
                          onClick={() => {
                            setPickedTheme(theme)
                            openDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>

      {pickedTheme.id && (
        <RemovalConfirmationDialog
          targetName='Tema'
          confirmationMessage={`Deseja realmente remover o tema <strong>${pickedTheme.nome}</strong>?`}
          targetId={pickedTheme.id}
          isOpen={isDialogOpen}
          onClose={() => {
            setPickedTheme({} as Theme)
            openDialog(false)
          }}
          onRemoveTarget={deleteTheme}
        />
      )}
      <ThemeModal
        open={isModalOpen}
        handleClose={() => {
          fetchThemes()
          openModal(false)
        }}
        themeToEdit={pickedTheme}
      />
    </Layout>
  )
}
