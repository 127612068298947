import { SimpleGrid } from '@chakra-ui/react'
import { useContext } from 'react'
import { ItemContext } from '../Provider'
import { ContentCard } from './ContentCard'

export const SearchResult = () => {
  const { items } = useContext(ItemContext)
  return (
    <>
      <p style={{ textAlign: 'center' }}>
        <b>{items.length}</b> resultados encontrados
      </p>
      <SimpleGrid columns={3} spacing={3} padding={3}>
        {items.map(item => (
          <ContentCard key={item.id} item={item} />
        ))}
      </SimpleGrid>
    </>
  )
}
