import { CompanyFormValue } from "types/company";

export const initialState: CompanyFormValue = {
  nome: "",
  codigo: "",
  slug: "",
};

export type FormProps = {
  okLabel: string;
  cancelLabel?: string;
  loading: boolean;
  companyToEdit?: CompanyFormValue;
  create?: (institution: CompanyFormValue) => void;
  search?: (institution: CompanyFormValue) => void;
  update?: (institution: CompanyFormValue) => void;
  onResetResult?: () => void;
  uploadImage?: (event: any) => void;
  handleClose?: () => void;
};
