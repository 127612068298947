import React, { ReactNode } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { ChildrenBox, Content, MenuBox, Wrapper } from "./style";

interface LayoutProps {
  header?: boolean;
  menu?: boolean;
  footer?: boolean;
  children: ReactNode;
}

export const Layout = ({ header, menu, footer, children }: LayoutProps) => {
  return (
    <Wrapper>
      {header && <Header />}
      <Content>
        {menu && (
          <MenuBox>
            <Menu />
          </MenuBox>
        )}
        <ChildrenBox>{children}</ChildrenBox>
      </Content>
      {footer && <Footer />}
    </Wrapper>
  );
};
