import { ChartProps, DefaultDataset } from "../types";

type LineDataset = DefaultDataset & { label: string };

export type LineChartProps = ChartProps & { datasets: LineDataset[] };

export const initialState: LineDataset = {
  label: "",
  backgroundColor: "",
  data: [0],
};
