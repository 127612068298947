import { LoggedUser } from 'types/loggedUser'

export const useLocalStorage = (): LoggedUser => {
  const { user }: any = JSON.parse(
    localStorage.getItem('@empodera/backoffice')!!
  )

  return {
    id: user.id,
    nome: user.nome
  }
}
