import dayjs from "dayjs";

export const formatDate = (raw: string) => {
  if (!raw) return "";
  return dayjs(raw).format("DD/MM/YYYY");
};

export const parseDate = (raw: string) => {
  if (!raw) return "";
  const [day, month, year] = raw.split("/");
  return `${year}-${month}-${day}T12:00:00Z`;
};
