import React from "react";
import { Menu as CMenu, MenuList as CMenuList } from "@chakra-ui/react";
import { Item, Title, Wrapper } from "./style";
import elements from "./elements";
import { useNavigate } from "react-router-dom";

export const Menu = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      {elements.map((el) => (
        <CMenu key={el.id}>
          <Title>{el.name}</Title>
          <CMenuList>
            {el.options.map((op) => (
              <Item key={op.id} onClick={() => navigate(op.link)}>
                {op.name}
              </Item>
            ))}
          </CMenuList>
        </CMenu>
      ))}
    </Wrapper>
  );
};
