import styled from "styled-components";
import { theme } from "../../shared/theme";

const { colors } = theme;

const shadowValue = `1px 1px 2px ${colors.black}`;

type PercentState = {
  $value: number;
};

export const Wrapper = styled.div`
  border: 1px solid black;
  height: 190px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NumberBox = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${colors.black};
  background-color: ${colors.lightGray};
`;

export const Current = styled.span`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 16px;
  text-shadow: ${shadowValue};
`;

export const Aim = styled.span`
  font-weight: 500;
  font-size: 14px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.span`
  width: 100%;
  text-align: center;
  color: ${colors.lightGray};
  background-color: ${colors.black};
  font-weight: bold;
  font-size: 13px;
  padding: 8px 0;
  text-shadow: ${shadowValue};
`;

export const Percent = styled.span<PercentState>`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 14px;
  padding: 5px 0;
  text-shadow: ${shadowValue};

  background-color: ${({ $value }) => {
    if ($value <= 25) return colors.red;
    if ($value > 25 && $value <= 50) return colors.yellow;
    if ($value > 50 && $value <= 75) return colors.blue;
    return colors.green;
  }};
`;
