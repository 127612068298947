import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import parser from "html-react-parser";

type DialogProps = {
  targetName: string;
  confirmationMessage: string;
  targetId: number;
  isOpen: boolean;
  onClose: () => void;
  onRemoveTarget: (targetId: number) => void;
};

export const RemovalConfirmationDialog = ({
  targetName,
  confirmationMessage,
  targetId,
  isOpen,
  onClose,
  onRemoveTarget,
}: DialogProps) => {
  const cancelRef = React.createRef<HTMLButtonElement>();

  const handleConfirmation = () => {
    onRemoveTarget(targetId!!);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remover {targetName}
          </AlertDialogHeader>

          <AlertDialogBody>{parser(confirmationMessage)}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={handleConfirmation} ml={3}>
              Remover
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
