import { useToast } from "@chakra-ui/react";

type UseSnackbarProps = {
  title: string;
  description?: string;
  status: "success" | "warning" | "error";
};

export const useSnackbar = () => {
  const toast = useToast();
  return (props: UseSnackbarProps) => {
    const status = props.status || "success";
    toast({
      ...props,
      status,
      position: "top-right",
    });
  };
};
