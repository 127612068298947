import { config } from "config";
import { parseDate } from "shared/utils/date";
import {
  AcademicForm,
  User,
  AddressAndContactForm,
  ExperienceForm,
  PersonalForm,
} from "types";

export const parsePersonalForm = (form: PersonalForm) => {
  return {
    nome: form.nome,
    email: form.email,
    nascimento: parseDate(form.nascimento),
    genero: form.genero,
    etnia: form.etnia,
    pcd: form.pcd,
    rg: form.rg,
    cpf: form.cpf,
    codigoEmpresarial: form.codigoEmpresarial,
    codigoCoaching: form.codigoCoaching,
    empodera: form.empodera,
    status: form.status,
    numeroEstrelas: form.numeroEstrelas,
  };
};

export const parseAddressAndContactForm = (form: AddressAndContactForm) => {
  return {
    logradouro: form.logradouro,
    bairro: form.bairro,
    cep: form.cep,
    pais: {
      id: form.paisId,
    },
    estado: {
      id: form.estadoId,
    },
    cidade: {
      id: form.cidadeId,
    },
    foneFixo: form.foneFixo,
    foneCelular: form.foneCelular,
    skype: form.skype,
    linkedin: form.linkedin,
  };
};

export const parseAcademicForm = (form: AcademicForm) => {
  return {
    id: form.id,
    instituicaoId: Number(form.instituicaoId),
    status: form.status,
    cursoId: Number(form.cursoId),
    periodo: form.periodo,
    grau: form.grau,
    conclusao: form.conclusao,
    bolsista: form.bolsista === 'SIM' ? true : false,
  };
};

export const parseExperienceForm = (form: ExperienceForm) => {
  return {
    id: form.id,
    empresaId: Number(form.empresaId),
    areaId: Number(form.areaId),
    cargoId: Number(form.cargoId),
    nivel: form.nivel,
  };
};

export const getUserPhoto = (user: User | undefined) =>
  `${config.imagesPath}/usuarios/${user?.foto}`;

export const getUserSituation = (user: User | undefined) => {
  return {
    hasCurriculum: !!user?.curriculo.arquivo,
    isComplete: user?.adequado,
    progress: user?.progress,
  };
};
