import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Box } from "@chakra-ui/react";
import { BarChartProps } from "./types";
import { defaultOptions } from "../config";
import { Bar } from "react-chartjs-2";

export const BarChart = ({ title, labels, datasets }: BarChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels,
    datasets: datasets.map((dataset) => ({
      borderWidth: 1.2,
      ...dataset,
    })),
  };

  const customOptions = structuredClone(defaultOptions);
  customOptions.plugins.title.text = title;
  customOptions.plugins.tooltip.enabled = true;

  return (
    <Box w="90%">
      <Bar data={data} options={customOptions} style={{ maxHeight: "300px" }} />
    </Box>
  );
};
