import { Element } from "./types";

const list: Element[] = [
  {
    id: 1,
    name: "Dashboard",
    options: [
      {
        id: 1,
        name: "Home",
        link: "/dashboard",
      },
      {
        id: 2,
        name: "Usuários",
        link: "/dashboard/usuarios",
      },
    ],
  },
  {
    id: 2,
    name: "Usuários",
    options: [
      {
        id: 1,
        name: "Listar",
        link: "/usuarios",
      },
      {
        id: 2,
        name: "Geraçao de usuários",
        link: "/usuarios/geracao",
      },
    ],
  },
  {
    id: 3,
    name: "Escolaridade",
    options: [
      {
        id: 1,
        name: "Cursos",
        link: "/escolaridade/cursos",
      },
      {
        id: 2,
        name: "Instituições",
        link: "/escolaridade/instituicoes",
      },
    ],
  },
  {
    id: 4,
    name: "Carreira Profissional",
    options: [
      {
        id: 1,
        name: "Empresas",
        link: "/empresa/listar",
      },
      {
        id: 2,
        name: "Áreas de atuação",
        link: "/areadeatuacao/listar",
      },
    ],
  },
  { id: 5,
    name: 'Conteúdos',
    options: [
      {
        id: 1,
        name: 'Listar itens',
        link: '/item/listar'
      },
      {
        id: 2,
        name: 'Temas',
        link: '/item/temas'
      },
      {
        id: 3,
        name: 'Subtemas 1',
        link: '/item/subtemas1'
      },
      {
        id: 4,
        name: 'Subtemas 2',
        link: '/item/subtemas2'
      },
      {
        id: 5,
        name: 'Subtemas 3',
        link: '/item/subtemas3'
      },
      {
        id: 6,
        name: 'Categorias',
        link: '/item/categorias'
      },
    ]
  },
  {
    id: 6,
    name: 'Mercado',
    options: [
      {
        id: 1,
        name: "Buscar",
        link: "/mercado/buscar",
      },
    ]
  },
  {
    id: 7,
    name: 'Trilha de Conhecimento',
    options: [
      {
        id: 1,
        name: "Listar",
        link: "/trilha/listar",
      },
    ]
  },
  {
    id: 8,
    name: 'Processo Seletivo',
    options: [
      {
        id: 1,
        name: "Listar",
        link: "/processosseletivos",
      },
    ]
  },
]
export default list;
