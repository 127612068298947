import { Anchor } from "./styles";
import { Props } from "./types";

export const Link = ({ base, resource }: Props) => {
  return (
    <>
      {!!resource && (
        <Anchor href={`${base}/${resource}`} target="_blank" rel="noreferrer">
          {" "}
          Baixar{" "}
        </Anchor>
      )}
    </>
  );
};
