import {
  Flex,
  Card,
  CardHeader,
  Heading,
  CardBody,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'
import {
  faArrowDown,
  faArrowUp,
  faEyeSlash,
  faEye,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { api } from 'shared/api'
import { Combo } from 'types/trail'
import { ComboModal } from '../Combo'

type CombosTableProps = {
  combos: Combo[]
  refreshCombos: () => void
}

export const CombosTable = ({ combos, refreshCombos }: CombosTableProps) => {
  const [pickedCombo, setPickedCombo] = useState({} as Combo)
  const [isComboModalOpen, openComboModal] = useState(false)

  const setHidden = async (comboId: number, hide: boolean) => {
    await api.combo.hide(comboId, { id: comboId, oculto: hide })
    refreshCombos()
  }

  const moveComboUp = async (combo: any, otherCombo: any) => {
    const { id, posicao } = combo

    await api.combo.move(id, { id, posicao: posicao - 1 })
    await api.combo.move(otherCombo.id, {
      id: otherCombo.id,
      posicao: otherCombo.posicao + 1
    })
    refreshCombos()
  }

  const moveComboDown = async (combo: any, otherCombo: any) => {
    const { id, posicao } = combo

    await api.combo.move(id, { id, posicao: posicao + 1 })
    await api.combo.move(otherCombo.id, {
      id: otherCombo.id,
      posicao: otherCombo.posicao - 1
    })
    refreshCombos()
  }

  return (
    <Flex mt={5} mb={5} justify='center'>
      <Card w='100%'>
        <CardHeader>
          <Heading size='sm'>Combos Criados</Heading>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant='striped' size='sm'>
              <TableCaption placement='top'>
                <b>{combos.length}</b> resultado(s) encontrado(s)
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Nome</Th>
                  <Th>Cargo</Th>
                  <Th>Criada por</Th>
                  <Th>Ordernar</Th>
                  <Th>Ocultar</Th>
                  <Th>#</Th>
                </Tr>
              </Thead>
              <Tbody>
                {combos.map((combo, index) => (
                  <Tr key={combo.id}>
                    <Td>{combo.id}</Td>
                    <Td>{combo.nome}</Td>
                    <Td>{combo.cargo?.nome}</Td>
                    <Td>{combo.criadoPor?.nome}</Td>
                    <Td textAlign='center'>
                      {index !== combos.length - 1 && (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faArrowDown}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Para baixo'
                          onClick={() =>
                            moveComboDown(combo, combos[index + 1])
                          }
                        />
                      )}
                      {index > 0 && (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faArrowUp}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Para cima'
                          onClick={() => moveComboUp(combo, combos[index - 1])}
                        />
                      )}
                    </Td>
                    <Td textAlign='center'>
                      {combo.oculto ? (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faEyeSlash}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Não Ocultar'
                          onClick={() => {
                            setHidden(combo.id, false)
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faEye}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Ocultar'
                          onClick={() => {
                            setHidden(combo.id, true)
                          }}
                        />
                      )}
                    </Td>
                    <Td>
                      <FontAwesomeIcon
                        color='gray'
                        icon={faPenToSquare}
                        style={{
                          marginRight: '10px',
                          cursor: 'pointer'
                        }}
                        title='Oculta'
                        onClick={() => {
                          setPickedCombo(combo)
                          openComboModal(true)
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      {isComboModalOpen && (
        <ComboModal
          isOpen={isComboModalOpen}
          onClose={() => {
            setPickedCombo({} as Combo)
            openComboModal(false)
            refreshCombos()
          }}
          combo={pickedCombo}
        />
      )}
    </Flex>
  )
}
