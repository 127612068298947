import React, { useMemo, useState } from "react";
import { Layout } from "components/Layout";
import { Container, Paginator, Title } from "./style";
import { Form } from "components/Form";
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { PersonalForm } from "./components/PersonalForm";
import { AcademicForm } from "./components/AcademicForm";
import { SelectionProcessForm } from "./components/SelectionProcessForm";
import { api } from "shared/api";
import { Paginated, User } from "types";
import { Record } from "./components/Record";
import { getBlank, parseParams } from "./helper";
import { useSnackbar, useUser } from "hooks";
import { downloader } from "shared/utils/file";

export const ListUsers = () => {
  const [initial, setInitial] = useState(getBlank);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Paginated<User> | null>();
  const [params, setParams] = useState<any>();
  const [loadingExport, isLoadingExport] = useState(false);

  const { logged } = useUser();
  const toast = useSnackbar();

  const handleInput = (event: any) => {
    const { type } = event.nativeEvent;
    const { name, value, checked } = event.target;
    setInitial({
      ...initial,
      [name]: type === "input" ? value : checked,
    });
  };

  const handlePicker = (name: string, value: number[]) => {
    setInitial({
      ...initial,
      [name]: value,
    });
  };

  const search = (params: any) => {
    const title = "Pesquisar perfis";
    setData(null);
    setLoading(true);
    api.user
      .search(params)
      .then((data) => {
        setData(data);
        if (data.elements.length === 0) {
          toast({
            title,
            description: "Consulta não retornou resultados",
            status: "warning",
          });
        }
      })
      .catch(() => {
        toast({
          title,
          description: "Erro ao pesquisar",
          status: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const getUserIds = async () => {
    isLoadingExport(true);
    const ids = await api.user.searchForIds(params);
    const title = "Exportar perfis";
    try {
      const result = await api.user.export({ ids, requesterId: logged.id });
      if (result.output === "SYNC") {
        downloader(
          "xls",
          `usuarios-exportados-por-${logged.id}`,
          String(result.file)
        );
      } else {
        toast({
          title,
          description: String(result.message),
          status: "success",
        });
      }
    } catch (e) {
      toast({
        title,
        description: "Erro ao exportar os perfis",
        status: "error",
      });
    } finally {
      isLoadingExport(false);
    }
  };

  const handleSubmit = () => {
    const parsed = parseParams({ ...initial, page: 1 });
    setParams(parsed);
    search(parsed);
  };

  const handleReset = () => {
    setData(null);
    setInitial(getBlank);
  };

  const handlePageChange = ({ selected }: any) => {
    const parsed = { ...params, page: selected + 1 };
    setParams(parsed);
    search(parsed);
  };

  const hasElements = useMemo(() => {
    return !!data?.elements?.length;
  }, [data]);

  return (
    <Layout header menu footer>
      <Container>
        <Title>Parametrização</Title>
        <Form
          initial={initial}
          onReset={handleReset}
          onSubmit={handleSubmit}
          isLoading={loading}
          okLabel="Pesquisar"
          cancelLabel="Limpar"
          style={{ padding: "20px 30px" }}
          buttonWidth="150px"
        >
          <Tabs isFitted>
            <TabList>
              <Tab> Dados Pessoais </Tab>
              <Tab> Dados Acadêmicos </Tab>
              <Tab> Processos Seletivos </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <PersonalForm
                  initial={initial}
                  handleInput={handleInput}
                  handlePicker={handlePicker}
                />
              </TabPanel>
              <TabPanel>
                <AcademicForm
                  initial={initial}
                  handleInput={handleInput}
                  handlePicker={handlePicker}
                />
              </TabPanel>
              <TabPanel>
                <SelectionProcessForm
                  initial={initial}
                  handlePicker={handlePicker}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Form>
        <div>
          {!!data?.total && (
            <p style={{ textAlign: "center", fontSize: "13px" }}>
              <b>{data?.total}</b> resultado(s) encontrados
            </p>
          )}
          {!!data?.total && (
            <Box style={{ textAlign: "center", margin: "15px 0" }}>
              <Tooltip
                label="+ de 10k perfis, o arquivo segue por e-mail"
                fontSize="sm"
              >
                <Button
                  size="sm"
                  colorScheme="green"
                  onClick={getUserIds}
                  isLoading={loadingExport}
                >
                  Exportar perfis
                </Button>
              </Tooltip>
            </Box>
          )}
          {data?.elements?.map((user, index) => {
            return (
              <Record user={user} key={index} reset={() => search(params)} />
            );
          })}
        </div>
        {hasElements && (
          <Paginator
            breakLabel="..."
            nextLabel="Póximo >"
            onPageChange={handlePageChange}
            pageRangeDisplayed={10}
            forcePage={Number(data?.page) - 1}
            pageCount={Number(data?.pages)}
            previousLabel="< Anterior"
          />
        )}
      </Container>
    </Layout>
  );
};
