import React, { useMemo } from "react";
import {
  Current,
  Aim,
  InfoBox,
  Label,
  NumberBox,
  Percent,
  Wrapper,
} from "./styles";

type CounterProps = {
  current: number;
  aim?: number;
  showAim?: boolean;
  label: string;
};

export const Counter = ({
  current,
  aim,
  showAim = true,
  label,
}: CounterProps) => {
  const percent = useMemo(() => {
    if (aim) {
      return Number(((current / aim) * 100).toFixed(2));
    }
    return 0;
  }, [current, aim]);
  return (
    <Wrapper>
      <NumberBox>
        <Current>{current}</Current>
        {aim && showAim && <Aim> Alvo: {aim} </Aim>}
      </NumberBox>
      <InfoBox>
        <Label>{label}</Label>
        {aim && <Percent $value={percent}>{percent}%</Percent>}
      </InfoBox>
    </Wrapper>
  );
};
