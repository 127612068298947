import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
  Flex
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Category } from 'types/category'

type ModalProps = {
  handleClose: () => void
  open: boolean
  categoryToEdit: Category
}

export const CategoryModal = ({
  open,
  handleClose,
  categoryToEdit
}: ModalProps) => {
  const [category, setCategory] = useState({ id: 0, nome: '' } as Category)
  const toast = useSnackbar()

  const onEdit = async () => {
    try {
      await api.category.put(category.id, category)
      handleClose()
      toast({
        title: 'Categoria atualizada com sucesso',
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar a categoria',
        status: 'error'
      })
    }
  }

  const resetAndClose = () => {
    setCategory({} as Category)
    handleClose()
  }

  useEffect(() => {
    setCategory({ ...categoryToEdit })
  }, [categoryToEdit])

  return (
    <Modal isOpen={open} onClose={resetAndClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Categoria</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={4}>
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input
                value={category.nome}
                onChange={({ target }) =>
                  setCategory({ ...category, nome: target.value })
                }
              />
            </FormControl>

            <FormControl w='30%'>
              <FormLabel>Pontuação</FormLabel>
              <Input
                value={category.pontuacao}
                onChange={({ target }) =>
                  setCategory({ ...category, pontuacao: parseInt(target.value) })
                }
              />
            </FormControl>
            <FormControl w='30%'>
              <FormLabel>Questões</FormLabel>
              <Input
                value={category.questoes}
                onChange={({ target }) =>
                  setCategory({ ...category, questoes: parseInt(target.value) })
                }
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='yellow'
            variant='outline'
            mr={3}
            onClick={resetAndClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme='yellow'
            onClick={onEdit}
            disabled={category.nome === ''}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
