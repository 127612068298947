import { FormControl, FormErrorMessage, Textarea as ChakraTextarea } from "@chakra-ui/react"
import { Field } from "formik"

type Props = {
  name: string;
  placeholder: string;
  type?: 'text' | 'password' | 'email'
  required?: boolean;
  rows?: number,
  validate?: (value: string | number) => boolean;
}

export const Textarea = ({ name, type = "text", placeholder, required, rows, validate }: Props) => {

  const Component = ({ field, form }: any) => {
    const isInvalid = () => form.errors[name] && form.touched[name];

    const errorMessage = () => form.errors[name]

    return (
      <FormControl 
        isRequired={required}
        isInvalid={isInvalid()}
        >
        <ChakraTextarea 
          {...field }
          placeholder={placeholder}
          name={name}
          type={type}
          validate={validate}
          size='sm'
          rows={rows}
        />
        <FormErrorMessage> {errorMessage()} </FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Field 
      name={name} 
      placeholder={placeholder} 
      component={Component} 
    />
  )
}