import React, { useEffect, useState } from "react";
import { api } from "../../../../../shared/api";
import { useSnackbar } from "hooks";
import { Flex } from "@chakra-ui/react";
import { LineChart } from "components/Chart/LineChart";
import { initialState } from "components/Chart/LineChart/types";
import { StateProps } from "./types";

export const StateChart = ({ id, name }: StateProps) => {
  const toast = useSnackbar();
  const [datasets, setDatasets] = useState([initialState]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function fetchStateTotals() {
      const stateTotals = await api.stats.totalsByState(id);
      return stateTotals;
    }

    fetchStateTotals()
      .then((totals) => {
        setLabels(totals.map((state: any) => state.referencia));
        setDatasets([
          {
            label: "Quantidade",
            borderColor: "blue",
            backgroundColor: "lightblue",
            data: totals.map((state: any) => state.quantidade),
          },
          {
            label: "Acumulado",
            borderColor: "red",
            backgroundColor: "pink",
            data: totals.map((state: any) => state.acumulado),
          },
        ]);
      })
      .catch((error) => {
        toast({
          title: `Não foi possível atualizar estatísticas para o estado ${name}`,
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="25px">
      <LineChart
        title={`Novas contas nos últimos 12 meses - ${name}`}
        labels={labels}
        datasets={datasets}
      />
    </Flex>
  );
};
