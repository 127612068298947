export const theme = {
  colors: {
    yellow: "#EAAE1C",
    green: "#334925",
    red: "#DC143C",
    blue: "#1E90FF",
    lightGray: "#DCDCDC",
    black: "#111111",
    lightRed: '#FFF4F3',
    white: "#FFF",
  },
};
