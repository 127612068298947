import React, { useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { SelectiveProcess } from "types";
import { api } from "shared/api";
import { findElements } from "shared/utils/arrays";
import { Picker } from "./styles";

type Props = {
  initial: any;
  handlePicker: (name: string, value: number[]) => void;
};

export const SelectionProcessForm = ({ initial, handlePicker }: Props) => {
  const [processes, setProcesses] = useState<SelectiveProcess[]>([]);

  useEffect(() => {
    api.selectiveProcess.nopageable().then(setProcesses);
  }, []);

  return (
    <>
      <SimpleGrid columns={1}>
        <Picker
          value={findElements(processes, initial.inscricoes)}
          onChange={(value: any) =>
            handlePicker(
              "inscricoes",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Processos Seletivos"
          options={processes}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
      </SimpleGrid>
    </>
  );
};
