import React from "react";
import { StateChart } from "./StateChart";

export const StatesChart = () => {
  const states = [
    { id: 8, name: "Espírito Santo" },
    { id: 13, name: "Minas Gerais" },
    { id: 19, name: "Rio de Janeiro" },
    { id: 25, name: "São Paulo" },
  ];

  return (
    <>
      {states.map((state) => (
        <StateChart key={state.id} id={state.id} name={state.name} />
      ))}
    </>
  );
};
