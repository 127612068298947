import React, { useContext } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { DialogProps } from "./types";
import { SearchContext } from "../../Provider";

export const ConfirmatinDialog = ({ isOpen, onClose }: DialogProps) => {
  const cancelRef = React.createRef<HTMLButtonElement>();
  const { removeCourse, course } = useContext(SearchContext);

  const handleConfirmation = () => {
    removeCourse(course);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remover Curso
          </AlertDialogHeader>

          <AlertDialogBody>
            Deseja realmente remover o curso <strong>{course.nome}</strong>?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirmation} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
