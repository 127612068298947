import { Table } from "components/Table";
import { Paginator } from "components/Table/Paginator";
import { TableColumn } from "components/Table/types";
import { formatDate } from "shared/utils/date";
import { Paginated, SelectiveProcess } from "types";

type Props = {
  paginatedList: Paginated<SelectiveProcess> | undefined
  onChangePage: (id: number) => void;
  onEdit: (id: number) => void;
}

const header: TableColumn[] = [
  {
    label: 'ID',
    field: 'id'
  },
  {
    label: 'Nome',
    field: 'nome'
  },
  {
    label: 'Inscritos',
    field: 'inscritos'
  },
  {
    label: 'Empresa',
    field: 'empresa'
  },
  {
    label: 'Fecha em',
    field: 'fechamentoEm'
  },
  {
    label: 'Ativo',
    field: 'habilitado'
  },
  {
    label: 'Ver',
    event: 'view',
    icon: 'view',
  },
  {
    label: 'Editar',
    event: 'edit',
    icon: 'edit',
  },
  {
    label: 'Opções',
    event: 'options',
    icon: 'options',
  },
  {
    label: 'Excluir',
    event: 'delete',
    icon: 'delete',
  }
]

const parseElements= (list: SelectiveProcess[])  => {
  return list.map(el => ({
    id: el.id,
    nome: el.nome.length > 75 ? `${el.nome.substr(0, 75)}...` : el.nome,
    inscritos: el.inscritos,
    empresa: el.empresa.nome,
    fechamentoEm: formatDate(el.fechamentoEm),
    habilitado: el.habilitado === true ? 'Sim' : 'Não'
  }))
}


export const List = ({ paginatedList, onChangePage, onEdit }: Props) => {

  const processes = () => paginatedList ? parseElements(paginatedList.elements) : []
  
  const handleChangePage = (page: number) => {
    onChangePage(page)
  }

  const handleEvent = (event: string, el: SelectiveProcess, index: number) => {
    if(event === 'edit') { 
      onEdit && onEdit(el.id)
    }
  }
  
  return (
    <div>
    <Table 
      header={header} 
      elements={processes()}
      onEvent={handleEvent}
    />
    {processes().length > 0 && (
      <Paginator
        onChange={handleChangePage}
        page={Number(paginatedList?.page)}
        pages={Number(paginatedList?.pages)}
      />
    )}
    </div>
  )
}