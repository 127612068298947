import axios, {  AxiosError, AxiosRequestConfig } from 'axios'
import { SearchParams } from 'pages/Content/types';
import { storeName } from 'store'
import {
  Course,
  Institution,
  User,
  CreateCourse,
  Paginated,
  SelectiveProcess,
  State,
  ExportUsers,
  Country,
  CompanyFormValue,
  Manager,
  CreateManager,
  City,
  InstitutionFormValue,
  Scholarity,
  Experience,
  Item,
  Subtheme3,
  Subtheme2,
  Subtheme1,
  Theme,
  Market,
  CreateSelectiveProcess,
  SelectiveProcessCounterParams,
  SelectiveProcessParticipantsParams,
  SelectiveProcessStatistics,
  UpdateSelectiveProcess,
  Opportunity,
} from "types";
import { Category } from 'types/category';
import { Area, Company, CompanySyntheticReport, Position } from "types/company";
import { Combo, CreateTrail, GroupedTrail, Subtrail, Trail } from 'types/trail';
import { config } from "../../config";
import { Credential } from "../interfaces";

const http = axios.create({
  baseURL: config.api.baseUrl,
});

http.interceptors.request.use<AxiosRequestConfig>((config) => {
  const storeItem = localStorage.getItem(storeName);
  config.headers = {
    "x-application-id": "ADMIN",
  }
  if (storeItem) {
    config.headers = {
      Authorization: `Bearer ${JSON.parse(storeItem).user.token}`,
      ...config.headers
    };
  }
  return config;
});

http.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if(error.response?.status === 401) {
      console.error('Request rejected by axios interceptor: expired auth token');
      localStorage.removeItem("@empodera/backoffice");
      window.location.href = '/'
      return
    }
    return Promise.reject(error)
  }
)

export const api = {
  auth: {
    login: async (credential: Credential) => {
      const { data, headers } = await http.post<User>("/auth", credential);
      return {
        user: data,
        token: headers["authorization"],
      };
    },
  },
  category: {
    list: async () => {
      const { data } = await http.get<Category[]>("/categorias");
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Category>(`/categorias/${id}`);
      return data;
    },
    post: async (payload: any) => {
      const { data } = await http.post<Category>(`/categorias`, payload);
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Category>(`/categorias/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/categorias/${id}`),
  },
  company: {
    list: async (params?: CompanyFormValue) => {
      const { data } = await http.get<Company[]>("/empresas", { params });
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Company>(`/empresas/${id}`);
      return data;
    },
    getManagers: async (id: number) => {
      const { data } = await http.get<Manager[]>(`/empresas/${id}/gestores`);
      return data;
    },
    post: async (payload: CompanyFormValue) => {
      const { data } = await http.post<Company>(`/empresas`, payload);
      return data;
    },
    put: async (id: number, payload: CompanyFormValue) => {
      const { data } = await http.put<Company>(`/empresas/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/empresas/${id}`),
    upload: async (id: number, formData: FormData) => {
      const { data } = await http.post<Company>(
        `/empresas/${id}/fotos`,
        formData
      );
      return data;
    },
    upgrade: async (id: number, value: boolean) => {
      const { data } = await http.put(`/empresas/${id}`, { cliente: value });
      return data;
    },
    getSyntheticReport: async (id: number) => {
      const { data } = await http.get<CompanySyntheticReport[]>(`/empresas/${id}/sintetico`);
      return data;
    },
  },
  course: {
    list: async () => {
      const { data } = await http.get<Course[]>("/cursos");
      return data;
    },
    listByLevel: async (level: string) => {
      const params = new URLSearchParams({ grau: level });
      const { data } = await http.get<Course[]>("/cursos", { params });
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Course>(`/cursos/${id}`);
      return data;
    },
    create: async (payload: CreateCourse) => {
      const { data } = await http.post<Course>(`/cursos`, payload);
      return data;
    },
    update: async (id: number, payload: CreateCourse) => {
      const { data } = await http.put<Course>(`/cursos/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/cursos/${id}`),
  },
  institution: {
    list: async (params?: any) => {
      const { data } = await http.get<Institution[]>("/instituicoes", {
        params,
      });
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Institution>(`/instituicoes/${id}`);
      return data;
    },
    create: async (payload: InstitutionFormValue) => {
      const { data } = await http.post<Institution>(`/instituicoes`, payload);
      return data;
    },
    update: async (id: number, payload: any) => {
      const { data } = await http.put<Institution>(
        `/instituicoes/${id}`,
        payload
      );
      return data;
    },
    delete: async (id: number) => http.delete(`/instituicoes/${id}`),
    upload: async (id: number, formData: FormData) => {
      const { data } = await http.post<Institution>(
        `/instituicoes/${id}/imagens`,
        formData
      );
      return data;
    },
  },
  items: {
    list: async (params: SearchParams) => {
      const { data } = await http.get<Item[]>("/itens", { params });
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Item>(`/itens/${id}`);
      return data;
    },
    post: async (payload: any) => {
      const { data } = await http.post<Item>(`/itens`, payload);
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Item>(`/itens/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/itens/${id}`),
    upload: async (id: number, formData: FormData) => {
      const { data } = await http.post<Item>(
        `/itens/${id}/fotos`,
        formData
      )
      return data
    },
  },
  manager: {
    create: async (payload: CreateManager) => {
      const { data } = await http.post<Manager>(`/gestores`, payload);
      return data;
    },
    update: async (id: number, payload: Manager) => {
      const { data } = await http.put<Manager>(`/gestores/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/gestores/${id}`),
  },
  scholarity: {
    create: async (payload: any) => {
      const { data } = await http.post<Scholarity>(`/escolaridades`, payload);
      return data;
    },
    update: async (id: number, payload: any) => {
      const { data } = await http.put<Scholarity>(
        `/escolaridades/${id}`,
        payload
      );
      return data;
    },
    delete: async (id: number) => http.delete(`/escolaridades/${id}`),
  },
  selectiveProcess: {
    list: async (params?: any) => {
      const { data } = await http.get<Paginated<SelectiveProcess>>(
        "/processosseletivos/search", { params }
      );
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<SelectiveProcess>(
        `/processosseletivos/${id}`
      );
      return data;
    },
    statistics: async (id: number) => {
      const { data } = await http.get<SelectiveProcessStatistics>(
        `/processosseletivos/${id}/estatisticas`
      );
      return data;
    },
    counters: async (id: number, params: SelectiveProcessCounterParams) => {
      const { data } = await http.get<SelectiveProcess>(
        `/processosseletivos/${id}/counters`, { params }
      );
      return data;
    },
    participants: async (id: number, params: SelectiveProcessParticipantsParams) => {
      const { data } = await http.get<SelectiveProcess>(
        `/processosseletivos/${id}/participantes`, { params }
      );
      return data;
    },
    nopageable: async () => {
      const { data } = await http.get<SelectiveProcess[]>(
        "/processosseletivos/nopageable"
      );
      return data;
    },
    post: async (payload: CreateSelectiveProcess) => {
      const { data } = await http.post<SelectiveProcess>(
        `/processosseletivos`, payload
      );
      return data;
    },
    put: async (id: number, payload: UpdateSelectiveProcess) => {
      const { data } = await http.put<SelectiveProcess>(
        `/processosseletivos/${id}`, payload
      );
      return data;
    },
    delete: async (id: number) => http.delete(`/processosseletivos/${id}`),
  },
  state: {
    list: async () => {
      const { data } = await http.get<State[]>("/estados");
      return data;
    },
    getCities: async (id: number): Promise<City[]> => {
      const { data } = await http.get(`/estados/${id}/cidades`);
      return data;
    },
  },
  stats: {
    totals: async () => {
      const { data } = await http.get("/stats/totais");
      return data;
    },
    totalUniversitiesByState: async (id: number) => {
      const { data } = await http.get(
        `/stats/totais/usuarios/estados/${id}/universidades`
      );
      return data;
    },
    totalsByState: async (id: number) => {
      const { data } = await http.get(`/stats/totais/usuarios/estados/${id}`);
      return data;
    },
    dailyTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/dias`);
      return data;
    },
    monthlyTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/meses`);
      return data;
    },
    scholarityTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/escolaridades`);
      return data;
    },
    stateTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/estados`);
      return data;
    },
    ethnicitiesTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/etnias`);
      return data;
    },
    genderTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/generos`);
      return data;
    },
    ageTotals: async (id: number) => {
      const { data } = await http.get(`/stats/totais/usuarios/faixasetarias`);
      return data;
    },
    suitableTotals: async () => {
      const { data } = await http.get(`/stats/totais/usuarios/adequados`);
      return data;
    },
  },
  subtheme1: {
    list: async () => {
      const { data } = await http.get<Subtheme1[]>("/subtemas1");
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Subtheme1>(`/subtemas1/${id}`);
      return data;
    },
    getSubthemes: async (id: number) => {
      const { data } = await http.get<Subtheme1[]>(`/subtemas1/${id}/subtemas`);
      return data;
    },
    post: async (payload: any) => {
      const { data } = await http.post<Subtheme1>(`/subtemas1`, payload);
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Subtheme1>(`/subtemas1/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/subtemas1/${id}`),
  },
  subtheme2: {
    list: async () => {
      const { data } = await http.get<Subtheme2[]>("/subtemas2");
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Subtheme2>(`/subtemas2/${id}`);
      return data;
    },
    getSubthemes: async (id: number) => {
      const { data } = await http.get<Subtheme2[]>(`/subtemas2/${id}/subtemas`);
      return data;
    },
    post: async (payload: any) => {
      const { data } = await http.post<Subtheme2>(`/subtemas2`, payload);
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Subtheme2>(`/subtemas2/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/subtemas2/${id}`),
  },
  subtheme3: {
    list: async () => {
      const { data } = await http.get<Subtheme3[]>("/subtemas3");
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Subtheme3[]>(`/subtemas3/${id}`);
      return data;
    },
    post: async (payload: any) => {
      const { data } = await http.post<Subtheme3>(`/subtemas3`, payload);
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Subtheme3>(`/subtemas3/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/subtemas3/${id}`),
  },
  theme: {
    list: async () => {
      const { data } = await http.get<Theme[]>("/temas");
      return data;
    },
    get: async (id: number) => {
      const { data } = await http.get<Theme>(`/temas/${id}`);
      return data;
    },
    getSubthemes: async (id: number) => {
      const { data } = await http.get<Theme>(`/temas/${id}/subtemas`);
      return data;
    },
    post: async (nome: any) => {
      const { data } = await http.post<Theme>(`/temas`, { nome });
      return data;
    },
    put: async (id: number, payload: any) => {
      const { data } = await http.put<Theme>(`/temas/${id}`, payload);
      return data;
    },
    delete: async (id: number) => http.delete(`/temas/${id}`),
  },
  user: {
    get: async (id: number) => {
      const { data } = await http.get<User>(`/usuarios/${id}`);
      return data;
    },
    create: async (payload: any) => {
      const { data } = await http.post<User>(`/usuarios`, payload);
      return data;
    },
    update: async (id: number, payload: any) => {
      const { data } = await http.put<User>(`/usuarios/${id}`, payload);
      return data;
    },
    search: async (payload: any) => {
      const { data } = await http.post<Paginated<User>>(
        `/usuarios/pesquisar`,
        payload
      );
      return data;
    },
    searchForIds: async (payload: any) => {
      const { data } = await http.post<number[]>(`/usuarios/pesquisar`, {
        ...payload,
        property: "id",
      });
      return data;
    },
    export: async (payload: any) => {
      const { data } = await http.post<ExportUsers>(
        `/usuarios/exportar`,
        payload
      );
      return data;
    },
    uploadCurriculum: async (id: number, fd: FormData) => {
      const { data } = await http.post<User>(`/usuarios/${id}/curriculos`, fd);
      return data;
    },
    uploadCompanyOpinion: async (id: number, fd: FormData) => {
      const { data } = await http.post<User>(`/usuarios/${id}/pareceres`, fd);
      return data;
    },
    uploadBehavioralProfile: async (id: number, fd: FormData) => {
      const { data } = await http.post<User>(
        `/usuarios/${id}/perfiscomportamentais`,
        fd
      );
      return data;
    },
  },
  country: {
    list: async () => {
      const { data } = await http.get<Country[]>("/paises");
      return data;
    },
  },
  field: {
    list: async () => {
      const { data } = await http.get<Area[]>("/areasdeatuacoes");
      return data;
    },
    post: async (name: string) => {
      const { data } = await http.post<Area>(`/areasdeatuacoes`, {
        nome: name,
      });
      return data;
    },
    put: async (payload: Area) => {
      const { data } = await http.put<Area>(
        `/areasdeatuacoes/${payload.id}`,
        payload
      )
      return data
    },
    delete: async (id: number) => {
      try {
        const { data } = await http.delete<Area>(`/areasdeatuacoes/${id}`);
        return data;
      } catch (error: any) {
        if (error.response.status === 403) {
          throw new Error("Esta área está em uso");
        } else {
          throw new Error("Não foi possível remover a área");
        }
      }
    }
  },
  categories: {
    list: async () => {
      const { data } = await http.get<Category[]>('/categorias')
      return data
    }
  },

  position: {
    list: async () => {
      const { data } = await http.get<Position[]>("/cargos");
      return data;
    },
  },
  experience: {
    create: async (payload: any) => {
      const { data } = await http.post<Experience>(`/experiencias`, payload);
      return data;
    },
    update: async (id: number, payload: any) => {
      const { data } = await http.put<Experience>(
        `/experiencias/${id}`,
        payload
      );
      return data;
    },
    delete: async (id: number) => http.delete(`/experiencias/${id}`),
  },
  market: {
    get: async () =>  {
      const { data } = await http.get<Market>(`/mercados/1`);
      return data;
    },
    update: async (payload: any) =>  {
      const { data } = await http.put<Market>(`/mercados/1`, payload);
      return data;
    }
  },
  trail:{
    create: async(payload: CreateTrail) => {
      const { data } = await http.post<Trail>(`/trilhas`, payload);
      return data;
    },
    update: async(id: number, payload: Trail) => {
      const { data } = await http.put<Trail>(`/trilhas/${id}`, payload);
      return data;
    },
    delete: async(id: number) => {
      const { data } = await http.delete<Trail>(`/trilhas/${id}`);
      return data;
    },
    grouped: async() => {
      const { data } = await http.get<GroupedTrail[]>(`/trilhas/agrupadas`);
      return data;
    },
    getSubtrails: async(id: number) => {
      const { data } = await http.get<Subtrail[]>(`/trilhas/${id}/subtrilhas`);
      return data;
    },
    getPositions: async() => {
      const { data } = await http.get<Position[]>(`/cargos`);
      return data;
    },
    move: async (id: number, payload: any) => {
      await http.put(`/trilhas/${id}`, payload)
    }
  },
  subtrail: {
    create: async(payload: any) => {
      const { data } = await http.post<any>(`/subtrilhas`, payload);
      return data;
    },
    update: async(id: number, payload: any) => {
      const { data } = await http.put<any>(`/subtrilhas/${id}`, payload);
      return data;
    },
    delete: async(id: number) => {
      const { data } = await http.delete<any>(`/subtrilhas/${id}`);
      return data;
    },
    getCombos: async(id: number) => {
      const { data } = await http.get<Combo[]>(`/subtrilhas/${id}/combos`);
      return data;
    },
    move: async (id: number, payload: any) => {
      await http.put(`/subtrilhas/${id}`, payload)
    },
    hide: async (id: number, payload: any) => {
      await http.put(`/subtrilhas/${id}`, payload)
    },
  },
  combo: {
    move: async (id: number, payload: any) => {
      await http.put(`/combos/${id}`, payload)
    },
    create: async(payload: any) => {
      const { data } = await http.post<any>(`/combos`, payload);
      return data;
    },
    update: async(id: number, payload: any) => {
      const { data } = await http.put<any>(`/combos/${id}`, payload);
      return data;
    },
    hide: async (id: number, payload: any) => {
      await http.put(`/combos/${id}`, payload)
    },
    getItems: async(id: number) => {
      const { data } = await http.get<any[]>(`/combos/${id}/itensdecombos`);
      return data;
    },
    delete: async(id: number) => {
      const { data } = await http.delete<any>(`/combos/${id}`);
      return data;
    },
    createItem: async (payload: any) => {
      const { data } = await http.post<any>(`/itensdecombos`, payload);
      return data;
    },
    moveItem: async (id: number, payload: any) => {
      await http.put(`/itensdecombos/${id}`, payload)
    },
    hideItem: async (id: number, payload: any) => {
      await http.put(`/itensdecombos/${id}`, payload)
    },
    deleteItem: async(id: number) => {
      const { data } = await http.delete<any>(`/itensdecombos/${id}`);
      return data;
    },
  },
  product: {
    post: async (payload: any) => {
      const { data } = await http.post(`/produtos/gerar`, payload);
      return data;
    },
  },
  opportunity: {
    list: async() => {
      const { data } = await http.get<Opportunity[]>(`/opportunities`);
      return data.map(el => ({ id: el.id, nome: el.name }));
    }
  }
};
