import { Button, Flex, Spinner } from '@chakra-ui/react'
import { Layout } from 'components/Layout'
import { useContext, useState } from 'react'
import { ContentForm } from './ContentForm'
import { ContentModal } from './ContentModal'
import { ItemContext } from './Provider'
import { SearchResult } from './Search/SearchResult'

export const ContentPage = () => {
  const { loading } = useContext(ItemContext)
  const [isModalOpen, openModal] = useState(false)

  return (
    <Layout header menu footer>
        <Flex direction='column' w='100%' mt={2}>
          <Flex justify={'flex-end'} w='50%' ml='30%'>
            <Button colorScheme='yellow' onClick={() => openModal(true)}>
              Criar
            </Button>
          </Flex>
          <ContentForm />

          {loading ? (
            <Spinner
              thickness='2px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='lg'
              style={{ margin: 'auto' }}
            />
          ) : (
            <SearchResult />
          )}
        </Flex>
        {isModalOpen && (
          <ContentModal isOpen={isModalOpen} onClose={() => openModal(false)} />
        )}
    </Layout>
  )
}
