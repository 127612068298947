import { Box, Button } from "@chakra-ui/react";
import { Props } from "./types";

export const SubmitButton = ({ loading, onClick }: Props) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Box textAlign="right" marginTop="10px">
      <Button
        colorScheme="yellow"
        style={{ color: "white" }}
        size="sm"
        w="100px"
        height="33px"
        type="submit"
        isLoading={loading}
        onClick={handleClick}
      >
        Salvar
      </Button>
    </Box>
  );
};
