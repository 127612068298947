import { MenuButton, MenuItem } from "@chakra-ui/react";
import styled from "styled-components";
import { theme } from "../../../shared/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.green};
`;

export const Title = styled(MenuButton)`
  color: white;
  font-size: 15px;
  display: flex !important;
  font-weight: 500;
  padding: 0 0 10px 10px;

  &:hover {
    font-weight: bold;
  }
`;

export const Item = styled(MenuItem)`
  font-weight: bold;
  font-size: 13px;
`;
