import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Checkbox,
  Button
} from '@chakra-ui/react'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useEffect, useState } from 'react'
import { FaTrash, FaSave } from 'react-icons/fa'
import { api } from 'shared/api'
import { Position } from 'types'
import { Combo } from 'types/trail'

type ComboToUpdate = {
  id: number
  name: string
  isHidden: boolean
  position: Position
  challenge: string
}

type EditComboProps = {
  combo: Combo
  onClose: () => void
}

export const EditCombo = ({ combo, onClose }: EditComboProps) => {
  const toast = useSnackbar()
  const loggedUser = useLocalStorage()
  const [isDialogOpen, openDialog] = useState(false)
  const [positions, setPositions] = useState([] as Position[])
  const [comboToUpdate, setComboToUpdate] = useState({} as ComboToUpdate)

  const updateCombo = async () => {
    try {
      const payload = {
        nome: comboToUpdate.name,
        oculto: comboToUpdate.isHidden,
        cargo: { id: comboToUpdate.position.id },
        desafio: comboToUpdate.challenge,
        criadaPor: loggedUser
      }

      api.combo.update(combo.id, payload)
      toast({
        title: `Combo atualizado com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar combo.',
        status: 'error'
      })
    }
  }

  const remove = async () => {
    try {
      await api.combo.delete(combo.id)
      toast({
        title: `Combo removido com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível remover combo.',
        status: 'error'
      })
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    api.trail.getPositions().then(setPositions)

    setComboToUpdate({
      id: combo.id,
      name: combo.nome,
      isHidden: combo.oculto,
      position: combo.cargo,
      challenge: combo.desafio
    })
  }, [])

  return (
    <>
      <Card w='50%'>
        <CardHeader>
          <Heading size='sm'>Combo {combo.nome}</Heading>
        </CardHeader>
        <CardBody>
          <Flex direction='column' gap={3}>
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input
                type='text'
                value={comboToUpdate.name}
                onChange={({ target }) =>
                  setComboToUpdate({
                    ...comboToUpdate,
                    name: target.value
                  })
                }
              />
            </FormControl>

            <FormControl mt={2}>
              <FormLabel>Desafio</FormLabel>
              <Textarea
                value={comboToUpdate.challenge}
                onChange={({ target }: any) =>
                  setComboToUpdate({
                    ...comboToUpdate,
                    challenge: target.value
                  })
                }
                size='sm'
              />
            </FormControl>

            <FormControl>
              <FormLabel>Cargo</FormLabel>
              <Select
                name='position'
                placeholder='Selecione...'
                value={comboToUpdate.position?.id}
                onChange={({ target }) =>
                  setComboToUpdate({
                    ...comboToUpdate,
                    position: { id: parseInt(target.value), nome: '' }
                  })
                }
              >
                {positions.map(position => (
                  <option key={position.id} value={position.id}>
                    {position.nome}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={comboToUpdate.isHidden}
                onChange={({ target }) =>
                  setComboToUpdate({
                    ...comboToUpdate,
                    isHidden: Boolean(target.checked)
                  })
                }
              >
                Oculta
              </Checkbox>
            </FormControl>

            <Flex justify='flex-end'>
              <Button
                leftIcon={<FaTrash />}
                ml='2'
                size={'sm'}
                onClick={() => {
                  openDialog(true)
                }}
              >
                Excluir
              </Button>
              <Button
                leftIcon={<FaSave />}
                colorScheme='yellow'
                ml='2'
                size={'sm'}
                onClick={updateCombo}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </CardBody>
      </Card>

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName='Combo'
          confirmationMessage={`Deseja realmente remover o combo <strong>${combo.nome}</strong>?`}
          targetId={combo.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
            onClose()
          }}
          onRemoveTarget={remove}
        />
      )}
    </>
  )
}
