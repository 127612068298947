import { useEffect, useState } from "react"
import { api } from "shared/api"
import { List } from "./List"
import { Paginated, SelectiveProcess } from "types"
import { Layout } from "components/Layout"
import { Flex, Text } from "@chakra-ui/react"
import { Formulary } from "./Formulary"

export const SelectiveProcessPage = () => {
  const [paginatedList, setPaginatedList] = useState<Paginated<SelectiveProcess>>()
  const [openForm, setOpenForm] = useState(false)
  const [id, setId] = useState<number>()

  useEffect(()=> {
    load(1)
  },[])

  const load = (page: number) => {
    api.selectiveProcess.list({ page }).then(setPaginatedList)
  }

  const onChangePage = (page: number) => {
    load(page)
  }

  const onEdit = (id: number) => {
    setId(id)
    setOpenForm(true)
  }

  const onSubmit = async () => {
    setOpenForm(false)
    load(1)
  }

  return (
    <Layout header menu footer>
      <Flex direction="column" w="100%">
        <Text fontSize="2xl" mx="auto" my="20px">
          Gerenciamento de Processos Seletivos
        </Text>
        <Flex direction="column" w="95%"  mx="auto">
          <List
            paginatedList={paginatedList}
            onChangePage={onChangePage}
            onEdit={onEdit}
          />
        </Flex>
      </Flex>
      <Formulary
        id={id}
        open={openForm} 
        onSubmit={onSubmit}
        onClose={() => setOpenForm(false)} 
      />
    </Layout>
  )
}