import React from "react";
import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DefaultStat } from "pages/Dashboard/Users/types";

type StatsTableProps = {
  title: string;
  headers: string[];
  values: DefaultStat[];
};

export const StatsTable = ({ title, headers, values }: StatsTableProps) => {
  return (
    <Box>
      <TableContainer>
        <Table variant="striped">
          <TableCaption placement="top">{title}</TableCaption>
          <Thead>
            <Tr>
              <Th>{headers[0]}</Th>
              <Th>{headers[1]}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {values.map((value) => (
              <Tr key={value.total!! * 99}>
                <Td>{value.label}</Td>
                <Td>{value.total}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
