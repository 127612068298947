import { FormControl, FormErrorMessage, Checkbox as ChakraCheckbox } from "@chakra-ui/react"
import { Field } from "formik"

type Props = {
  name: string;
  placeholder: string;
  required?: boolean;
  validate?: (value: string | number) => boolean;
}

export const Checkbox = ({ name, placeholder, required, validate }: Props) => {

  const Component = ({ field, form }: any) => {
    const isInvalid = () => form.errors[name] && form.touched[name];

    const errorMessage = () => form.errors[name]

    return (
      <FormControl 
        isRequired={required}
        isInvalid={isInvalid()}
        >
        <ChakraCheckbox 
          {...field }
          isChecked={field.value}
          name={name}
          validate={validate}
        >
          {placeholder}
        </ChakraCheckbox>
        <FormErrorMessage> {errorMessage()} </FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Field 
      name={name} 
      placeholder={placeholder} 
      component={Component} 
    />
  )
}