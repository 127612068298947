import React, { useState } from "react";
import { SimpleGrid, FormControl } from "@chakra-ui/react";
import { Label } from "./styles";
import { User } from "types";
import { api } from "shared/api";
import { useSnackbar } from "hooks";
import { config } from "config";
import { SubmitButton } from "components/Form/SubmitButton";
import { Link } from "components/Link";
import { Chooser } from "components/Form/Chooser";

type Props = {
  user?: User;
  onClose: () => void;
};

export const Document = ({ user, onClose }: Props) => {
  const [loadingCurriculum, setLoadingCurriculum] = useState(false);
  const [curriculumFile, setCurriculumFile] = useState<File>();
  const [loadingCompanyOpinion, setLoadingCompanyOpinion] = useState(false);
  const [companyOpinionFile, setCompanyOpinionFile] = useState<File>();
  const [loadingBehavioralProfile, setLoadingBehavioralProfile] =
    useState(false);
  const [behavioralProfileFile, setBehavioralProfileFile] = useState<File>();

  const toast = useSnackbar();

  const onSuccess = (title: string) => {
    toast({ title, status: "success" });
    onClose();
  };

  const onError = (title: string) => {
    toast({ title, status: "error" });
  };

  const submit = (name: string) => {
    const id = Number(user?.id);
    const fd = new FormData();

    if (name === "curriculum") {
      if (!curriculumFile) {
        onError("Selecione o arquivo do curriculo");
        return;
      }
      fd.set("arquivo", curriculumFile);
      setLoadingCurriculum(true);
      api.user
        .uploadCurriculum(id, fd)
        .then(() => {
          onSuccess("Curriculo enviado com sucesso");
        })
        .catch(() => {
          onError("Erro ao enviar o curriculo");
        })
        .finally(() => {
          setLoadingCurriculum(false);
        });
    }
    if (name === "opinion") {
      if (!companyOpinionFile) {
        onError("Selecione o arquivo do parecer");
        return;
      }
      fd.set("arquivo", companyOpinionFile);
      setLoadingCompanyOpinion(true);
      api.user
        .uploadCompanyOpinion(id, fd)
        .then(() => {
          onSuccess("Parecer enviado com sucesso");
        })
        .catch(() => {
          onError("Erro ao enviar o parecer");
        })
        .finally(() => {
          setLoadingCompanyOpinion(false);
        });
    }
    if (name === "profile") {
      if (!behavioralProfileFile) {
        onError("Selecione o arquivo do perfil comportamental");
        return;
      }
      fd.set("arquivo", behavioralProfileFile);
      setLoadingBehavioralProfile(true);
      api.user
        .uploadBehavioralProfile(id, fd)
        .then(() => {
          onSuccess("Perfil comportamental enviado com sucesso");
        })
        .catch(() => {
          onError("Erro ao enviar o perfil comportamental");
        })
        .finally(() => {
          setLoadingBehavioralProfile(false);
        });
    }
  };

  return (
    <SimpleGrid columns={3} spacing="10px">
      <FormControl isRequired>
        <Label>
          Currículo
          <Link
            base={config.curriculosPath}
            resource={user?.curriculo.arquivo}
          />
        </Label>
        <Chooser setFile={setCurriculumFile} />
        <SubmitButton
          loading={loadingCurriculum}
          onClick={() => submit("curriculum")}
        />
      </FormControl>
      <FormControl isRequired>
        <Label>
          Parecer Empodera!
          <Link base={config.pareceresPath} resource={user?.parecer} />
        </Label>
        <Chooser setFile={setCompanyOpinionFile} />
        <SubmitButton
          loading={loadingCompanyOpinion}
          onClick={() => submit("opinion")}
        />
      </FormControl>
      <FormControl isRequired>
        <Label>
          Perfil Comportamental
          <Link
            base={config.perfisComportamentaisPath}
            resource={user?.pdfEtalent}
          />
        </Label>
        <Chooser setFile={setBehavioralProfileFile} />
        <SubmitButton
          loading={loadingBehavioralProfile}
          onClick={() => submit("profile")}
        />
      </FormControl>
    </SimpleGrid>
  );
};
