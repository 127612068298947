import { Modal as ChModal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react"

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  minW?: string;
}

export const Modal = ({ open, onClose, title, minW = '600px', children }: Props) => {
  return (
    <ChModal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={minW}>
        <ModalHeader> {title} </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </ModalContent>
    </ChModal>
  )
}