import React, { useEffect, useState } from "react";
import { SimpleGrid, FormControl } from "@chakra-ui/react";
import { Label } from "./styles";
import { Select } from "components/Form/Select";
import { SelectOptions, SelectOptionsValue, User, AcademicForm } from "types";
import { parseAcademicForm } from "helper/user";
import { api } from "shared/api";
import {
  assertions,
  educationConclusions,
  educationLevels,
  educationPeriods,
  statuses,
} from "shared/utils/arrays";
import { Form } from "components/Form";
import { Combo } from "components/Combo";

const initialValues: AcademicForm = {
  id: 0,
  instituicaoId: 0,
  status: "CONCLUIDO",
  cursoId: 0,
  periodo: 1,
  grau: "DESCONHECIDO",
  conclusao: "2025.1",
  bolsista: "NAO",
};

type Props = {
  user?: User;
  onClose: () => void;
};

export const Academic = ({ user, onClose }: Props) => {
  const [initial, setInitial] = useState<AcademicForm>(initialValues)
  const [scholarities, setScholarities] = useState<AcademicForm[]>([]);
  const [options, setOptions] = useState<SelectOptions[]>([]);
  const [currentOption, setCurrentOption] = useState<SelectOptionsValue>();
  const [courses, setCourses] = useState<SelectOptions[]>([]);
  const [institutions, setInstitutions] = useState<SelectOptions[]>([]);
  const [isGraduation, setIsGraduation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    const list = user.escolaridades.map((scholarity) => {
      return {
        id: Number(scholarity.id),
        instituicaoId: Number(scholarity?.instituicao?.id),
        status: scholarity.status,
        cursoId: Number(scholarity.curso?.id),
        periodo: scholarity.periodo || 1,
        grau: scholarity.grau,
        conclusao: scholarity.conclusao,
        bolsista: scholarity.bolsista === "true" ? "SIM" : "NAO",
      };
    });

    setScholarities(list);

    if (list.length) setCurrentOption(String(list[0].id));

    setOptions(
      user.escolaridades.map((el) => {
        const institution = el.instituicao
          ? el.instituicao.nome
          : "Não informado";
        const course = el.curso ? el.curso.nome : "Não informado";
        return {
          id: el.id,
          nome: `ID: ${el.id} - Grau: ${el.grau} - Instituição: ${institution} - Curso: ${course}`,
        };
      })
    );
  }, [user]);

  useEffect(() => {
    api.course.list().then(setCourses);
    api.institution.list().then(setInstitutions);
  }, []);

  useEffect(() => {
    const s = scholarities.find((o) => o.id === Number(currentOption));
    s && setInitial(s);
  }, [currentOption]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const parsed = parseAcademicForm(values);
    if (parsed.id) {
      api.scholarity
        .update(parsed.id, parsed)
        .then(onClose)
        .finally(() => setLoading(false));
    }
  }

  return (
    <SimpleGrid columns={1} spacing="10px">
      <SimpleGrid columns={1} spacing="10px">
        <Combo
          name="option"
          options={options}
          handleChange={setCurrentOption}
        />
      </SimpleGrid>
      <Form 
          initial={initial}
          onSubmit={handleSubmit}
          okLabel="Salvar"
          isLoading={loading}
        >
        <SimpleGrid columns={2} spacing="10px">
          <FormControl>
            <Label>Instituição</Label>
            <Select
              name="instituicaoId"
              options={institutions}
            />
          </FormControl>
          <FormControl>
            <Label>Status</Label>
            <Select
              name="status"
              options={statuses}
            />
          </FormControl>
          <FormControl>
            <Label>Curso</Label>
            <Select
              name="cursoId"
              options={courses}
            />
          </FormControl>
          <FormControl>
            <Label>Grau</Label>
            <Select
              name="grau"
              options={educationLevels}
              onChange={(id) => {
                setIsGraduation(String(id) === 'SUPERIOR')
              }}
            />
          </FormControl>
          {isGraduation && (
            <FormControl>
              <Label>Período</Label>
              <Select
                name="periodo"
                options={educationPeriods}
              />
            </FormControl>
          )}
          <FormControl>
            <Label>Conclusão</Label>
            <Select
              name="conclusao"
              options={educationConclusions}
            />
          </FormControl>
          <FormControl>
            <Label>Bolsista</Label>
            <Select
              name="bolsista"
              options={assertions}
            />
          </FormControl>
        </SimpleGrid>
      </Form>
    </SimpleGrid>
  );
};
