import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
} from '@chakra-ui/react'
import { CustomSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Combo, Position, Subtrail } from 'types/trail'
import { CombosTable } from './CombosTable'
import { EditSubtrail } from './EditSubtrail'
import { NewCombo } from './NewCombo'

type SubtrailModalProps = {
  isOpen: boolean
  onClose: () => void
  subtrail: Subtrail
}


export const SubtrailModal = ({
  isOpen,
  onClose,
  subtrail
}: SubtrailModalProps) => {
  const [loading, isLoading] = useState(false)
  const [positions, setPositions] = useState([] as Position[])
  const [combos, setCombos] = useState([] as Combo[])

  const fetchCombos = async () => {
    isLoading(true)
    const result = await api.subtrail.getCombos(subtrail.id)
    setCombos(result)
    isLoading(false)
  }

  useEffect(() => {
    fetchCombos()
    api.trail.getPositions().then(setPositions)
  }, [])

  return (
    <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Subtrilha</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={5}>
            <EditSubtrail
              subtrail={subtrail}
              positions={positions}
              onClose={onClose}
            />
            <NewCombo
              subtrailId={subtrail.id}
              positions={positions}
              refreshCombos={fetchCombos}
            />
          </Flex>
          <CustomSpinner loading={loading}>
            <CombosTable combos={combos} refreshCombos={fetchCombos} />
          </CustomSpinner>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
