import styled from "styled-components";
import { theme } from "../../shared/theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  heigth: 100vh;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 300px;
  background-color: ${theme.colors.green};
  border-radius: 10px;
  padding: 0 20px;
  color: white;
  margin-top: 250px;
`;

export const Title = styled.p`
  color: ${theme.colors.yellow};
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
`;

export const Subtitle = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
`;
