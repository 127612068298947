import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { api } from 'shared/api'
import { Position } from 'types'

type NewComboProps = {
  subtrailId: number
  positions: Position[]
  refreshCombos: () => void
}

export const NewCombo = ({positions, subtrailId, refreshCombos}: NewComboProps) => {
  const toast = useSnackbar()
  const loggedUser = useLocalStorage()
  const [newCombo, setNewCombo] = useState({} as any)

  const createCombo = async () => {
    try {
      const payload = {
        nome: newCombo.nome,
        cargo: { id: newCombo.cargo?.id },
        criadoPor: { id: loggedUser?.id },
        desafio: newCombo.desafio,
        subtrilha: { id: subtrailId },
        oculto: newCombo.isHidden
      }
      await api.combo.create(payload)
      toast({
        title: `Combo criado com sucesso`,
        status: 'success'
      })
      refreshCombos()
    } catch (error) {
      toast({
        title: 'Não foi possível criar combo.',
        status: 'error'
      })
    } finally {
      setNewCombo({ nome: '', cargo: { id: 0, nome: '' }, desafio: '' })
    }
  }

  return (
    <Card w='50%'>
      <CardHeader>
        <Heading size='sm'>Criar Combo</Heading>
      </CardHeader>
      <CardBody>
        <Flex direction='column' gap={3}>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              type='text'
              value={newCombo.nome}
              onChange={({ target }) =>
                setNewCombo({ ...newCombo, nome: target.value })
              }
            />
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>Desafio</FormLabel>
            <Textarea
              value={newCombo.desafio}
              onChange={({ target }: any) =>
                setNewCombo({
                  ...newCombo,
                  desafio: target.value
                })
              }
              size='sm'
            />
          </FormControl>
          <FormControl>
            <FormLabel>Cargo</FormLabel>
            <Select
              name='position'
              placeholder='Selecione...'
              value={newCombo.cargo?.id}
              onChange={({ target }) =>
                setNewCombo({
                  ...newCombo,
                  cargo: { id: parseInt(target.value), nome: '' }
                })
              }
            >
              {positions.map(position => (
                <option key={position.id} value={position.id}>
                  {position.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <Flex justify='flex-end'>
            <Button
              leftIcon={<FaPlus />}
              colorScheme='yellow'
              ml='2'
              size={'sm'}
              onClick={createCombo}
            >
              Criar
            </Button>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  )
}
