export const bgColor = [
  "rgba(0, 128, 255)",
  "rgba(255, 128, 0)",
  "rgba(0, 153, 76)",
  "rgba(255, 0, 255)",
  "rgba(255, 0, 0)",
];

export const initialInformedEthnicityState = {
  sim: { total: 0, percentual: 0 },
  nao: { total: 0, percentual: 0 },
};
