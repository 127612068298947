import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker"
import { endOfDay, parseISO, format } from "date-fns";
import { Field } from "formik"

type Props = {
  name: string;
  placeholder: string;
  required?: boolean;
  validate?: (value: string | number) => boolean;
}

export const Datepicker = ({ name, placeholder, required, validate }: Props) => {

  const Component = ({ field, form, ...rest }: any) => {
    const isInvalid = () => form.errors[name] && form.touched[name];

    const errorMessage = () => form.errors[name]

    const date =  field.value ? parseISO(field.value) : new Date()

    return (
      <FormControl 
        isRequired={required}
        isInvalid={isInvalid()}
        >
        <SingleDatepicker 
          {...field }
          date={date}
          name={name}
          configs={{
            dateFormat: 'dd/MM/yyyy'
          }}
          validate={validate}
          onDateChange={(date) => {
            const formattedDate = format(endOfDay(date), "yyyy-MM-dd'T'HH:mm:ss'Z'")
            form.setFieldValue(name, formattedDate)}
          }
        />
        <FormErrorMessage> {errorMessage()} </FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Field 
      name={name} 
      placeholder={placeholder} 
      component={Component} 
    />
  )
}