import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Button
} from '@chakra-ui/react'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useEffect, useState } from 'react'
import { FaTrash, FaSave } from 'react-icons/fa'
import { api } from 'shared/api'
import { Position } from 'types'
import { Subtrail } from 'types/trail'

type SubtrailToUpdate = {
  id: number
  name: string
  isHidden: boolean
  position: Position
}

type EditSubtrailProps = {
  subtrail: Subtrail
  positions: Position[]
  onClose: () => void
}
export const EditSubtrail = ({ subtrail, positions, onClose }: EditSubtrailProps) => {
  const toast = useSnackbar()
  const loggedUser = useLocalStorage()
  const [isDialogOpen, openDialog] = useState(false)
  const [subtrailToUpdate, setSubtrailToUpdate] = useState(
    {} as SubtrailToUpdate
  )

  const updateSubtrail = async () => {
    try {
      const payload = {
        nome: subtrailToUpdate.name,
        oculto: subtrailToUpdate.isHidden,
        cargo: { id: subtrailToUpdate.position.id },
        criadaPor: loggedUser
      }

      api.subtrail.update(subtrail.id, payload)
      toast({
        title: `Subtrilha atualizada com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar subtrilha.',
        status: 'error'
      })
    }
  }

  const remove = async () => {
    try {
      await api.subtrail.delete(subtrail.id)
      toast({
        title: `Subtrilha removida com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível remover subtrilha.',
        status: 'error'
      })
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    setSubtrailToUpdate({
      id: subtrail.id,
      name: subtrail.nome,
      isHidden: subtrail.oculto,
      position: subtrail.cargo
    })
  }, [])

  return (
    <>
      <Card w='50%'>
        <CardHeader>
          <Heading size='sm'>Subtrilha {subtrail.nome}</Heading>
        </CardHeader>
        <CardBody>
          <Flex direction='column' gap={3}>
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input
                type='text'
                value={subtrailToUpdate.name}
                onChange={({ target }) =>
                  setSubtrailToUpdate({
                    ...subtrailToUpdate,
                    name: target.value
                  })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Cargo</FormLabel>
              <Select
                name='position'
                placeholder='Selecione...'
                value={subtrailToUpdate.position?.id}
                onChange={({ target }) =>
                  setSubtrailToUpdate({
                    ...subtrailToUpdate,
                    position: { id: parseInt(target.value), nome: '' }
                  })
                }
              >
                {positions.map(position => (
                  <option key={position.id} value={position.id}>
                    {position.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Checkbox
                isChecked={subtrailToUpdate.isHidden}
                onChange={({ target }) =>
                  setSubtrailToUpdate({
                    ...subtrailToUpdate,
                    isHidden: Boolean(target.checked)
                  })
                }
              >
                Oculta
              </Checkbox>
            </FormControl>
            <Flex justify='flex-end'>
              <Button
                leftIcon={<FaTrash />}
                ml='2'
                size={'sm'}
                onClick={() => {
                  openDialog(true)
                }}
              >
                Excluir
              </Button>
              <Button
                leftIcon={<FaSave />}
                colorScheme='yellow'
                ml='2'
                size={'sm'}
                onClick={updateSubtrail}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </CardBody>
      </Card>

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName='Subtrilha'
          confirmationMessage={`Deseja realmente remover a subtrilha <strong>${subtrail.nome}</strong>?`}
          targetId={subtrail.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
          }}
          onRemoveTarget={remove}
        />
      )}
    </>
  )
}
