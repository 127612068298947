import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, Input, SimpleGrid } from "@chakra-ui/react";
import { Field } from "formik";
import { State } from "types";
import { api } from "shared/api";
import {
  ethnicities,
  findElements,
  genders,
  orientations,
  disabilityTypes,
  accountStatus,
} from "shared/utils/arrays";
import { Picker } from "./style";

type Props = {
  initial: any;
  handleInput: (event: any) => void;
  handlePicker: (name: string, value: number[]) => void;
};

export const PersonalForm = ({ initial, handleInput, handlePicker }: Props) => {
  const [states, setStates] = useState<State[]>([]);

  useEffect(() => {
    api.state.list().then(setStates);
  }, []);

  return (
    <>
      <SimpleGrid columns={3} spacing={5} style={{ marginBottom: "1rem" }}>
        <Field name="id">
          {({ field }: any) => (
            <FormControl>
              <Input
                {...field}
                placeholder="Id"
                name="id"
                type="number"
                value={initial.id}
                onChange={handleInput}
              />
            </FormControl>
          )}
        </Field>
        <Field name="email">
          {({ field }: any) => (
            <FormControl>
              <Input
                {...field}
                placeholder="E-mail"
                name="email"
                value={initial.email}
                onChange={handleInput}
              />
            </FormControl>
          )}
        </Field>
        <Picker
          value={findElements(accountStatus, initial.statusDaConta)}
          onChange={(value: any) =>
            handlePicker(
              "statusDaConta",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Status da Conta"
          options={accountStatus}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
      </SimpleGrid>
      <div>
        <SimpleGrid columns={4} spacing={5} style={{ marginTop: "1rem" }}>
          <Field name="nome">
            {({ field }: any) => (
              <FormControl>
                <Input
                  {...field}
                  placeholder="Nome"
                  name="nome"
                  value={initial.nome}
                  onChange={handleInput}
                />
              </FormControl>
            )}
          </Field>
          <SimpleGrid columns={2} spacing={2}>
            <Field name="startAge">
              {({ field }: any) => (
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Idade de"
                    name="startAge"
                    value={initial.startAge}
                    onChange={handleInput}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="endAge">
              {({ field }: any) => (
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Idade até"
                    name="endAge"
                    value={initial.endAge}
                    onChange={handleInput}
                  />
                </FormControl>
              )}
            </Field>
          </SimpleGrid>
          <Picker
            value={findElements(states, initial.estado)}
            onChange={(value: any) =>
              handlePicker(
                "estado",
                value.map((v: any) => v.id)
              )
            }
            placeholder="Estado"
            options={states}
            isMulti
            getOptionLabel={(data: any) => data.nome}
            getOptionValue={(data: any) => String(data.id)}
          />
          <SimpleGrid columns={2} spacing={2}>
            <Checkbox
              name="imigrante"
              isChecked={initial.imigrante}
              onChange={handleInput}
            >
              Imigrante
            </Checkbox>
            <Checkbox
              name="refugiado"
              isChecked={initial.refugiado}
              onChange={handleInput}
            >
              Refugiado
            </Checkbox>
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={4} spacing={5} style={{ marginTop: "1rem" }}>
          <Picker
            value={findElements(genders, initial.genero)}
            onChange={(value: any) =>
              handlePicker(
                "genero",
                value.map((v: any) => v.id)
              )
            }
            placeholder="Gênero"
            options={genders}
            isMulti
            getOptionLabel={(data: any) => data.nome}
            getOptionValue={(data: any) => String(data.id)}
          />
          <Picker
            value={findElements(ethnicities, initial.etnia)}
            onChange={(value: any) =>
              handlePicker(
                "etnia",
                value.map((v: any) => v.id)
              )
            }
            placeholder="Raça ou Cor"
            options={ethnicities}
            isMulti
            getOptionLabel={(data: any) => data.nome}
            getOptionValue={(data: any) => String(data.id)}
          />
          <Picker
            value={findElements(orientations, initial.orientacaoSexual)}
            onChange={(value: any) =>
              handlePicker(
                "orientacaoSexual",
                value.map((v: any) => v.id)
              )
            }
            placeholder="Orientação Sexual"
            options={orientations}
            isMulti
            getOptionLabel={(data: any) => data.nome}
            getOptionValue={(data: any) => String(data.id)}
          />
          <SimpleGrid columns={2} spacing={2}>
            <Checkbox
              name="travesti"
              isChecked={initial.travesti}
              onChange={handleInput}
            >
              Travesti
            </Checkbox>
            <Checkbox
              name="transexual"
              isChecked={initial.transexual}
              onChange={handleInput}
            >
              Transexual
            </Checkbox>
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={4} spacing={5} style={{ marginTop: "1rem" }}>
          {initial.pcd && (
            <Picker
              value={findElements(disabilityTypes, initial.tipoDeficiencia)}
              onChange={(value: any) =>
                handlePicker(
                  "tipoDeficiencia",
                  value.map((v: any) => v.id)
                )
              }
              placeholder="Deficiências"
              options={disabilityTypes}
              isMulti
              getOptionLabel={(data: any) => data.nome}
              getOptionValue={(data: any) => String(data.id)}
            />
          )}
          <Checkbox name="pcd" isChecked={initial.pcd} onChange={handleInput}>
            PCD
          </Checkbox>
        </SimpleGrid>
      </div>
    </>
  );
};
