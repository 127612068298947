import { api } from "shared/api";
import { useSnackbar } from "./snackbar";

export const useSaveUser = () => {
  const toast = useSnackbar();

  return (
    userId: number,
    data: any,
    setLoading: (value: boolean) => void,
    onClose: () => void
  ) => {
    setLoading(true);

    if (userId) {
      api.user
        .update(userId, data)
        .then(() => {
          toast({
            title: `Perfil atualizado com sucesso`,
            status: "success",
          });
          onClose();
        })
        .catch(() => {
          toast({
            title: `Erro ao atualizar o perfil`,
            status: "error",
          });
        })
        .finally(() => setLoading(false));
    } else {
      api.user
        .create(data)
        .then(() => {
          toast({
            title: `Perfil criado com sucesso`,
            status: "success",
          });
          onClose();
        })
        .catch(() => {
          toast({
            title: `Erro ao criar o perfil`,
            status: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };
};
