import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useSnackbar } from "hooks";
import { useContext } from "react";
import { useState } from "react";
import { api } from "shared/api";
import { CompanyForm } from "../CompanyForm";
import { CompaniesContext } from "../CompaniesProvider";
import { CompanyFormValue } from "types/company";

type EditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyFormValue;
};

export const EditModal = ({ isOpen, onClose, company }: EditModalProps) => {
  const toast = useSnackbar();
  const [loading, isLoading] = useState(false);
  const { uploadImage, fetchCompanies, searchParams } =
    useContext(CompaniesContext);

  const update = async (editedCompany: CompanyFormValue) => {
    isLoading(true);
    try {
      await api.company.put(Number(editedCompany.id), editedCompany);
      toast({
        title: `Empresa ${editedCompany.nome} salva com sucesso`,
        status: "success",
      });
      onClose();
      fetchCompanies(searchParams);
    } catch (error) {
      toast({
        title: `Não foi possível salvar a empresa ${company.nome}`,
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Empresa - ID {company.id} </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CompanyForm
            okLabel="Salvar"
            cancelLabel="Fechar"
            loading={loading}
            companyToEdit={company}
            uploadImage={(event) => {
              uploadImage(Number(company.id), event);
              fetchCompanies(searchParams);
              onClose();
            }}
            update={update}
            handleClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
