import {
  Flex,
  Card,
  CardHeader,
  Heading,
  CardBody,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { useSnackbar } from 'hooks'
import { useState } from 'react'
import { api } from 'shared/api'

type ItemsTableProps = {
  comboId: number
  items: any[]
  refreshItems: () => void
  onClose: () => void
}

export const ItemsTable = ({
  comboId,
  items,
  refreshItems,
  onClose
}: ItemsTableProps) => {
  const toast = useSnackbar()
  const [isDialogOpen, openDialog] = useState(false)
  const [pickedItem, setPickedItem] = useState({} as any)

  const setHidden = async (itemId: number, hide: boolean) => {
    await api.combo.hideItem(itemId, { id: itemId, oculto: hide })
    refreshItems()
  }

  const moveItemUp = async (item: any, otherItem: any) => {
    const { id, posicao } = item

    await api.combo.moveItem(id, { id, posicao: posicao - 1 })
    await api.combo.moveItem(otherItem.id, {
      id: otherItem.id,
      posicao: otherItem.posicao + 1
    })
    refreshItems()
  }

  const moveItemDown = async (item: any, otherItem: any) => {
    const { id, posicao } = item

    await api.combo.moveItem(id, { id, posicao: posicao + 1 })
    await api.combo.moveItem(otherItem.id, {
      id: otherItem.id,
      posicao: otherItem.posicao - 1
    })
    refreshItems()
  }

  const removeItem = async (id: number) => {
    try {
      await api.combo.deleteItem(id)
      toast({
        title: `Item removido com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível remover item.',
        status: 'error'
      })
    } finally {
      refreshItems()
    }
  }

  return (
    <Flex mt={5} mb={5} justify='center'>
      <Card w='100%'>
        <CardHeader>
          <Heading size='sm'>Itens Incluídos</Heading>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant='striped' size='sm'>
              <TableCaption placement='top'>
                <b>{items.length}</b> resultado(s) encontrado(s)
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Título Reduzido</Th>
                  <Th>Criada por</Th>
                  <Th>Ordernar</Th>
                  <Th>Ocultar</Th>
                  <Th>#</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item, index) => (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{item.item.tituloReduzido}</Td>
                    <Td>{item.criadoPor?.nome}</Td>
                    <Td textAlign='center'>
                      {index !== items.length - 1 && (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faArrowDown}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Para baixo'
                          onClick={() => moveItemDown(item, items[index + 1])}
                        />
                      )}
                      {index > 0 && (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faArrowUp}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Para cima'
                          onClick={() => moveItemUp(item, items[index - 1])}
                        />
                      )}
                    </Td>
                    <Td textAlign='center'>
                      {item.oculto ? (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faEye}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Não Ocultar'
                          onClick={() => {
                            setHidden(item.id, false)
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color='gray'
                          icon={faEyeSlash}
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          title='Não Ocultar'
                          onClick={() => {
                            setHidden(item.id, true)
                          }}
                        />
                      )}
                    </Td>
                    <Td>
                      <FontAwesomeIcon
                        color='gray'
                        icon={faTrashCan}
                        style={{
                          marginRight: '10px',
                          cursor: 'pointer'
                        }}
                        title='Remover'
                        onClick={() => {
                          setPickedItem(item)
                          openDialog(true)
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName='Combo'
          confirmationMessage={`Deseja realmente remover o item <strong>${pickedItem.item.tituloReduzido}</strong>?`}
          targetId={comboId}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
          }}
          onRemoveTarget={removeItem}
        />
      )}
    </Flex>
  )
}
