import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { GroupedTrail } from 'types/trail'
import { TrailCard } from './TrailCard'

type GroupedTrailsTableProps = {
  trails: GroupedTrail[]
  refreshTrails: () => void
}

export const GroupedTrailsList = ({ trails, refreshTrails }: GroupedTrailsTableProps) => {
  return (
    <Flex direction={'column'} w={'90%'} style={{ marginLeft: '5%' }} mt={5}>
      <Text style={{ marginLeft: '40%' }} mb={5}>
        <b>{trails.length}</b> resultados encontrados
      </Text>

      {trails.length > 0 && (
        <SimpleGrid gap={3} mb={5}>
          {trails.map(trail => (
            <TrailCard
              key={trail.empresa.id}
              groupedTrail={trail}
              refreshTrails={refreshTrails}
            />
          ))}
        </SimpleGrid>
      )}
    </Flex>
  )
}
