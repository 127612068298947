import React, { useEffect, useState } from "react";
import { PieChart } from "components/Chart/PieChart";
import { api } from "../../../../shared/api";
import { useSnackbar } from "hooks";
import { Flex } from "@chakra-ui/react";
import { StatsTable } from "components/Table/StatsTable";
import { initialStatState } from "../types";
import { initialDatasetState } from "components/Chart/PieChart/types";
import { bgColor } from "./types";

export const GenderChart = () => {
  const toast = useSnackbar();
  const [datasets, setDatasets] = useState([initialDatasetState]);
  const [values, setValues] = useState([initialStatState]);

  useEffect(() => {
    async function fetchGenders() {
      const genderTotals = await api.stats.genderTotals();
      return genderTotals;
    }

    fetchGenders()
      .then((totals) => {
        setValues([
          { label: "Masculino", total: totals.masculino },
          { label: "Feminino", total: totals.feminino },
          { label: "Não Binário", total: totals.naobinario },
        ]);

        setDatasets([
          {
            backgroundColor: bgColor,
            data: [totals.masculino, totals.feminino, totals.naobinario],
          },
        ]);
      })
      .catch((error) => {
        toast({
          title: "Não foi possível atualizar estatísticas de gênero",
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="15px">
      <PieChart
        title={"Usuários por gênero - Gráfico"}
        labels={["Masculino", "Feminino", "Não Binário"]}
        datasets={datasets}
      />
      <StatsTable
        title="Usuários por gênero - Totais"
        headers={["Gênero", "Total"]}
        values={values}
      />
    </Flex>
  );
};
