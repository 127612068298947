import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tag,
  Textarea,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "components/Form";
import { Field } from "formik";
import { useContext, useEffect, useMemo, useState, KeyboardEvent } from "react";
import { Gender } from "types";
import { Area, Nationality, Package, Size, Sponsor } from "types/company";
import { CompaniesContext } from "./CompaniesProvider";
import { FormProps, initialState } from "./types";

export const CompanyForm = ({
  okLabel,
  cancelLabel,
  loading,
  companyToEdit,
  search,
  create,
  update,
  onResetResult,
  uploadImage,
  handleClose,
}: FormProps) => {
  const [company, setCompany] = useState(initialState);
  const [value, setValue] = useState("");
  const showImageUpload = useMemo(() => okLabel !== "Pesquisar", [okLabel]);
  const editMode = useMemo(() => okLabel === "Salvar", [okLabel]);
  const { areas } = useContext(CompaniesContext);

  const handleReset = () => {
    switch (okLabel) {
      case "Pesquisar":
        onResetResult!!();
        break;
      case "Salvar":
        handleClose!!();
        break;
    }
    setCompany(initialState);
  };

  const onSubmit = () => {
    switch (okLabel) {
      case "Pesquisar":
        search!!(company);
        break;
      case "Cadastrar":
        create!!(company);
        setCompany(initialState);
        break;
      case "Salvar":
        update!!(company);
        setCompany(initialState);
        break;
    }
  };

  const addTag = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const tags = company.valores
        ? [...(company.valores as string[]), value]
        : [value];
      setCompany({ ...company, valores: tags });
      setValue("");
    }
  };

  useEffect(() => {
    if (companyToEdit) {
      setCompany({
        ...companyToEdit,
        slug: companyToEdit.slug ? companyToEdit.slug : "",
        area: (companyToEdit.area as Area).id,
      });
    }
  }, []);

  return (
    <Form
      initial={company}
      onSubmit={onSubmit}
      onReset={handleReset}
      isLoading={loading}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
    >
      <Field name="nome">
        {({ field }: any) => (
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              {...field}
              name="nome"
              type="text"
              value={company.nome}
              mb="15px"
              onChange={({ target }) =>
                setCompany({ ...company, nome: target.value })
              }
            />
          </FormControl>
        )}
      </Field>
      {okLabel !== "Pesquisar" && (
        <Field name="tratamento">
          {({ field }: any) => (
            <FormControl mb={5}>
              <FormLabel>Tratamento</FormLabel>
              <RadioGroup
                onChange={(value) =>
                  setCompany({
                    ...company,
                    tratamento: value as Gender,
                  })
                }
                value={company.tratamento}
              >
                <Stack direction="row">
                  <Radio value="MASCULINO">Masculino</Radio>
                  <Radio value="FEMININO">Feminino</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      )}
      <Field name="area">
        {({ field }: any) => (
          <FormControl>
            <FormLabel>Área de atuação</FormLabel>
            <Select
              icon={<FontAwesomeIcon icon={faCaretDown} />}
              name="area"
              placeholder="Selecione..."
              value={company.area as string}
              mb="15px"
              onChange={({ target }) =>
                setCompany({
                  ...company,
                  area: target.value,
                })
              }
            >
              {areas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.nome}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </Field>
      <Field name="porte">
        {({ field }: any) => (
          <FormControl>
            <FormLabel>Porte</FormLabel>
            <Select
              icon={<FontAwesomeIcon icon={faCaretDown} />}
              name="porte"
              placeholder="Selecione..."
              value={company.porte}
              mb="15px"
              onChange={({ target }) =>
                setCompany({
                  ...company,
                  porte: target.value as Size,
                })
              }
            >
              <option value="AUTONOMA">Atividade autônoma</option>
              <option value="MICRO">Microempresa</option>
              <option value="PEQUENO">Pequeno porte</option>
              <option value="MEDIO">Médio porte</option>
              <option value="GRANDE">Grande porte</option>
            </Select>
          </FormControl>
        )}
      </Field>
      <Field name="nacionalidade">
        {({ field }: any) => (
          <FormControl mb={5}>
            <FormLabel>Nacionalidade</FormLabel>
            <RadioGroup
              onChange={(value) =>
                setCompany({
                  ...company,
                  nacionalidade: value as Nationality,
                })
              }
              value={company.nacionalidade}
            >
              <Stack direction="row">
                <Radio value="NACIONAL">Nacional</Radio>
                <Radio value="MULTINACIONAL">Multinacional</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
      </Field>
      {editMode && (
        <Field name="pacote">
          {({ field }: any) => (
            <FormControl>
              <FormLabel>Pacote</FormLabel>
              <Select
                icon={<FontAwesomeIcon icon={faCaretDown} />}
                name="pacote"
                placeholder="Selecione..."
                value={company.pacote}
                mb="15px"
                onChange={({ target }) =>
                  setCompany({
                    ...company,
                    pacote: target.value as Package,
                  })
                }
              >
                <option value="NAOCONTRATADO">Não contratado</option>
                <option value="GOLD">Gold</option>
                <option value="BLACK">Black</option>
                <option value="PLATINUM">Platinum</option>
              </Select>
            </FormControl>
          )}
        </Field>
      )}
      {editMode && (
        <Field name="patrocinio">
          {({ field }: any) => (
            <FormControl>
              <FormLabel>Patrocínio</FormLabel>
              <Select
                icon={<FontAwesomeIcon icon={faCaretDown} />}
                name="patrocinio"
                placeholder="Selecione..."
                value={company.sponsor}
                mb="15px"
                onChange={({ target }) =>
                  setCompany({
                    ...company,
                    sponsor: target.value as Sponsor,
                  })
                }
              >
                <option value="NONE">Nenhum</option>
                <option value="BRONZE">Bronze</option>
                <option value="SILVER">Prata</option>
                <option value="GOLD">Ouro</option>
                <option value="PLATINUM">Platina</option>
              </Select>
            </FormControl>
          )}
        </Field>
      )}
      {editMode && (
        <Field name="slug">
          {({ field }: any) => (
            <FormControl>
              <FormLabel>URL customizada</FormLabel>
              <Input
                {...field}
                name="slug"
                type="text"
                value={company.slug}
                mb="15px"
                w={"100%"}
                onChange={({ target }) =>
                  setCompany({ ...company, slug: target.value })
                }
              />
            </FormControl>
          )}
        </Field>
      )}
      {(okLabel === "Pesquisar" || editMode) && (
        <Field name="capacitacao">
          {({ field }: any) => (
            <FormControl mb={5}>
              <FormLabel>Capacitação</FormLabel>
              <RadioGroup
                onChange={(value) => {
                  setCompany({
                    ...company,
                    capacitacao: value,
                  });
                }}
                value={String(company.capacitacao)}
              >
                <Stack direction="row">
                  <Radio value="true">Liberada</Radio>
                  <Radio value="false">Bloqueada</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      )}
      {editMode && (
        <Field name="codigo">
          {({ field }: any) => (
            <FormControl>
              <FormLabel>Código</FormLabel>
              <Input
                {...field}
                name="codigo"
                type="text"
                value={company.codigo}
                mb="15px"
                w={"100%"}
                onChange={({ target }) =>
                  setCompany({ ...company, codigo: target.value })
                }
              />
            </FormControl>
          )}
        </Field>
      )}
      {editMode && (
        <Field name="valores">
          {({ field }: any) => (
            <FormControl>
              <FormLabel>Valores</FormLabel>
              <Input
                {...field}
                name="valores"
                type="text"
                value={value}
                mb="15px"
                w={"100%"}
                onChange={(event) => setValue(event.target.value)}
                onKeyDown={addTag}
              />
              {company.valores?.map((valor) => (
                <Tag key={valor.length * Math.random()} ml="1" mb="1" mt="2">
                  <TagLabel>{valor}</TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      const updatedValues = company.valores?.filter(
                        (value) => value !== valor
                      );
                      setCompany({ ...company, valores: updatedValues });
                    }}
                  />
                </Tag>
              ))}
            </FormControl>
          )}
        </Field>
      )}
      {okLabel === "Pesquisar" && (
        <Field name="cliente">
          {({ field }: any) => (
            <FormControl mb={5}>
              <FormLabel>Cliente</FormLabel>
              <Stack spacing={5} direction="row">
                <Checkbox
                  isChecked={company.cliente}
                  onChange={({ target }) =>
                    setCompany({
                      ...company,
                      cliente: Boolean(target.checked),
                    })
                  }
                >
                  Cliente
                </Checkbox>
              </Stack>
            </FormControl>
          )}
        </Field>
      )}
      {editMode && (
        <Field name="minibio">
          {({ field }: any) => (
            <FormControl mt="8px">
              <FormLabel>Mini bio</FormLabel>
              <Textarea
                value={company.minibio}
                onChange={({ target }: any) =>
                  setCompany({
                    ...company,
                    minibio: target.value,
                  })
                }
                size="sm"
              />
            </FormControl>
          )}
        </Field>
      )}
      {showImageUpload && (
        <Field name="imagem">
          {({ field }: any) => (
            <FormControl mt="8px">
              <FormLabel>Imagem</FormLabel>
              <Input
                {...field}
                name="imagem"
                type="file"
                pt="8px"
                w={"100%"}
                onChange={uploadImage}
              />
            </FormControl>
          )}
        </Field>
      )}
    </Form>
  );
};
