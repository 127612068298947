import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Theme } from 'types'

type ModalProps = {
  handleClose: () => void
  open: boolean
  themeToEdit: Theme
}

export const ThemeModal = ({
  open,
  handleClose,
  themeToEdit
}: ModalProps) => {
  const [theme, setTheme] = useState({id: 0, nome: ''} as Theme)
  const toast = useSnackbar()

  const onEdit = async () => {
    try {
      await api.theme.put(theme.id, theme)
      handleClose()
      toast({
        title: 'Tema atualizado com sucesso',
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar o tema',
        status: 'error'
      })
    }
  }

  const resetAndClose = () => {
    setTheme({} as Theme)
    handleClose()
  }

  useEffect(() => {
    setTheme({...themeToEdit})
  }, [themeToEdit])

  return (
    <Modal isOpen={open} onClose={resetAndClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Tema</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              value={theme.nome}
              onChange={({ target }) =>
                setTheme({ ...theme, nome: target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='yellow'
            variant='outline'
            mr={3}
            onClick={resetAndClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme='yellow'
            onClick={onEdit}
            disabled={theme.nome === ''}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
