import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;