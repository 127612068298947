import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const Paginator = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
