import React from "react";
import { Divider, Flex } from "@chakra-ui/react";
import { Layout } from "components/Layout";
import { GenderChart } from "./GenderChart";
import { RaceChart } from "./RaceChart";
import { DaysChart } from "./DaysChart";
import { MonthsChart } from "./MonthsChart";
import { StatesChart } from "./StatesChart";
import { UsersByState } from "./UsersByState";
import { UsersByScholarity } from "./UsersByScholarity";
import { SuitableProfilesChart } from "./SuitableProfiles";
import { UniversitiesChart } from "./Universities";

export const Users = () => {
  return (
    <Layout header menu footer>
      <Flex direction="column" w="100%">
        <SuitableProfilesChart />
        <GenderChart />
        <Divider />
        <RaceChart />
        <Divider />
        <DaysChart />
        <Divider />
        <MonthsChart />
        <Divider />
        <StatesChart />
        <Divider />
        <UniversitiesChart />
        <Divider />
        <UsersByState />
        <UsersByScholarity />
      </Flex>
    </Layout>
  );
};
