import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react"
import { createRef } from "react"

type ConfirmationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  message: string
  handleConfirmation: () => void
};

export const ConfirmationDialog = ({isOpen, onClose, message, handleConfirmation}: ConfirmationDialogProps) => {
  const cancelRef = createRef<HTMLButtonElement>()
  
  return (
    <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Confirmação
            </AlertDialogHeader>

            <AlertDialogBody>{message}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="teal" onClick={handleConfirmation} ml={3}>
              Confirmar
            </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
  )
}