import {
  FormLabel,
  Flex,
  Select,
  Text,
  Button,
  Tbody,
  Thead,
  Th,
  Tr,
  TableContainer,
  Table,
  TableCaption,
  Checkbox,
  Td,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper
} from '@chakra-ui/react'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout } from 'components/Layout'
import { format } from 'date-fns'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Market } from 'types'
import { ConfirmationDialog } from './ConfirmationDialog'

export const MarketPage = () => {
  const toast = useSnackbar()
  const [market, setMarket] = useState({} as Market)
  const [inventory, setInventory] = useState(0)
  const [isDialogOpen, openDialog] = useState(false)
  const [openMarket, setOpenMarket] = useState(false)

  const updateInventory = async (type: string) => {
    const { user }: any = JSON.parse(
      localStorage.getItem('@empodera/backoffice')!!
    )
    await api.product.post({
      tipo: type,
      quantidade: inventory,
      criadoPor: { id: user.id }
    })
    fetchMarket()
    setInventory(0)
  }

  const handleConfirmation = async () => {
    try {
      await api.market.update({ aberto: openMarket })
      fetchMarket()
      openDialog(false)
      toast({
        title: `Mercado atualizado com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: `Erro ao atualizar mercado`,
        status: 'error'
      })
    }
  }

  const fetchMarket = () => {
    api.market.get().then(setMarket)
  }

  const handleUpdate = async () => {
    try {
      await api.market.update(market)
      fetchMarket()
      toast({
        title: `Produtos atualizados com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: `Erro ao atualizar produtos`,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    fetchMarket()
  }, [])

  useEffect(() => {
    setOpenMarket(market.aberto)
  }, [market.aberto])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='80%' style={{ marginLeft: '10%' }}>
        <Flex
          direction='column'
          w='50%'
          h='20%'
          p={3}
          mt={5}
          style={{ marginLeft: '25%' }}
        >
          <FormControl>
            <FormLabel>O mercado está</FormLabel>
            <Select
              name='openMarket'
              value={openMarket?.toString() || 'false'}
              onChange={({ target }) => setOpenMarket(target.value === 'true')}
              fontWeight='semibold'
            >
              <option key={1} value={'true'}>
                Aberto
              </option>
              <option key={0} value={'false'}>
                Fechado
              </option>
            </Select>
          </FormControl>

          <Text mt={5} textAlign='center' fontSize={'sm'}>
            Última abertura em{' '}
            <b>
              {market.abriuEm &&
                format(new Date(market.abriuEm), 'dd/MM/yyyy HH:mm:ss')}
            </b>
          </Text>

          <Flex justify='flex-end' mt={4}>
            <Button
              size={'sm'}
              colorScheme='yellow'
              onClick={() => openDialog(true)}
            >
              Salvar
            </Button>
          </Flex>
        </Flex>

        <Flex direction='column' w='50%' mt={5} style={{ marginLeft: '25%' }}>
          <TableContainer>
            <Table variant='simple' size='sm'>
              <TableCaption placement='top'>
                <b>Produtos</b>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Habilitar</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Vale Cultura</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirCultura}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirCultura: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Transporte</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirTransporte}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirTransporte: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Livro</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirLivro}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirLivro: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Xerox</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirXerox}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirXerox: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Alimentação</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirAlimentacao}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirAlimentacao: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Refeição</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirRefeicao}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirRefeicao: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vale Mentoria</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirMentoria}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirMentoria: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Aprendizado</Td>
                  <Td align='justify'>
                    <Checkbox
                      pl='30px'
                      isChecked={market.permitirAprendizado}
                      onChange={({ target }) =>
                        setMarket({
                          ...market,
                          permitirAprendizado: target.checked
                        })
                      }
                    ></Checkbox>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Flex justify='flex-end' mt={4}>
            <Button size={'sm'} colorScheme='yellow' onClick={handleUpdate}>
              Salvar
            </Button>
          </Flex>
        </Flex>

        <Flex
          direction='column'
          w='100%'
          mt={5}
          mb={5}
        >
          <TableContainer>
            <Table variant='simple' size='sm'>
              <TableCaption placement='top'>
                <b>Estoque</b>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Produto</Th>
                  <Th>Disponível</Th>
                  <Th>Quantidade</Th>
                  <Th>#</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Vale Refeição</Td>
                  <Td>{market.estoque?.refeicao}</Td>
                  <Td align='left'>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALEREFEICAO')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Alimentação</Td>
                  <Td>{market.estoque?.alimentacao}</Td>
                  <Td align='left'>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) =>
                        updateInventory('VALEALIMENTACAO')
                      }
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Transporte Municipal </Td>
                  <Td align='justify'>
                    {market.estoque?.transportes?.municipal}
                  </Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) =>
                        updateInventory('VALETRANSPORTEMUNICIPAL')
                      }
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Transporte Intermunicipal</Td>
                  <Td align='justify'>
                    {market.estoque?.transportes?.intermunicipal}
                  </Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) =>
                        updateInventory('VALETRANSPORTEINTERMUNICIPAL')
                      }
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Livro de R$ 40,00</Td>
                  <Td align='justify'>{market.estoque?.livros?.livro1}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALELIVRO1')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Livro de R$ 75,00</Td>
                  <Td align='justify'>{market.estoque?.livros?.livro2}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALELIVRO2')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Livro de R$ 100,00</Td>
                  <Td align='justify'>{market.estoque?.livros?.livro3}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALELIVRO3')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Cultura de R$ 50,00</Td>
                  <Td align='justify'>{market.estoque?.culturas?.cultura1}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALECULTURA1')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Cultura de R$ 75,00</Td>
                  <Td align='justify'>{market.estoque?.culturas?.cultura2}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALECULTURA2')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Cultura de R$ 100,00</Td>
                  <Td align='justify'>{market.estoque?.culturas?.cultura3}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALECULTURA3')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Xerox de R$ 50,00</Td>
                  <Td align='justify'>{market.estoque?.xeroxes?.xerox1}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALEXEROX1')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Xerox de R$ 75,00</Td>
                  <Td align='justify'>{market.estoque?.xeroxes?.xerox2}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALEXEROX2')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Vale Xerox de R$ 100,00</Td>
                  <Td align='justify'>{market.estoque?.xeroxes?.xerox3}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('VALEXEROX3')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Mentoria</Td>
                  <Td align='justify'>{market.estoque?.mentoria}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('MENTORIA')}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>Aprendizado</Td>
                  <Td align='justify'>{market.estoque?.aprendizado}</Td>
                  <Td>
                    <NumberInput
                      step={1}
                      min={0}
                      max={25}
                      w='35%'
                      ml='10px'
                      size='sm'
                      onChange={value => setInventory(parseInt(value))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={faSave}
                      size={'lg'}
                      color='gray'
                      title='Salvar'
                      cursor={'pointer'}
                      onClick={({ target }) => updateInventory('APRENDIZADO')}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </Flex>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => {
          openDialog(false)
          fetchMarket()
        }}
        message='Você confirma a abertura / fechamento manual do mercado?'
        handleConfirmation={handleConfirmation}
      />
    </Layout>
  )
}
