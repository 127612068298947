import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Subtheme1, Subtheme2 } from 'types'
import { Subtheme2Modal } from './modal'

export const Subtheme2Page = () => {
  const initalState = { nome: '', subtema1: { id: 0 } }
  const [loading, isLoading] = useState(false)
  const [subthemes1, setsubthemes1] = useState([] as Subtheme1[])
  const [subthemes2, setSubthemes2] = useState([] as Subtheme2[])
  const [newSubtheme2, setNewSubtheme2] = useState(initalState)
  const [pickedSubTheme2, setPickedSubTheme2] = useState({} as Subtheme2)
  const [isDialogOpen, openDialog] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const toast = useSnackbar()

  const fetchSubthemes1 = () => {
    api.subtheme1.list().then(setsubthemes1)
  }

  const fetchSubthemes2 = async () => {
    isLoading(true)
    const _subthemes2 = await api.subtheme2.list()
    setSubthemes2(_subthemes2)
    isLoading(false)

  }

  const createTheme = async () => {
    isLoading(true)
    try {
      await api.subtheme2.post(newSubtheme2)
      toast({
        title: `Subtema2 ${newSubtheme2.nome} cadastrado com sucesso`,
        status: 'success'
      })
      fetchSubthemes1()
      setNewSubtheme2(initalState)
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar o subtema2.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  const deleteTheme = async (id: number) => {
    isLoading(true)
    try {
      await api.subtheme2.delete(id)
      toast({
        title: `Subtema2 ${newSubtheme2.nome} removido com sucesso`,
        status: 'success'
      })
      fetchSubthemes2()
      setNewSubtheme2(initalState)
    } catch (error) {
      toast({
        title: 'Não foi possível remover o subtema2.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    fetchSubthemes1()
    fetchSubthemes2()
  }, [])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='100%' mt={4}>
        <Flex justify='space-between' mb='40px' w='60%' gap={4} style={{ marginLeft: '20%' }}>
          <FormControl>
            <FormLabel>Subtemas1</FormLabel>
            <Select
              name='subtemas1'
              placeholder='Selecione...'
              value={newSubtheme2.subtema1.id}
              onChange={({ target }) =>
                setNewSubtheme2({
                  ...newSubtheme2,
                  subtema1: { id: parseInt(target.value) }
                })
              }
            >
              {subthemes1.map(subtheme1 => (
                <option key={subtheme1.id} value={subtheme1.id}>
                  {subtheme1.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl w='80%'>
            <FormLabel>Nome</FormLabel>
            <Input
              value={newSubtheme2.nome}
              onChange={({ target }) =>
                setNewSubtheme2({ ...newSubtheme2, nome: target.value })
              }
            />
          </FormControl>

          <Flex direction='column' justify='flex-end'>
            <Button
              colorScheme='yellow'
              size='md'
              onClick={createTheme}
              disabled={newSubtheme2.nome === ''}
            >
              Criar
            </Button>
          </Flex>
        </Flex>

        {loading ? (
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='md'
            style={{ marginLeft: '50%' }}
          />
        ) : (
          <Flex justify='center' w='100%' mb='40px'>
            <TableContainer>
              <Table variant='simple' size='lg'>
                <TableCaption placement='top'>
                  <b>{subthemes2.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Tema</Th>
                    <Th>Subtema1</Th>
                    <Th>Nome</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subthemes2.map(subtheme2 => (
                    <Tr key={subtheme2.id}>
                      <Td>{subtheme2.subtema1.tema.nome}</Td>
                      <Td>{subtheme2.subtema1.nome}</Td>
                      <Td>{subtheme2.nome}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color='gray'
                          icon={faPenToSquare}
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          title='Editar'
                          onClick={() => {
                            setPickedSubTheme2(subtheme2)
                            openModal(true)
                          }}
                        />
                        <FontAwesomeIcon
                          color='red'
                          icon={faTrashCan}
                          title='Remover'
                          onClick={() => {
                            setPickedSubTheme2(subtheme2)
                            openDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>

      {pickedSubTheme2.id && (
        <RemovalConfirmationDialog
          targetName='Subtema2'
          confirmationMessage={`Deseja realmente remover o subtema2 <strong>${pickedSubTheme2.nome}</strong>?`}
          targetId={pickedSubTheme2.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
          }}
          onRemoveTarget={deleteTheme}
        />
      )}

      <Subtheme2Modal
        open={isModalOpen}
        handleClose={() => {
          fetchSubthemes2()
          openModal(false)
        }}
        subtheme2ToEdit={pickedSubTheme2}
      />
    </Layout>
  )
}