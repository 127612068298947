import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading
} from '@chakra-ui/react'
import { ActionButtons } from './ActionButtons'
import { TrailsTable } from './TrailsTable'
import { GroupedTrail } from 'types/trail'

type TrailCardPrps = {
  groupedTrail: GroupedTrail
  refreshTrails: () => void
}

export const TrailCard = ({ groupedTrail, refreshTrails }: TrailCardPrps) => {
  return (
    <Flex>
      <Card w='100%'>
        <CardHeader>
          <Heading size='sm'>
            {groupedTrail.empresa.nome}{' '}
            <Badge colorScheme='gray' variant='solid'>
              {groupedTrail.trilhas.length}
            </Badge>
          </Heading>
        </CardHeader>

        <ActionButtons trail={groupedTrail} />

        <CardBody>
          <TrailsTable
            trails={groupedTrail.trilhas}
            refreshTrails={refreshTrails}
          />
        </CardBody>
      </Card>
    </Flex>
  )
}
