import React, { useState } from 'react'
import { api } from 'shared/api'
import { Item } from 'types'
import { SearchParams } from './types'

export type ItemProviderProps = {
  children: React.ReactElement
}

type ItemContextType = {
  fetchItems: () => void
  setItems: (items: Item[]) => void
  items: Item[]
  isLoading: (toggle: boolean) => void
  loading: boolean
  setParams: (params: SearchParams) => void
  params: SearchParams
}

const initialContext: ItemContextType = {
  fetchItems: () => {},
  setItems: (items: Item[]) => {},
  items: [],
  isLoading: (toggle: boolean) => {},
  loading: false,
  setParams: (params: SearchParams) => {},
  params: {}
}

export const ItemContext = React.createContext(initialContext)

const ItemProvider = ({ children }: ItemProviderProps) => {
  const [params, setParams] = useState({} as SearchParams)
  const [items, setItems] = useState([] as Item[])
  const [loading, isLoading] = useState(false)

  const fetchItems = async () => {
    isLoading(true)
    if (params.id) {
      const item = await api.items.get(params.id)
      setItems([item])
    } else {
      const items = await api.items.list(params)
      setItems(items)
    }
    isLoading(false)
  }

  return (
    <ItemContext.Provider
      value={{
        fetchItems,
        setItems,
        items,
        isLoading,
        loading,
        setParams,
        params,
      }}
    >
      {children}
    </ItemContext.Provider>
  )
}

export default ItemProvider
