import { useSnackbar } from "hooks";
import React, { useEffect, useState } from "react";
import { api } from "shared/api";
import { Area, CompanyFormValue } from "types/company";
import { initialState } from "./types";

type CompaniesProviderProps = {
  children: React.ReactElement;
};

type CompaniesContextType = {
  uploadImage: (id: number, event: any) => void;
  fetchCompanies: (searchParams: CompanyFormValue) => void;
  companies: CompanyFormValue[];
  setCompanies: (companys: CompanyFormValue[]) => void;
  loading: boolean;
  isLoading: (toggle: boolean) => void;
  areas: Area[];
  searchParams: CompanyFormValue;
};

const initialContext: CompaniesContextType = {
  uploadImage: (id: number, event: any) => {},
  fetchCompanies: (searchParams: CompanyFormValue) => {},
  companies: [],
  setCompanies: (companys: CompanyFormValue[]) => {},
  loading: false,
  isLoading: (toggle: boolean) => {},
  areas: [],
  searchParams: {} as CompanyFormValue,
};

export const CompaniesContext = React.createContext(initialContext);

const CompaniesProvider = ({ children }: CompaniesProviderProps) => {
  const toast = useSnackbar();
  const [loading, isLoading] = useState(false);
  const [companies, setCompanies] = useState([] as CompanyFormValue[]);
  const [searchParams, setSearchParamsForFutureUse] = useState(initialState);
  const [areas, setAreas] = useState([] as Area[]);

  const fetchAreas = () => api.field.list();

  const uploadImage = async (companyId: number, event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", file, file.name);

    try {
      await api.company.upload(companyId, formData);
      toast({
        title: `Foto ${file.name} cadastrada com sucesso`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível cadastrar a foto.",
        status: "error",
      });
    }
  };

  const fetchCompanies = async (searchParams: CompanyFormValue) => {
    isLoading(true);
    try {
      const result = await api.company.list(searchParams);
      setCompanies(result);

      if (result.length === 0) {
        toast({
          title: "Nenhum resultado encontrado.",
          status: "warning",
        });
      }
      setSearchParamsForFutureUse(searchParams);
    } catch (error) {
      toast({
        title: "Não foi possível concluir a pesquisa.",
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    fetchAreas().then(setAreas);
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        uploadImage,
        fetchCompanies,
        companies,
        setCompanies,
        loading,
        isLoading,
        areas: areas,
        searchParams,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesProvider;
