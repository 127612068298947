import { Avatar } from "@chakra-ui/react";
import { getUserPhoto, getUserSituation } from "helper/user";
import { Box } from "./styles";
import { Props } from "./types";

export const Info = ({ user }: Props) => {
  const source = getUserPhoto(user);
  const situation = getUserSituation(user);

  return (
    <Box>
      <Avatar size="2xl" name={user?.nome} src={source} marginBottom="5px" />
      <p>
        <b>ID:</b> {user?.id}.
      </p>
      <p>
        <b>Nome:</b> {user?.nome}.
      </p>
      <p>
        <b>E-mail:</b> {user?.email}.
      </p>
      <p>
        <b>Prenchimento do perfil:</b> {`${situation.progress}%`}.
      </p>
      <p>
        <b>Possui curriculo:</b> {situation.hasCurriculum ? "Sim" : "Não"}.
      </p>
      <p>
        <b>Perfil adequado:</b> {situation.isComplete ? "Sim" : "Não"}.
      </p>
    </Box>
  );
};
