import {
  TableContainer,
  TableCaption,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Box,
} from "@chakra-ui/react";
import { Container, Counter } from "./style";
import { Props, TableEvent } from "./types";
import { Icon } from "components/Icon";

export const Table = ({ caption, header, elements, footer, onEvent }: Props) => {

  const handleEvent = (event: TableEvent | undefined, el: any, index: number) => {
    if(!onEvent) return;
    if(!event) return;
    if(event === 'delete') {
      const res = confirm(`Você confirma a exclusão do elemento de ID: ${el.id}?`)
      if(res) {
        onEvent(event, el, index);
      }
    } else {
      onEvent(event, el, index);
    }
  }

  return (
    <>
      {!!elements.length && (
        <Container>
          <TableContainer>
            <ChakraTable size="sm" variant="striped">
              {!!caption && <TableCaption>{caption}</TableCaption>}
              <Thead>
                <Tr>
                  {header.map((h) => (
                    <Th key={h.label} style={{ textAlign: 'center'}}>
                      {h.label}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {elements.map((el, index) => (
                  <Tr key={el.id}>
                    {
                      header.map((h, index2) => (
                        <Td key={index2} style={{ textAlign: 'center'}}>
                          <>
                            { h.event && h.icon && (
                              <Icon 
                                type={h.icon} 
                                onClick={() => handleEvent(h.event, el, index)} 
                              />
                            )}
                            { h.field && (!h.icon || !h.event) && (el[h.field]) }
                          </>
                        </Td>
                      ))
                    }
                  </Tr>
                ))}
              </Tbody>
              {!!footer?.length && (
                <Tfoot>
                  <Tr>
                    {footer?.map((f) => (
                      <Th key={f}>{f}</Th>
                    ))}
                  </Tr>
                </Tfoot>
              )}
            </ChakraTable>
          </TableContainer>
          <Box textAlign="right" mt={2}>
            <Counter>
              <b>Linhas: </b> {elements.length}
            </Counter>
          </Box>
        </Container>
      )}
    </>
  );
};
