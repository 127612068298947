import * as xlsx from 'xlsx'
import { config } from "config";
import { DownloadFileExtension } from "types";

export const downloader = (
  format: DownloadFileExtension,
  filename: string,
  url: string
) => {
  const a = document.createElement("a");
  a.href = `${config.tmpPath}/${url}`;
  a.download = `${filename}.${format}`;
  a.target = "_blank";
  a.click();
  a.remove();
};

export const convertSheetToJSON = async <Type>(file: File) => {
  const ar = await file.arrayBuffer()
  const workbook = xlsx.read(ar);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  return xlsx.utils.sheet_to_json<Type>(worksheet, { raw: false, defval: "", blankrows: false });
}