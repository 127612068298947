import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Subtheme2, Subtheme3 } from 'types'
import { Subtheme3Modal } from './modal'

export const Subtheme3Page = () => {
  const initalState = { nome: '', subtema2: { id: 0 } }
  const [loading, isLoading] = useState(false)
  const [subthemes2, setsubthemes2] = useState([] as Subtheme2[])
  const [subthemes3, setSubthemes3] = useState([] as Subtheme3[])
  const [newSubtheme3, setNewSubtheme3] = useState(initalState)
  const [pickedSubTheme3, setPickedSubTheme3] = useState({} as Subtheme3)
  const [isDialogOpen, openDialog] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const toast = useSnackbar()

  const fetchSubthemes2 = () => {
    api.subtheme2.list().then(setsubthemes2)
  }

  const fetchSubthemes3 = async () => {
    isLoading(true)
    const result = await api.subtheme3.list()
    setSubthemes3(result)
    isLoading(false)
  }

  const createTheme = async () => {
    isLoading(true)
    try {
      await api.subtheme3.post(newSubtheme3)
      toast({
        title: `Subtema3 ${newSubtheme3.nome} cadastrado com sucesso`,
        status: 'success'
      })
      setNewSubtheme3(initalState)
      fetchSubthemes3()
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar o subtema3.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  const deleteTheme = async (id: number) => {
    isLoading(true)
    try {
      await api.subtheme3.delete(id)
      toast({
        title: `Subtema3 ${newSubtheme3.nome} removido com sucesso`,
        status: 'success'
      })
      fetchSubthemes3()
      setNewSubtheme3(initalState)
    } catch (error) {
      toast({
        title: 'Não foi possível remover o subtema3.',
        status: 'error'
      })
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    fetchSubthemes2()
    fetchSubthemes3()
  }, [])

  return (
    <Layout header menu footer>
      <Flex direction='column' w='100%' mt={4}>
        <Flex justify='center' mb='40px' w='60%' gap={4} style={{ marginLeft: '20%' }}>
          <FormControl>
            <FormLabel>Subtemas2</FormLabel>
            <Select
              name='subtemas2'
              placeholder='Selecione...'
              value={newSubtheme3.subtema2.id}
              onChange={({ target }) =>
                setNewSubtheme3({
                  ...newSubtheme3,
                  subtema2: { id: parseInt(target.value) }
                })
              }
            >
              {subthemes2.map(subtheme2 => (
                <option key={subtheme2.id} value={subtheme2.id}>
                  {subtheme2.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl w='80%'>
            <FormLabel>Nome</FormLabel>
            <Input
              value={newSubtheme3.nome}
              onChange={({ target }) =>
                setNewSubtheme3({ ...newSubtheme3, nome: target.value })
              }
            />
          </FormControl>

          <Flex direction='column' justify='flex-end'>
            <Button
              colorScheme='yellow'
              size='md'
              onClick={createTheme}
              disabled={newSubtheme3.nome === ''}
            >
              Criar
            </Button>
          </Flex>
        </Flex>

        {loading ? (
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='md'
            style={{ marginLeft: '50%' }}
          />
        ) : (
          <Flex justify='center' mb='40px'>
            <TableContainer>
              <Table variant='simple' size='md'>
                <TableCaption placement='top'>
                  <b>{subthemes2.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Tema</Th>
                    <Th>Subtema1</Th>
                    <Th>Subtema2</Th>
                    <Th>Nome</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subthemes3.map(subtheme3 => (
                    <Tr key={subtheme3.id}>
                      <Td>{subtheme3.subtema2.subtema1.tema.nome}</Td>
                      <Td>{subtheme3.subtema2.subtema1.nome}</Td>
                      <Td>{subtheme3.subtema2.nome}</Td>
                      <Td>{subtheme3.nome}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color='gray'
                          icon={faPenToSquare}
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          title='Editar'
                          onClick={() => {
                            setPickedSubTheme3(subtheme3)
                            openModal(true)
                          }}
                        />
                        <FontAwesomeIcon
                          color='red'
                          icon={faTrashCan}
                          title='Remover'
                          onClick={() => {
                            setPickedSubTheme3(subtheme3)
                            openDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>

      {pickedSubTheme3.id && (
        <RemovalConfirmationDialog
          targetName='Subtema3'
          confirmationMessage={`Deseja realmente remover o subtema3 <strong>${pickedSubTheme3.nome}</strong>?`}
          targetId={pickedSubTheme3.id}
          isOpen={isDialogOpen}
          onClose={() => {
            openDialog(false)
          }}
          onRemoveTarget={deleteTheme}
        />
      )}

      <Subtheme3Modal
        open={isModalOpen}
        handleClose={() => {
          fetchSubthemes3()
          openModal(false)
        }}
        subtheme3ToEdit={pickedSubTheme3}
      />
    </Layout>
  )
}