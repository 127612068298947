export const defaultOptions = {
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: true,
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "",
    },
    datalabels: {
      color: "white",
      formatter: undefined,
    },
  },
};
