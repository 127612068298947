import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { useSnackbar } from "hooks";
import { useEffect, useState } from "react";
import { api } from "shared/api";
import { Area } from "types";

type ModalProps = {
  handleClose: () => void;
  open: boolean;
  workFieldToEdit?: Area;
};

export const WorkFieldModal = ({
  open,
  handleClose,
  workFieldToEdit,
}: ModalProps) => {
  const initialState = { id: 0, nome: "" };
  const [workField, setWorkField] = useState(initialState);
  const action = workFieldToEdit?.nome ? "Editar" : "Criar";
  const toast = useSnackbar();

  const handleSave = () => {
    if (workField.id) {
      onEdit();
    } else {
      onSave();
    }
  };

  const onSave = async () => {
    try {
      await api.field.post(workField.nome);
      handleClose();
      toast({
        title: "Área criada com sucesso",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível criar a área",
        status: "error",
      });
    }
  };

  const onEdit = async () => {
    try {
      await api.field.put(workField);
      handleClose();
      toast({
        title: "Área atualizada com sucesso",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível atualizar a área",
        status: "error",
      });
    }
  };

  const resetAndClose = () => {
    setWorkField(initialState);
    handleClose();
  };

  useEffect(() => {
    setWorkField({ ...workFieldToEdit!! });
  }, [workFieldToEdit]);

  return (
    <Modal isOpen={open} onClose={resetAndClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{action} Área</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              value={workField.nome}
              onChange={({ target }) =>
                setWorkField({ ...workField, nome: target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="yellow"
            variant="outline"
            mr={3}
            onClick={resetAndClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="yellow"
            onClick={handleSave}
            disabled={workField.nome === ""}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
