import { Button, Divider } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Formik, Form as FForm, FormikProps } from "formik";
import { ActionsBox } from "./styles";

interface FormProps {
  initial: any;
  okLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
  loadingText?: string;
  buttonWidth?: string;
  style?: object;
  onSubmit: (values: any) => void;
  onReset?: () => void;
  children: ReactNode;
}

export const Form = ({
  initial,
  okLabel = "Salvar",
  cancelLabel = "Cancelar",
  isLoading,
  loadingText,
  buttonWidth,
  style,
  onSubmit,
  onReset,
  children,
}: FormProps) => {
  const handleSubmit = (values: any) => {
    onSubmit(values);
  };

  const handleReset = () => {
    onReset && onReset();
  };

  return (
    <Formik 
      initialValues={initial} 
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<any>) => (
        <FForm
          onReset={handleReset}
          style={{ minWidth: "350px", ...style }}
        >
          {children}
          <ActionsBox>
            <Divider sx={{ mb: "4" }} />
            <Button
              colorScheme="yellow"
              variant="outline"
              size="sm"
              width={buttonWidth}
              type="reset"
            >
              {cancelLabel}
            </Button>
            <Button
              colorScheme="yellow"
              style={{ color: "white" }}
              size="sm"
              width={buttonWidth}
              isLoading={isLoading}
              loadingText={loadingText}
              marginLeft="10px"
              type="submit"
            >
              {okLabel}
            </Button>
          </ActionsBox>
        </FForm>
      )}
    </Formik>
  );
};
