import { useContext, useState } from "react";
import { Box, Flex, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RemovalConfirmationDialog } from "components/Dialog/RemovalConfirmation";
import { config } from "config";
import { useSnackbar } from "hooks";
import { api } from "shared/api";
import { Country, InstitutionFormValue, State } from "types";
import { InstitutionsContext } from "../InstitutionsProvider";
import { initialState } from "../types";
import { EditModal } from "./EditModal";

type SearchResultProps = {
  institutions: InstitutionFormValue[];
};

export const SearchResult = ({ institutions }: SearchResultProps) => {
  const toast = useSnackbar();
  const [pickedInstitution, setPickedInstitution] = useState(initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fetchInstitutions, searchParams } = useContext(InstitutionsContext);

  const onRemove = (institution: InstitutionFormValue) => {
    setPickedInstitution(institution);
    setIsDialogOpen(true);
  };

  const onEdit = (institution: InstitutionFormValue) => {
    setPickedInstitution(institution);
    setIsModalOpen(true);
  };

  const removeInstitution = async ({ nome, id }: InstitutionFormValue) => {
    try {
      await api.institution.delete(Number(id));
      toast({
        title: `Instituição ${nome} removida com sucesso`,
        status: "success",
      });
      fetchInstitutions(searchParams);
    } catch (error) {
      toast({
        title: `Não foi possível remover a instituição ${nome}`,
        status: "error",
      });
    }
  };

  return (
    <>
      {institutions.map((institution) => (
        <Flex
          key={institution.id}
          bgColor="rgba(250, 250, 250)"
          _hover={{ boxShadow: "0 0 5px 2px #b7d7e8" }}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        >
          <Box
            style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}
          >
            <Image
              boxSize="100px"
              objectFit="scale-down"
              src={`${config.api.baseUrl}/static/images/instituicoes/${institution.imagem}`}
              alt={institution.sigla}
            />
          </Box>
          <Box w="100%">
            <UnorderedList listStyleType="none" fontSize="11px">
              <ListItem>
                <strong>ID: </strong>
                {institution.id}
              </ListItem>
              <ListItem>
                <strong>Nome: </strong>
                {institution.nome}
              </ListItem>
              <ListItem>
                <strong>Sigla: </strong>
                {institution.sigla}
              </ListItem>
              <ListItem>
                <strong>Estado: </strong>
                {(institution.estado as State)?.nome}
              </ListItem>
              <ListItem>
                <strong>País: </strong>
                {(institution.pais as Country)?.nome}
              </ListItem>
              <ListItem>
                <strong>Tipo: </strong>
                {institution.tipo}
              </ListItem>
              <ListItem>
                <strong>Nível: </strong>
                {institution.nivel}
              </ListItem>
              <ListItem>
                <strong>Peso: </strong>
                {institution.peso}
              </ListItem>
            </UnorderedList>
            <Flex justify="flex-end" mb="8px" mr="5px">
              <FontAwesomeIcon
                color="gray"
                icon={faPenToSquare}
                style={{ marginRight: "10px", cursor: "pointer" }}
                title="Editar"
                onClick={() => onEdit(institution)}
              />
              <FontAwesomeIcon
                color="red"
                icon={faTrashCan}
                title="Remover"
                onClick={() => onRemove(institution)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </Box>
        </Flex>
      ))}
      <RemovalConfirmationDialog
        targetName="Instituição"
        confirmationMessage={`Deseja realmente remover a instituição <strong>${pickedInstitution.nome}</strong>?`}
        targetId={pickedInstitution.id!!}
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setPickedInstitution(initialState);
        }}
        onRemoveTarget={() => removeInstitution(pickedInstitution)}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setPickedInstitution(initialState);
        }}
        institution={pickedInstitution}
      />
    </>
  );
};
