import React, { useEffect, useState } from "react";
import { api } from "../../../../../shared/api";
import { useSnackbar } from "hooks";
import { Flex } from "@chakra-ui/react";
import { initialState } from "components/Chart/LineChart/types";
import { UniversityProps } from "./types";
import { BarChart } from "components/Chart/BarChart";

export const UniversityChart = ({ id, name }: UniversityProps) => {
  const toast = useSnackbar();
  const [datasets, setDatasets] = useState([initialState]);
  const [labels, setLabels] = useState([]);
  const blue = "rgba(0, 128, 255)";

  useEffect(() => {
    async function fetchUniversities() {
      const { universidades: universities } =
        await api.stats.totalUniversitiesByState(id);

      return universities;
    }

    fetchUniversities()
      .then((totals) => {
        setLabels(
          totals.map((university: any) => university.instituicao.sigla)
        );
        setDatasets([
          {
            label: "Quantidade",
            borderColor: "lightblue",
            backgroundColor: blue,
            data: totals.map((university: any) => university.total),
          },
        ]);
      })
      .catch((error) => {
        toast({
          title: `Não foi possível atualizar estatísticas para o estado ${name}`,
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="25px">
      <BarChart
        title={`Usuários nas universidades públicas "alvo" - ${name}`}
        labels={labels}
        datasets={datasets}
      />
    </Flex>
  );
};
