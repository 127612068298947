import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "types";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: 0,
    nome: "",
    email: "",
    token: "",
  },
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      const { id, nome, email } = action.payload;
      state.id = id;
      state.nome = nome;
      state.email = email;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { setUser, setToken } = userSlice.actions;

export default userSlice.reducer;
