import React, { useContext } from "react";
import {
  Box,
  Flex,
  ButtonGroup,
  Button,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { SearchResult } from "./SearchResult";
import { SearchContext } from "./Provider";

export const CourseSearch = () => {
  const { fetchCourses, courses, isLoading, editCourse } =
    useContext(SearchContext);

  return (
    <Flex direction="column" justify="center">
      <Text fontSize="xl" m="auto" my="20px">
        Listagem
      </Text>
      <Box m="auto">
        <ButtonGroup gap="4">
          <Button
            colorScheme="yellow"
            onClick={() => fetchCourses("MEDIO")}
            style={{ width: "150px" }}
          >
            Médio / Técnico
          </Button>
          <Button
            colorScheme="yellow"
            onClick={() => fetchCourses("SUPERIOR")}
            style={{ width: "150px" }}
          >
            Superior
          </Button>
        </ButtonGroup>
      </Box>
      {isLoading ? (
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="md"
          style={{ margin: "auto" }}
        />
      ) : (
        <SearchResult courses={courses} editCourse={editCourse} />
      )}
    </Flex>
  );
};
