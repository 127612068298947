import React, { useContext, useState } from "react";
import {
  Flex,
  FormControl,
  Input,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormLabel,
} from "@chakra-ui/react";
import { EducationLevel } from "types";
import { EditModalProps } from "./types";
import { SearchContext } from "../../Provider";

export const EditModal = ({ isOpen, onClose }: EditModalProps) => {
  const { course, editCourse } = useContext(SearchContext);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [level, setLevel] = useState(0);

  const closeAndReset = () => {
    setName("");
    setType("");
    setLevel(0);
    onClose();
  };

  return (
    <Modal
      closeOnEsc={true}
      isOpen={isOpen}
      onClose={closeAndReset}
      motionPreset="none"
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Curso</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="row" justify="space-between">
            <FormControl isRequired>
              <FormLabel>Nome</FormLabel>
              <Input
                type="text"
                value={name || course.nome}
                onChange={(input) => setName(input.target.value)}
              />
            </FormControl>
            <FormControl isRequired ml="15px">
              <FormLabel>Tipo</FormLabel>
              <Select
                value={type || course.grau}
                onChange={(input) => setType(input.target.value)}
              >
                <option value="MEDIO">Médio</option>
                <option value="SUPERIOR">Superior</option>
              </Select>
            </FormControl>
            <FormControl isRequired ml="15px">
              <FormLabel>Peso</FormLabel>
              <Select
                defaultValue={course.peso}
                onChange={(input) => setLevel(Number(input.target.value))}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Select>
            </FormControl>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="yellow"
            onClick={() => {
              editCourse({
                id: course.id,
                nome: name || course.nome,
                grau: (type as EducationLevel) || course.grau,
                peso: level || course.peso,
              });
              onClose();
              setName("");
              setType("");
              setLevel(0);
            }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
