import React, { useEffect, useState } from "react";
import { SimpleGrid, FormControl } from "@chakra-ui/react";
import { Label } from "./styles";
import { User, PersonalForm } from "types";
import { formatDate } from "shared/utils/date";
import { parsePersonalForm } from "helper/user";
import { useSaveUser } from "hooks/saveUser";
import {
  accountClassification,
  accountStatus,
  assertions,
  ethnicities,
  genders,
} from "shared/utils/arrays";
import { Select } from "components/Form/Select";
import { Form } from "components/Form";
import { Input } from "components/Form/Input";

export const initialValues: PersonalForm = {
  nome: "",
  email: "",
  nascimento: "",
  genero: "",
  etnia: "",
  pcd: "",
  rg: "",
  cpf: "",
  codigoEmpresarial: "",
  codigoCoaching: "",
  pontuacao: 0,
  empodera: 0,
  status: "",
  numeroEstrelas: 0,
  criadoEm: "",
  ultimoLogin: "",
};

type Props = {
  user?: User;
  onClose: () => void;
};

export const Personal = ({ user, onClose }: Props) => {
  const [initial, setInitial] = useState<PersonalForm>(initialValues)
  const [loading, setLoading] = useState(false);
  const doRequest = useSaveUser();

  useEffect(() => {
    if (!user) return;
    setInitial({
      ...user,
      nascimento: formatDate(user.nascimento),
      criadoEm: formatDate(user.criadoEm),
      ultimoLogin: formatDate(user.ultimoLogin),
    });
  }, [user]);

  const handleSubmit = (values: PersonalForm) => {
    setLoading(true);
    const parsed = parsePersonalForm(values);
    doRequest(Number(user?.id), parsed, setLoading, onClose);
  }

  return (
    <Form
      initial={initial}
      onSubmit={handleSubmit}
      okLabel="Salvar"
      isLoading={loading}
    >
      <SimpleGrid columns={2} spacing="10px">
        <FormControl>
          <Label>Nome</Label>
          <Input name="nome" />
        </FormControl>
        <FormControl>
          <Label>E-mail</Label>
          <Input name="email" />
        </FormControl>
        <FormControl>
          <Label>Nascimento</Label>
          <Input name="nascimento" />
        </FormControl>
        <FormControl>
          <Label>Gênero</Label>
          <Select
            name="genero"
            options={genders}
          />
        </FormControl>
        <FormControl>
          <Label>Etnia</Label>
          <Select
            name="etnia"
            options={ethnicities}
          />
        </FormControl>
        <FormControl>
          <Label>PCD</Label>
          <Select
            name="pcd"
            options={assertions}
          />
        </FormControl>
        <FormControl>
          <Label>RG</Label>
          <Input name="rg" />
        </FormControl>
        <FormControl>
          <Label>CPF</Label>
          <Input name="cpf" />
        </FormControl>
        <FormControl>
          <Label>Código enterprise (capacitação) </Label>
          <Input name="codigoEmpresarial" />
        </FormControl>
        <FormControl>
          <Label>Código coaching (coaching) </Label>
          <Input name="codigoCoaching" />
        </FormControl>
        <FormControl>
          <Label>Pontuação</Label>
          <Input disabled name="pontuacao" />
        </FormControl>
        <FormControl>
          <Label>Empodera</Label>
          <Input disabled name="empodera" />
        </FormControl>
        <FormControl>
          <Label>Status da Conta</Label>
          <Select
            name="status"
            options={accountStatus}
          />
        </FormControl>
        <FormControl>
          <Label>Classificação</Label>
          <Select
            name="numeroEstrelas"
            options={accountClassification}
          />
        </FormControl>
        <FormControl>
          <Label>Criado em</Label>
          <Input disabled name="criadoEm"
          />
        </FormControl>
        <FormControl>
          <Label>Último login</Label>
          <Input disabled name="ultimoLogin" />
        </FormControl>
      </SimpleGrid>
    </Form>
  );
};
