import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";
import { Box } from "@chakra-ui/react";
import { PieChartProps } from "./types";
import { defaultOptions } from "../config";

export const PieChart = ({
  title,
  labels,
  datasets,
  formatter,
}: PieChartProps) => {
  ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

  const data = {
    labels,
    datasets: datasets.map((dataset) => ({
      borderColor: "white",
      borderWidth: 1,
      ...dataset,
    })),
  };

  const customOptions = structuredClone(defaultOptions);
  customOptions.plugins.title.text = title;
  customOptions.plugins.datalabels.formatter = formatter;

  return (
    <Box w={400} h={400} p={4}>
      <Pie data={data} options={customOptions} />
    </Box>
  );
};
