export const getBlank = {
  id: "",
  email: "",
  nome: "",
  startAge: "",
  endAge: "",
  estado: [],
  imigrante: "",
  refugiado: "",
  genero: [],
  etnia: [],
  orientacaoSexual: [],
  travesti: "",
  transexual: "",
  pcd: false,
  instituicao: [],
  curso: [],
  status: [],
  conclusao: [],
  periodo: [],
  logica: [],
  ingles: [],
  inscricoes: [],
  tipoDeficiencia: "",
  statusDaConta: ['ATIVA'],
  page: 1,
};

export const parseParams = (form: any) => {
  const { pcd, inscricoes } = form;

  let params = { ...form };

  if (pcd === true) {
    params = { ...params, pcd: "SIM" };
  }

  if (pcd === false) {
    delete params.pcd
  }

  if (inscricoes) {
    params = {
      ...params,
      inscricoes: inscricoes.map((i: string) => Number(i)),
    };
  }

  return params;
};
