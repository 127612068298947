import React, { useEffect, useState } from "react";
import { api } from "../../../../shared/api";
import { useSnackbar } from "hooks";
import { Flex } from "@chakra-ui/react";
import { LineChart } from "components/Chart/LineChart";
import { initialState } from "components/Chart/LineChart/types";

export const DaysChart = () => {
  const toast = useSnackbar();
  const [datasets, setDatasets] = useState([initialState]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function fetchDaysTotals() {
      const daysTotals = await api.stats.dailyTotals();
      return daysTotals;
    }

    fetchDaysTotals()
      .then((totals) => {
        const _labels = totals.map((day: any) => day.data);
        const total = totals.map((day: any) => day.quantidade);

        setLabels(_labels);
        setDatasets([
          {
            label: "Quantidade",
            borderColor: "blue",
            backgroundColor: "lightblue",
            data: total,
          },
        ]);
      })
      .catch((error) => {
        toast({
          title: "Não foi possível atualizar estatísticas por dias",
          description: error.response.data.msg,
          status: "error",
        });
      });
  }, []);

  return (
    <Flex direction="row" wrap="nowrap" justify="center" mt="25px">
      <LineChart
        title="Novas contas nos últimos 30 dias"
        labels={labels}
        datasets={datasets}
      />
    </Flex>
  );
};
