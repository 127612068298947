import { Select } from "@chakra-ui/react";
import { SelectOptions } from "types";

type Props = {
  name: string;
  placeholder?: string;
  options: SelectOptions[];
  handleChange: (id: number) => void;
}

export const Combo = ({
  placeholder,
  options,
  name,
  handleChange
}: Props) => {
  return (
    <Select 
      placeholder={placeholder}
      name={name}
      onChange={(event) => {
        handleChange(Number(event.target.value));
      }}
    >
      {options.map((option) => (
        <option value={option.id} key={option.id}>{option.nome}</option>
      ))}
    </Select>
  )
}