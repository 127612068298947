import { Flex, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "components/Form";
import { Field } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { Country, State } from "types/place";
import { InstitutionLevel, InstitutionType } from "types/scholarity";
import { InstitutionsContext } from "./InstitutionsProvider";
import { FormProps, initialState } from "./types";

export const InstitutionForm = ({
  okLabel,
  cancelLabel,
  loading,
  institutionToEdit,
  search,
  create,
  update,
  onResetResult,
  uploadImage,
  handleClose,
}: FormProps) => {
  const [institution, setInstitution] = useState(initialState);
  const showImageUpload = useMemo(() => okLabel !== "Pesquisar", [okLabel]);
  const { states, countries } = useContext(InstitutionsContext);

  const handleReset = () => {
    switch (okLabel) {
      case "Pesquisar":
        onResetResult!!();
        break;
      case "Salvar":
        handleClose!!();
        break;
    }
    setInstitution(initialState);
  };

  const onSubmit = () => {
    switch (okLabel) {
      case "Pesquisar":
        search!!(institution);
        break;
      case "Cadastrar":
        create!!(institution);
        setInstitution(initialState);
        break;
      case "Salvar":
        update!!(institution);
        setInstitution(initialState);
        break;
    }
  };

  useEffect(() => {
    if (institutionToEdit) {
      setInstitution({
        ...institutionToEdit,
        estado: (institutionToEdit.estado as State).id,
        pais: (institutionToEdit.pais as Country).id,
      });
    }
  }, []);

  return (
    <>
      <Flex direction="column">
        <Form
          initial={institution}
          onSubmit={onSubmit}
          onReset={handleReset}
          isLoading={loading}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
        >
          <Field name="nome">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Nome</FormLabel>
                <Input
                  {...field}
                  name="nome"
                  type="text"
                  value={institution.nome}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({ ...institution, nome: target.value })
                  }
                />
              </FormControl>
            )}
          </Field>
          <Field name="sigla">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Sigla</FormLabel>
                <Input
                  {...field}
                  name="sigla"
                  type="text"
                  value={institution.sigla || ""}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({ ...institution, sigla: target.value })
                  }
                />
              </FormControl>
            )}
          </Field>
          <Field name="estado">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Estado</FormLabel>
                <Select
                  icon={<FontAwesomeIcon icon={faCaretDown} />}
                  name="estado"
                  placeholder="Selecione..."
                  value={institution.estado as number}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({
                      ...institution,
                      estado: parseInt(target.value),
                    })
                  }
                >
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name="pais">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>País</FormLabel>
                <Select
                  icon={<FontAwesomeIcon icon={faCaretDown} />}
                  name="pais"
                  placeholder="Selecione..."
                  value={institution.pais as number}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({
                      ...institution,
                      pais: parseInt(target.value),
                    })
                  }
                >
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name="tipo">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Tipo</FormLabel>
                <Select
                  icon={<FontAwesomeIcon icon={faCaretDown} />}
                  name="tipo"
                  placeholder="Selecione..."
                  value={institution.tipo}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({
                      ...institution,
                      tipo: target.value as InstitutionType,
                    })
                  }
                >
                  <option value="PUBLICA">Pública</option>
                  <option value="PRIVADA">Privada</option>
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name="nivel">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Nível</FormLabel>
                <Select
                  icon={<FontAwesomeIcon icon={faCaretDown} />}
                  name="nivel"
                  placeholder="Selecione..."
                  value={institution.nivel}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({
                      ...institution,
                      nivel: target.value as InstitutionLevel,
                    })
                  }
                >
                  <option value="MEDIO">Médio</option>
                  <option value="SUPERIOR">Superior</option>
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name="peso">
            {({ field }: any) => (
              <FormControl>
                <FormLabel>Peso</FormLabel>
                <Select
                  icon={<FontAwesomeIcon icon={faCaretDown} />}
                  name="peso"
                  placeholder="Selecione..."
                  value={institution.peso}
                  mb="15px"
                  onChange={({ target }) =>
                    setInstitution({
                      ...institution,
                      peso: parseInt(target.value),
                    })
                  }
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
              </FormControl>
            )}
          </Field>
          {showImageUpload && (
            <Field name="imagem">
              {({ field }: any) => (
                <FormControl>
                  <FormLabel>Imagem</FormLabel>
                  <Input
                    {...field}
                    name="imagem"
                    type="file"
                    mb="15px"
                    pt="8px"
                    onChange={uploadImage}
                  />
                </FormControl>
              )}
            </Field>
          )}
        </Form>
      </Flex>
    </>
  );
};
