import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  display: 9;
  width: 100%;
  height: 100%;
`;

export const MenuBox = styled.div`
  display: flex;
  flex: 1.1;
  min-height: 100vh;
  max-height: 100%;
`;

export const ChildrenBox = styled.div`
  display: flex;
  flex: 8;
  padding: 5px;
`;
