import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Subtheme2 } from 'types'

type ModalProps = {
  handleClose: () => void
  open: boolean
  subtheme2ToEdit: Subtheme2
}

export const Subtheme2Modal = ({
  open,
  handleClose,
  subtheme2ToEdit
}: ModalProps) => {
  const [subtheme2, setSubtheme2] = useState({id: 0, nome: ''} as Subtheme2)
  const toast = useSnackbar()

  const onEdit = async () => {
    try {
      await api.subtheme2.put(subtheme2.id, subtheme2)
      handleClose()
      toast({
        title: 'Subtema2 atualizado com sucesso',
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar o subtema2',
        status: 'error'
      })
    }
  }

  const resetAndClose = () => {
    setSubtheme2({} as Subtheme2)
    handleClose()
  }

  useEffect(() => {
    setSubtheme2({...subtheme2ToEdit})
  }, [subtheme2ToEdit])

  return (
    <Modal isOpen={open} onClose={resetAndClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Subtema2</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              value={subtheme2.nome}
              onChange={({ target }) =>
                setSubtheme2({ ...subtheme2, nome: target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='yellow'
            variant='outline'
            mr={3}
            onClick={resetAndClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme='yellow'
            onClick={onEdit}
            disabled={subtheme2.nome === ''}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}