import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faTrash as Delete, 
  faPenToSquare as Edit, 
  faGear as Settings, 
  faEllipsis as Options,
  faEye as View 
} from '@fortawesome/free-solid-svg-icons'
import { IconType } from 'types';


type Props = {
  type: IconType;
  onClick: () => void;
}

const getIcon = (type: IconType) => {
  if(type === 'delete') return Delete;
  if(type === 'edit') return Edit;
  if(type === 'settings') return Settings;
  if(type === 'options') return Options;
  if(type === 'view') return View;
  throw new Error('Invalid type');
}


export const Icon = ({ type, onClick }: Props) => {
  const icon = getIcon(type);
  const color = type === 'delete' ? 'red' : 'gray';
  
  return (
    <FontAwesomeIcon
      color={color}
      icon={icon}
      style={{ marginRight: '15px', cursor: 'pointer' }}
      onClick={onClick}
    />
  )
}