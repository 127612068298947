import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { api } from 'shared/api'

type NewItemProps = {
  comboId: number
  refreshItems: () => void
}

export const NewItem = ({ comboId, refreshItems }: NewItemProps) => {
  const toast = useSnackbar()
  const loggedUser = useLocalStorage()
  const [itemId, setItemId] = useState(0)

  const createItem = async () => {
    try {
      const payload = {
        item: { id: itemId },
        criadoPor: { id: loggedUser?.id },
        combo: { id: comboId }
      }
      await api.combo.createItem(payload)
      toast({
        title: `Item criado com sucesso`,
        status: 'success'
      })
      refreshItems()
    } catch (error) {
      toast({
        title: 'Não foi possível criar item.',
        status: 'error'
      })
    } finally {
      setItemId(0)
    }
  }
  return (
    <Card w='50%'>
      <CardHeader>
        <Heading size='sm'>Incluir Item</Heading>
      </CardHeader>
      <CardBody>
        <Flex gap={5} justify={'space-between'}>
          <FormControl w='80%'>
            <FormLabel>ID do item</FormLabel>
            <Input
              type='text'
              value={itemId || ''}
              onChange={({ target }) => setItemId(parseInt(target.value))}
            />
          </FormControl>
          <Button
            leftIcon={<FaPlus />}
            colorScheme='yellow'
            mt='33px'
            size='sm'
            onClick={createItem}
          >
            Incluir
          </Button>
        </Flex>
      </CardBody>
    </Card>
  )
}
