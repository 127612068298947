import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Subtheme1 } from 'types'

type ModalProps = {
  handleClose: () => void
  open: boolean
  subtheme1ToEdit: Subtheme1
}

export const Subtheme1Modal = ({
  open,
  handleClose,
  subtheme1ToEdit
}: ModalProps) => {
  const [subtheme1, setSubtheme1] = useState({id: 0, nome: ''} as Subtheme1)
  const toast = useSnackbar()

  const onEdit = async () => {
    try {
      await api.subtheme1.put(subtheme1.id, subtheme1)
      handleClose()
      toast({
        title: 'Subtema1 atualizado com sucesso',
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível atualizar o subtema1',
        status: 'error'
      })
    }
  }

  const resetAndClose = () => {
    setSubtheme1({} as Subtheme1)
    handleClose()
  }

  useEffect(() => {
    setSubtheme1({...subtheme1ToEdit})
  }, [subtheme1ToEdit])

  return (
    <Modal isOpen={open} onClose={resetAndClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Subtema1</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              value={subtheme1.nome}
              onChange={({ target }) =>
                setSubtheme1({ ...subtheme1, nome: target.value })
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='yellow'
            variant='outline'
            mr={3}
            onClick={resetAndClose}
          >
            Cancelar
          </Button>
          <Button
            colorScheme='yellow'
            onClick={onEdit}
            disabled={subtheme1.nome === ''}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}