import {
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormHelperText,
  FormControl,
  ModalFooter,
  Flex,
  Button,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "hooks";
import { useState } from "react";
import { api } from "shared/api";
import { Manager } from "types";

type EmpoderaCoinModalProps = {
  isOpen: boolean;
  onClose: () => void;
  manager: Manager;
};

export const EmpoderaCoinModal = ({
  isOpen,
  onClose,
  manager,
}: EmpoderaCoinModalProps) => {
  const toast = useSnackbar();
  const [coins, setCoins] = useState(0);

  const saveCoins = async () => {
    try {
      await api.manager.update(manager.id, { ...manager, empodera: coins });
      toast({
        title: "Quantidade atualizada com sucesso.",
        status: "success",
      });
      onClose();
    } catch (error) {
      toast({
        title: "Não foi possível atualizar a quantidade.",
        status: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cadastrar Empodera - Gestor {manager.id}</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Novo valor</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <FontAwesomeIcon
                    color="rgba(230, 211, 41)"
                    icon={faCoins}
                    style={{
                      marginLeft: "10px",
                      marginTop: "1px",
                      cursor: "pointer",
                    }}
                    title="Salvar moedas"
                  />
                }
              />
              <Input
                type="number"
                w="35%"
                value={coins || ""}
                onChange={({ target }) => setCoins(parseInt(target.value))}
              />
            </InputGroup>
            <FormHelperText>
              Quantidade atual: <strong>{manager.empodera}</strong>
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex justify={"flex-end"}>
            <Button
              colorScheme="yellow"
              variant="outline"
              ml="2"
              size={"sm"}
              onClick={() => {
                setCoins(0);
                onClose();
              }}
            >
              Fechar
            </Button>
            <Button
              colorScheme="yellow"
              ml="2"
              size={"sm"}
              onClick={({ target }: any) => saveCoins()}
            >
              Salvar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
