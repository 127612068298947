import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { api } from "shared/api";
import { Personal } from "./components/Personal";
import { AddressAndContact } from "./components/AddressAndContact";
import { Academic } from "./components/Academic";
import { Professional } from "./components/Professional";
import { Selection } from "./components/Selection";
import { Document } from "./components/Document";
import { User } from "types";
import { Info } from "./components/Info";

type Props = {
  id?: number;
  open: boolean;
  onClose: () => void;
};

const tabs = [
  "Info",
  "Pessoal",
  "Endereço / Contato",
  "Acadêmico",
  "Profissional",
  "Seleção",
  "Documentos",
];

export const Profile: FC<Props> = ({ id, open, onClose }) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    id && api.user.get(id).then(setUser);
  }, []);

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="1200px">
        <ModalHeader> Editar perfil de usuário</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              {tabs.map((el, index) => (
                <Tab fontSize="13px" key={index}>
                  {" "}
                  {el}{" "}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Info user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <Personal user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <AddressAndContact user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <Academic user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <Professional user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <Selection user={user} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <Document user={user} onClose={onClose} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
