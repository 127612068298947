import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Title, Wrapper } from "./style";

export const Header = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("@empodera/backoffice");
    navigate("/");
  };

  return (
    <Wrapper>
      <Title> Backoffice - Comunidade Empodera </Title>
      <Box sx={{ mr: "10px" }}>
        <Button
          size="sm"
          colorScheme="yellow"
          sx={{ width: "100px", color: "white" }}
          onClick={logout}
        >
          Sair
        </Button>
      </Box>
    </Wrapper>
  );
};
