import {
  Button,
  Flex,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  faPenToSquare,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { Layout } from 'components/Layout'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Area } from 'types'
import { WorkFieldModal } from './modal'

export const WorkField = () => {
  const toast = useSnackbar();
  const [loading, isLoading] = useState(false);
  const [workFields, setWorkFields] = useState([] as Area[]);
  const [pickedWorkField, setPickedWorkField] = useState({} as Area);
  const [isModalOpen, openModal] = useState(false);
  const [isDialogOpen, openDialog] = useState(false);

  const fetchWorkFields = async () => {
    isLoading(true);
    const result = await api.field.list();
    setWorkFields(result);
    isLoading(false);
  };

  const removeWorkField = async () => {
    try {
      await api.field.delete(pickedWorkField.id);
      toast({
        title: "Área removida com sucesso",
        status: "success",
      });
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
      });
    }
  };

  useEffect(() => {
    fetchWorkFields();
  }, []);

  return (
    <Layout header menu footer>
      <Flex
        direction={"column"}
        justify="flex-start"
        w="70%"
        style={{ marginLeft: "15%" }}
      >
        <Flex justify="flex-end" mt="20px">
          <Button
            colorScheme="yellow"
            size="md"
            onClick={() => {
              openModal(true);
            }}
          >
            Criar
          </Button>
        </Flex>
        {loading ? (
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
            style={{ margin: "auto" }}
          />
        ) : (
          <Flex justify="center" w="100%" mb="40px">
            <TableContainer>
              <Table variant="simple">
                <TableCaption placement="top">
                  <b>{workFields.length}</b> resultados encontrados
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Nome</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {workFields.map((field) => (
                    <Tr key={field.id}>
                      <Td>{field.id}</Td>
                      <Td>{field.nome}</Td>
                      <Td>
                        <FontAwesomeIcon
                          color="gray"
                          icon={faPenToSquare}
                          style={{ marginRight: "15px", cursor: "pointer" }}
                          title="Editar"
                          onClick={() => {
                            setPickedWorkField(field);
                            openModal(true);
                          }}
                        />
                        <FontAwesomeIcon
                          color="red"
                          icon={faTrashCan}
                          title="Remover"
                          onClick={() => {
                            setPickedWorkField(field);
                            openDialog(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>
      <WorkFieldModal
        open={isModalOpen}
        handleClose={() => {
          setPickedWorkField({} as Area);
          openModal(false);
          fetchWorkFields();
        }}
        workFieldToEdit={pickedWorkField}
      />
      <RemovalConfirmationDialog
        targetName="Área de atuação"
        confirmationMessage={`Deseja realmente remover a área <strong>${pickedWorkField.nome}</strong>?`}
        targetId={pickedWorkField.id}
        isOpen={isDialogOpen}
        onClose={() => {
          setPickedWorkField({} as Area);
          openDialog(false);
          fetchWorkFields();
        }}
        onRemoveTarget={removeWorkField}
      />
    </Layout>
  );
};
