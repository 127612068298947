import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Card,
  CardBody,
  Image,
  Flex,
  Box,
  Progress,
  Divider,
} from '@chakra-ui/react'
import { CustomSpinner } from 'components/Spinner'
import { config } from 'config'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { CompanySyntheticReport } from 'types/company'
import { Company } from 'types/trail'

type AudienceModalProps = {
  isOpen: boolean
  onClose: () => void
  company: Company
}

export const AudienceModal = ({
  isOpen,
  onClose,
  company
}: AudienceModalProps) => {
  const [loading, isLoading] = useState(false)
  const [synthetics, setSynthetics] = useState([] as CompanySyntheticReport[])

  const fetchSynthetics = async () => {
    isLoading(true)
    const result = await api.company.getSyntheticReport(company.id)
    setSynthetics(result)
    isLoading(false)
  }

  useEffect(() => {
    fetchSynthetics()
  }, [])

  return (
    <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Relatório de visualizações da empresa {company.nome}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomSpinner loading={loading}>
          <>
              <Flex justify={'center'}>
                {synthetics.length > 0 ? (
                  <p>
                    <b>{synthetics.length}</b> usuários encontrados
                  </p>
                ) : (
                  'Nenhum resultado.'
                )}
              </Flex>
              {synthetics.map(result => (
                <Card key={result.usuario.id} mb={5}>
                  <CardBody>
                    <Flex gap={5}>
                      <Image
                        boxSize='100px'
                        objectFit='scale-down'
                        alt={result.usuario.nome}
                        src={`${config.api.baseUrl}/static/images/usuarios/${result.usuario.foto}`}
                      />
                      <Flex direction={'column'}>
                        <Box fontSize='13px'>
                          <b>Identificador:</b> &nbsp; {result.usuario.id}
                        </Box>
                        <Box fontSize='13px'>
                          <b>Nome:</b> &nbsp; {result.usuario.nome}
                        </Box>
                        <Box fontSize='13px'>
                          <b>E-mal:</b> &nbsp; {result.usuario.email}
                        </Box>

                        <Divider mb={4}/>
                        {result.trilhas.length > 0 ? (
                          <>
                            {result.trilhas.map(trail => (
                              <Box fontSize='13px'>
                                <Box
                                  key={trail.nome.length * Math.random()}
                                >
                                  {trail.nome}
                                </Box>

                                <Box
                                  right='-120'
                                  position='relative'
                                  fontWeight={'bold'}
                                  zIndex={'999'}
                                >
                                  {trail.percentual}%
                                </Box>
                                <Progress
                                  position='relative'
                                  top='-20px'
                                  w={'250px'}
                                  size='lg'
                                  hasStripe
                                  colorScheme={
                                    trail.percentual < 50 ? 'red' : 'yellow'
                                  }
                                  value={trail.percentual}
                                />
                              </Box>
                            ))}
                          </>
                        ) : null}
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </>
          </CustomSpinner>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
