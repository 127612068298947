import React, { useEffect, useState } from "react";
import { SimpleGrid, FormControl } from "@chakra-ui/react";
import { Label } from "./styles";
import { Select } from "components/Form/Select";
import { SelectOptions, SelectOptionsValue, ExperienceForm } from "types";
import { parseExperienceForm } from "helper/user";
import { api } from "shared/api";
import { carrerLevels } from "shared/utils/arrays";
import { Props } from "./types";
import { Form } from "components/Form";
import { Combo } from "components/Combo";

const initialValues: ExperienceForm = {
  id: 0,
  empresaId: 0,
  areaId: 0,
  cargoId: 0,
  nivel: "",
};

export const Professional = ({ user, onClose }: Props) => {
  const [initial, setInitial] = useState<ExperienceForm>(initialValues)
  const [experiencies, setExperiences] = useState<ExperienceForm[]>([]);
  const [options, setOptions] = useState<SelectOptions[]>([]);
  const [currentOption, setCurrentOption] = useState<SelectOptionsValue>();
  const [companies, setCompanies] = useState<SelectOptions[]>([]);
  const [fields, setFields] = useState<SelectOptions[]>([]);
  const [positions, setPositions] = useState<SelectOptions[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.company.list().then(setCompanies);
    api.field.list().then(setFields);
    api.position.list().then(setPositions);
  }, []);

  useEffect(() => {
    if (!user) return;
    const list = user.experiencias.map((experience) => {
      return {
        id: experience.id,
        empresaId: Number(experience.empresa?.id),
        areaId: Number(experience.area?.id),
        cargoId: Number(experience.cargo?.id),
        nivel: experience.nivel,
      };
    });
    setExperiences(list);

    if (list.length) setCurrentOption(String(list[0].id));

    setOptions(
      user.experiencias.map((el) => {
        const company = el.empresa ? el.empresa.nome : "Não informado";
        const position = el.cargo ? el.cargo.nome : "Não informado";
        return {
          id: el.id,
          nome: `ID: ${el.id} - Empresa: ${company} - Cargo: ${position}`,
        };
      })
    );
  }, [user]);

  useEffect(() => {
    const s = experiencies.find((o) => o.id === Number(currentOption));
    s && setInitial(s);
  }, [currentOption]);

  const handleSubmit =  (values: ExperienceForm) => {
    setLoading(true);
    const parsed = parseExperienceForm(values);
    if (parsed.id) {
      api.experience
        .update(parsed?.id, parsed)
        .then(onClose)
        .finally(() => setLoading(false));
    }
  }

  return (
    <SimpleGrid columns={1} spacing="10px">
      <SimpleGrid columns={1} spacing="10px">
        <Combo
          name="option"
          options={options}
          handleChange={setCurrentOption}
        />
      </SimpleGrid>
      <Form
        initial={initial}
        onSubmit={handleSubmit}
        okLabel="Salvar"
        isLoading={loading}
      >
        <SimpleGrid columns={2} spacing="10px">
          <FormControl>
            <Label>Empresa</Label>
            <Select
              name="empresaId"
              options={companies}
            />
          </FormControl>
          <FormControl>
            <Label>Area e Atuação</Label>
            <Select
              name="areaId"
              options={fields}
            />
          </FormControl>
          <FormControl>
            <Label>Cargo</Label>
            <Select
              name="cargoId"
              options={positions}
            />
          </FormControl>
          <FormControl>
            <Label>Nível</Label>
            <Select
              name="nivel"
              options={carrerLevels}
            />
          </FormControl>
        </SimpleGrid>
      </Form>
    </SimpleGrid>
  );
};
