import styled from "styled-components";
import { theme } from "../../../shared/theme";

export const Wrapper = styled.div`
  height: 40px;
  width: 100%;
  background-color: ${theme.colors.green};
  color: ${theme.colors.yellow};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
