import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useSnackbar } from "hooks";
import { useContext } from "react";
import { useState } from "react";
import { api } from "shared/api";
import { InstitutionFormValue } from "types";
import { InstitutionForm } from "../InstitutionForm";
import { InstitutionsContext } from "../InstitutionsProvider";

type EditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  institution: InstitutionFormValue;
};

export const EditModal = ({ isOpen, onClose, institution }: EditModalProps) => {
  const toast = useSnackbar();
  const [loading, isLoading] = useState(false);
  const { uploadImage, fetchInstitutions, searchParams } =
    useContext(InstitutionsContext);

  const update = async (editedInstitution: InstitutionFormValue) => {
    isLoading(true);
    try {
      await api.institution.update(editedInstitution.id!!, editedInstitution);
      toast({
        title: `Instituição ${editedInstitution.nome} salva com sucesso`,
        status: "success",
      });
      onClose();
      fetchInstitutions(searchParams);
    } catch (error) {
      toast({
        title: `Não foi possível salvar a instituição ${institution.nome}`,
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar instituição </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InstitutionForm
            okLabel="Salvar"
            cancelLabel="Fechar"
            loading={loading}
            institutionToEdit={institution}
            uploadImage={(event) => {
              uploadImage(Number(institution.id), event);
              fetchInstitutions(searchParams);
              onClose();
            }}
            update={update}
            handleClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
