interface IObject {
  [key: string]: any;
}

export const assertions = [
  { id: "SIM", nome: "Sim" },
  { id: "NAO", nome: "Não" },
];

export const accountStatus = [
  { id: "ATIVA", nome: "Ativa" },
  { id: "DESATIVADA", nome: "Desativada" },
  { id: "ENCERRADA", nome: "Encerrada" },
];

export const accountClassification = [
  { id: 0, nome: "0" },
  { id: 1, nome: "1" },
  { id: 2, nome: "2" },
  { id: 3, nome: "3" },
  { id: 4, nome: "4" },
];

export const ethnicities = [
  { id: "AMARELA", nome: "Amarela" },
  { id: "BRANCA", nome: "Branca" },
  { id: "DESCONHECIDA", nome: "Desconhecida" },
  { id: "INDIGENA", nome: "Indígena" },
  { id: "PARDA", nome: "Parda" },
  { id: "PRETA", nome: "Preta" },
];

export const genders = [
  { id: "MASCULINO", nome: "Masculino" },
  { id: "FEMININO", nome: "Feminino" },
  { id: "NAOBINARIO", nome: "Não Binário" },
];

export const educationLevels = [
  { id: "DESCONHECIDO", nome: "Desconhecido" },
  { id: "FUNDAMENTAL", nome: "Ensino Fundamental" },
  { id: "MEDIO", nome: "Ensino Médio" },
  { id: "SUPERIOR", nome: "Ensino Superior" },
  { id: "POSGRADUACAO", nome: "Pós Graduação" },
  { id: "MESTRADO", nome: "Mestrado" },
  { id: "DOUTORADO", nome: "Doutorado" },
];

export const educationPeriods = (() => {
  const start = 1;
  const end = 10;

  let temp = start;

  const periods = [];

  while (temp <= end) {
    periods.push({ id: `${temp}`, nome: `${temp}` });
    temp += 1;
  }
  return periods;
})();

export const educationConclusions = (() => {
  const start = 1980;
  const end = 2030;

  let temp = start;

  const conclusions = [];

  while (temp < end) {
    conclusions.push({ id: `${temp}.1`, nome: `${temp}.1` });
    conclusions.push({ id: `${temp}.2`, nome: `${temp}.2` });
    temp += 1;
  }

  return conclusions;
})();

export const englishLevels = [
  { id: "BASICO", nome: "Básico" },
  { id: "INTERMEDIARIO", nome: "Intermediário" },
  { id: "INTERMEDIARIOAVANCADO", nome: "Intermediário - Avançado" },
  { id: "AVANCADO", nome: "Avançado" },
];

export const logicLevels = [
  { id: 50, nome: "50%" },
  { id: 55, nome: "55%" },
  { id: 60, nome: "60%" },
  { id: 65, nome: "65%" },
  { id: 70, nome: "70%" },
  { id: 75, nome: "75%" },
  { id: 80, nome: "80%" },
  { id: 85, nome: "85%" },
  { id: 90, nome: "90%" },
  { id: 95, nome: "95%" },
  { id: 100, nome: "100%" },
];

export const orientations = [
  { id: "DESCONHECIDA", nome: "Desconhecida" },
  { id: "HETEROSSEXUAL", nome: "Heterossexual" },
  { id: "HOMOSSEXUAL", nome: "Homossexual" },
  { id: "BISSEXUAL", nome: "Bissexual" },
  { id: "OUTRO", nome: "Outras" },
];

export const statuses = [
  { id: "DESCONHECIDO", nome: "Desconhecido" },
  { id: "CURSANDO", nome: "Cursando" },
  { id: "TRANCADO", nome: "Trancado" },
  { id: "CONCLUIDO", nome: "Concluído" },
];

export const selectiveProcessPhases = [
  { id: 1, nome: "Triagem de Currículos" },
  { id: 2, nome: "Provas Online" },
  { id: 3, nome: "Entrevista por Skype" },
  { id: 4, nome: "Dinâmica de Grupo" },
  { id: 5, nome: "Painel" },
  { id: 6, nome: "Entrevista Final" },
  // { id: 7, nome: 'Outros' },
];

export const idiomLevels = [
  { id: "BASICO", nome: "Básico" },
  { id: "INTERMEDIARIO", nome: "Intermediário" },
  { id: "AVANCADO", nome: "Avançado" },
  { id: "FLUENTE", nome: "Fluente" },
];

export const computingLevels = [
  { id: "BASICO", nome: "Básico" },
  { id: "INTERMEDIARIO", nome: "Intermediário" },
  { id: "AVANCADO", nome: "Avançado" },
];

export const carrerLevels = [
  { id: "DESCONHECIDO", nome: "Selecione" },
  { id: "AUXILIAROPERACIONAL", nome: "Auxiliar / Operacional" },
  { id: "TECNICO", nome: "Técnico" },
  { id: "ESTAGIO", nome: "Estágio" },
  { id: "JUNIORTRAINEE", nome: "Junior / Trainee" },
  { id: "PLENO", nome: "Pleno" },
  { id: "SENIOR", nome: "Sênior" },
  { id: "SUPERVISAOCOORDENACAO", nome: "Supervisão / Coordenação" },
  { id: "GERENCIA", nome: "Gerência" },
  { id: "DIRETORIA", nome: "Diretoria" },
];

export const disabilityTypes = [
  { id: "AUDITIVA", nome: "Auditiva" },
  { id: "FALA", nome: "Fala" },
  { id: "VISUAL", nome: "Visual" },
  { id: "INTELECTUAL_OU_MENTAL", nome: "Intelectual / Mental " },
  { id: "FISICA", nome: "Física" },
];

export const hearingDeficiencyTypes = [
  { id: "ENTRE_41_E_55_DB", nome: "De 41 a 55 dB - Surdez Moderada" },
  { id: "ENTRE_56_E_70_DB", nome: "De 56 a 70 dB - Surdez Acentuada" },
  { id: "ENTRE_71_E_90_DB", nome: "De 71 a 90 dB - Surdez Severa" },
  { id: "ACIMA_91_DB", nome: "Surdez Profunda" },
  { id: "ANACUSIA", nome: "Anacusia" },
];

export const speechDisabilityTypes = [
  { id: "GRANDES_ALTERACOES_FALA", nome: "Grandes alterações na fala" },
  { id: "MUDEZ", nome: "Mudez" },
];

export const visualDisabilityTypes = [
  {
    id: "VISAO_SUBNORMAL_OU_BAIXA_VISAO",
    nome: "Visão Subnormal ou Baixa Visão",
  },
  { id: "CEGUEIRA", nome: "Cegueira" },
];

export const visualDisabilityDescription = [
  { id: "UNILATERAL", nome: "Unilateral" },
  { id: "BILATERAL", nome: "Bilateral" },
];

export const mentalDisabilityTypes = [
  { id: "limitacaoComunicacao", nome: "Comunicação" },
  { id: "limitacaoCuidadoPessoal", nome: "Cuidado Pessoal" },
  { id: "limitacaoHabilidadeSocial", nome: "Habilidade Social" },
  {
    id: "limitacaoUtilizacaoRecursosComunidade",
    nome: "Utilização de Recursos da Comunidade",
  },
  { id: "limitacaoSaudeSeguranca", nome: "Saúde e Segurança" },
  { id: "limitacaoHabilidadeAcademica", nome: "Habilitação Acadêmica" },
  { id: "limitacaoLazer", nome: "Lazer" },
  { id: "limitacaoTrabalho", nome: "Trabalho" },
];

export const physicalDisabilityTypes = [
  { id: "MONOPARESIA", nome: "Monoparesia" },
  { id: "MONOPLEGIA", nome: "Monoplegia" },
  { id: "HEMIPARESIA", nome: "Hemiparesia" },
  { id: "HEMIPLEGIA", nome: "Hemiplegia" },
  { id: "PARAPARESIA", nome: "Paraparesia" },
  { id: "PARAPLEGIA", nome: "Paraplegia" },
  { id: "TRIPARESIA", nome: "Triparesia" },
  { id: "TRIPLEGIA", nome: "Triplegia" },
  { id: "TETRAPARESIA", nome: "Tetraparesia" },
  { id: "TETRAPLEGIA", nome: "Tetraplegia" },
  {
    id: "AMPUTACAO_OU_AUSENCIA_DE_MEMBRO",
    nome: "Amputação ou ausência de membro",
  },
  {
    id: "MEMBROS_COM_DEFORMIDADE_CONGENITA_OU_ADQUIRIDA",
    nome: "Membros com deformidade congênita ou adquirida",
  },
  { id: "NANISMO", nome: "Nanismo" },
  { id: "OSTOMIA", nome: "Ostomia" },
];

export const positionPreReq: IObject = {
  CIDADE: "Cidade",
  CURSO: "Curso",
  ESTADO: "Estado",
  FORMATURA: "Formatura",
  INGLES: "Inglês",
  LOGICA: "Lógica",
  PERIODO: "Periodo",
};

export const removeFalsy = (params: IObject): IObject => {
  const cleared: IObject = {};
  Object.keys(params).forEach((key: string) => {
    const isString = params[key] instanceof String;
    const isObject = params[key] instanceof Object;
    if (params[key] && isString) {
      cleared[key] = params[key];
    }
    if (params[key].length && isObject) {
      cleared[key] = params[key];
    }
  });
  return cleared;
};

export const addDefautOption = (list: any[], name = "") => {
  return [{ id: null, nome: name || "Selecione um" }, ...list];
};

export const getLast = (list: any[]) => {
  return list[list.length - 1];
};

export const findElements = (list: any[], ids: number[]) =>
  list.filter((i) => ids.includes(i.id));
