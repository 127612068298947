import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { Dashboard } from './pages/Dashboard'
import { Login } from './pages/Login'
import './App.css'
import { Users } from 'pages/Dashboard/Users'
import { ListUsers } from 'pages/User/List'
import { CreationUser } from 'pages/User/Creation'
import { Course } from 'pages/Scholarity/Course'
import { Institutions } from 'pages/Scholarity/Institutions'
import InstitutionsProvider from 'pages/Scholarity/Institutions/InstitutionsProvider'
import { WorkField } from 'pages/Career/WorkField'
import { Companies } from 'pages/Career/Companies'
import CompaniesProvider from 'pages/Career/Companies/CompaniesProvider'
import { ContentPage } from 'pages/Content'
import ItemProvider from 'pages/Content/Provider'
import { ThemePage } from 'pages/Content/Theme'
import { Subtheme1Page } from 'pages/Content/Subtheme1'
import { Subtheme2Page } from 'pages/Content/Subtheme2'
import { Subtheme3Page } from 'pages/Content/Subtheme3'
import { CategoryPage } from 'pages/Content/Category'
import { MarketPage } from 'pages/Market'
import { TrailPage } from 'pages/Trail'
import { SelectiveProcessPage } from 'pages/Selection'

type Props = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: Props) => {
  const { pathname } = useLocation();
  const user = localStorage.getItem("@empodera/backoffice");
  const isAuthenticated = user !== null;

  if (isAuthenticated) {
    return pathname === "/" ? <Dashboard /> : children;
  }

  return <Login />;
};

const routes = [
  { path: '/', isPrivate: false, element: Login },
  { path: '/dashboard', isPrivate: true, element: Dashboard },
  { path: '/dashboard/usuarios', isPrivate: true, element: Users },
  { path: '/escolaridade/cursos', isPrivate: true, element: Course },
  { path: '/usuarios', isPrivate: true, element: ListUsers },
  { path: '/usuarios/geracao', isPrivate: true, element: CreationUser },
  { path: '/areadeatuacao/listar', isPrivate: true, element: WorkField },
  { path: '/item/temas', isPrivate: true, element: ThemePage },
  { path: '/item/subtemas1', isPrivate: true, element: Subtheme1Page },
  { path: '/item/subtemas2', isPrivate: true, element: Subtheme2Page },
  { path: '/item/subtemas3', isPrivate: true, element: Subtheme3Page },
  { path: '/item/categorias', isPrivate: true, element: CategoryPage },
  { path: '/trilha/listar', isPrivate: true, element: TrailPage },
  { path: '/mercado/buscar', isPrivate: true, element: MarketPage },
  { path: '/processosseletivos', isPrivate: true, element: SelectiveProcessPage },
]

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          {
            routes.map((route, index) => (
              <Route 
                key={index}
                path={route.path}
                element={
                  route.isPrivate ? 
                  <PrivateRoute> 
                    <route.element /> 
                  </PrivateRoute>
                  : <route.element />
                }
              />    
            ))
          }
          <Route
            path="/escolaridade/instituicoes"
            element={
              <PrivateRoute>
                <InstitutionsProvider>
                  <Institutions />
                </InstitutionsProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/empresa/listar"
            element={
              <PrivateRoute>
                <CompaniesProvider>
                  <Companies />
                </CompaniesProvider>
              </PrivateRoute>
            }
          />
          <Route
            path='/item/listar'
            element={
              <PrivateRoute>
                <ItemProvider>
                  <ContentPage />
                </ItemProvider>
              </PrivateRoute>
            }
          />
        </Routes>
        
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
