import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  UnorderedList,
  ListItem,
  Image,
  Box,
  Spinner,
  SimpleGrid,
  ModalFooter,
  InputLeftElement,
  InputGroup
} from '@chakra-ui/react'
import { faHashtag, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { config } from 'config'
import { useSnackbar } from 'hooks'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Manager } from 'types/company'
import { EmpoderaCoinModal } from './EmpoderaCoinModal'

type ManagerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  companyId: number;
  resetCompany: () => void;
};

export const ManagerModal = ({
  isOpen,
  onClose,
  companyId,
  resetCompany,
}: ManagerModalProps) => {
  const toast = useSnackbar();
  const [managers, setManagers] = useState([] as Manager[]);
  const [pickedManager, setPickedManager] = useState({} as Manager);
  const [userId, setUserId] = useState(0);
  const [loading, isLoading] = useState(false);
  const [isDialogOpen, openDialog] = useState(false);
  const [isEmpoderaCoinModalOpen, openEmpoderaCoinModal] = useState(false);

  const associateManager = async () => {
    const { user }: any = JSON.parse(
      localStorage.getItem("@empodera/backoffice")!!
    );
    try {
      await api.manager.create({
        empresa: {
          id: companyId,
        },
        usuario: {
          id: userId,
        },
        criadoPor: {
          id: user.id,
        },
      });
      setUserId(0);
      fetchManagers();
      toast({
        title: "Gestor cadastrado com sucesso.",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível cadastrar o gestor.",
        status: "error",
      });
    }
  };

  const removeManager = async () => {
    try {
      await api.manager.delete(pickedManager.id);
      toast({
        title: "Gestor removido com sucesso.",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível remover gestor.",
        status: "error",
      });
    } finally {
      openDialog(false);
      fetchManagers();
    }
  };

  const fetchManagers = async () => {
    isLoading(true);
    try {
      const result = await api.company.getManagers(companyId);
      setManagers(result);
    } catch (error) {
      toast({
        title: "Não foi possível concluir a pesquisa.",
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    fetchManagers();
  }, []);

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const resetStates = () => {
    setManagers([]);
    setUserId(0);
    resetCompany();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar Gestor </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>ID do usuário</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <FontAwesomeIcon
                      color="lightGray"
                      icon={faHashtag}
                      style={{
                        marginLeft: "10px",
                        marginTop: "1px",
                        cursor: "pointer",
                      }}
                      title="Salvar moedas"
                    />
                  }
                />
                <Input
                  type="number"
                  w="25%"
                  value={userId || ""}
                  onChange={({ target }) => setUserId(parseInt(target.value))}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>

          {managers.length > 0 ? (
            <p style={{ textAlign: "center", fontSize: "13px" }}>
              <b>{managers.length}</b> gestores encontrados
            </p>
          ) : (
            <p style={{ textAlign: "center", fontSize: "13px" }}>
              <b>Nenhum gestor encontrado</b>
            </p>
          )}

          {loading ? (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
              style={{ margin: "auto" }}
            />
          ) : (
            <SimpleGrid columns={1} gap={2} m="2">
              {managers.map((manager) => (
                <Flex
                  key={manager.id}
                  bgColor="rgba(250, 250, 250)"
                  _hover={{ boxShadow: "0 0 5px 2px #b7d7e8" }}
                  boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                  direction="column"
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "5px",
                    }}
                  >
                    <Image
                      boxSize="100px"
                      objectFit="scale-down"
                      src={`${config.api.baseUrl}/static/images/usuarios/${manager.usuario.foto}`}
                      alt={manager.usuario.nome}
                    />
                    <UnorderedList listStyleType="none" fontSize="11px">
                      <ListItem>
                        <strong>ID do usuário: </strong>
                        {manager.usuario.id}
                      </ListItem>
                      <ListItem>
                        <strong>ID de gestão: </strong>
                        {manager.id}
                      </ListItem>
                      <ListItem>
                        <strong>Nome: </strong>
                        {manager.usuario.nome}
                      </ListItem>
                      <ListItem>
                        <strong>E-mail: </strong>
                        {manager.usuario.email}
                      </ListItem>
                      <ListItem>
                        <strong>Busca no banco de talentos: </strong>
                        {manager.searches}
                      </ListItem>
                      <ListItem>
                        <strong>Empoderas disponíveis: </strong>
                        {manager.empodera}
                        <FontAwesomeIcon
                          color="darkGray"
                          icon={faPenToSquare}
                          style={{
                            marginLeft: "10px",
                            marginTop: "1px",
                            cursor: "pointer",
                          }}
                          title="Atualizar moedas"
                          onClick={() => {
                            setPickedManager(manager);
                            openEmpoderaCoinModal(true);
                          }}
                        />
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Flex justify={"flex-end"} p={3}>
                    <FontAwesomeIcon
                      color="red"
                      icon={faTrashCan}
                      title="Remover Gestor"
                      onClick={() => {
                        setPickedManager(manager);
                        openDialog(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </Flex>
                </Flex>
              ))}
            </SimpleGrid>
          )}
          <ModalFooter>
            <Flex justify={"flex-end"}>
              <Button
                colorScheme="yellow"
                variant="outline"
                ml="2"
                size={"sm"}
                onClick={handleClose}
              >
                Fechar
              </Button>
              <Button
                colorScheme="yellow"
                ml="2"
                size={"sm"}
                onClick={({ target }: any) => associateManager()}
              >
                Salvar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {pickedManager.id && (
        <RemovalConfirmationDialog
          targetName="Gestor"
          confirmationMessage={`Deseja realmente remover o gestor <strong>${pickedManager?.usuario?.nome}</strong>?`}
          targetId={pickedManager.id}
          isOpen={isDialogOpen}
          onClose={() => {
            setPickedManager({} as Manager);
            openDialog(false);
          }}
          onRemoveTarget={removeManager}
        />
      )}
      <EmpoderaCoinModal
        isOpen={isEmpoderaCoinModalOpen}
        onClose={() => {
          openEmpoderaCoinModal(false);
          fetchManagers();
        }}
        manager={pickedManager}
      />
    </>
  );
};
