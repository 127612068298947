import { useMemo, useState } from "react"
import { convertSheetToJSON } from "shared/utils/file"
import { SheetLine } from "./types"
import { api } from "shared/api"
import { Layout } from "components/Layout"
import { Container, Title } from "./style"
import { Box, Button, Center, FormLabel, Input, SimpleGrid } from "@chakra-ui/react"
import { Table } from "components/Table"
import { Chooser } from "components/Form/Chooser"
import { parseDate } from "shared/utils/date"

const header = [
  { label: 'ID', field: 'id' },
  { label: 'Nome', field: 'nome' },
  { label: 'E-mail', field: 'email' },
  { label: 'Nascimento', field: 'nascimento' },
  { label: 'Gênero', field: 'genero' },
  { label: 'Telefone celular', field: 'celular' },
  { label: 'Status', field: 'status' },
  { label: 'Erro', field: 'error' },
]

export const CreationUser = () => {
    const [lines, setLines] = useState<SheetLine[]>([])
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const handleFile = async (file: File) => {
        if(file) {
          setLines([])
          const data = await convertSheetToJSON<SheetLine>(file)
          setLines(data.map(d => ({ ...d, status: 'PENDENTE' })))
        }
    }

    const buildUser = (line: SheetLine) => {
      return {
        nome: line.nome.trim(),
        email: line.email.trim(),
        nascimento: parseDate(line.nascimento.trim()),
        genero: line.genero.trim().toUpperCase(),
        foneCelular: line.celular.trim(),
        cidade: { id: 3659 },
        estado: { id: 19 },
        pais: { id: 33 },
        senha: password || 'empodera'
      }
    }

    const updateLines = (index: number, status: 'CRIADO' | 'ERRO', userId?: number, error?: string) => {
      setLines((old) => {
        old[index] = { id: userId, ...old[index], status, error }
        return [...old]
      })
    }

    const handleSubmit = async () => {
        let index = 0;
        setLoading(true)
        for(const line of lines) {
            try {
              const user = await api.user.create(buildUser(line))
              updateLines(index, 'CRIADO', user.id)
            } catch(error: any) {
              const errorMessage = error.response.data.message
              updateLines(index, 'ERRO', undefined, errorMessage)
            }
            index+=1
        }
        setLoading(false)
    }
    

    const handleReset = () => {
      setPassword('')
      setLines([])
    }

    const disableButton  = useMemo(() => {
        if(lines.length === 0) return true;
        if(loading) return true;
        return false;
    }, [lines, loading])

    return (
        <Layout header menu footer>
            <Container>
                <Title>Geração de Usuários</Title>
                <SimpleGrid columns={2} spacing={2}>
                    <Box px={10} py={5}>
                        <ul style={{ fontSize: '14px', textAlign:'justify' }}>
                            <li>
                                Após preencher a planilha, é necessário <b>manter o cabeçalho</b> informando qual a posição dos dados antes de realizar o upload da planilha. 
                            </li>
                            <li>
                                Se nenhuma senha for informada, o sistema usará automaticamente a palavra <b>empodera</b> para todas as contas.
                            </li>
                            <li>
                                Caso você informe uma senha, a mesma será usada para todas as novas contas. 
                            </li>
                        </ul>
                    </Box>
                    <Box px={10} py={5} display='flex' flexDirection='column'>
                        <form onReset={handleReset}>
                          <SimpleGrid columns={3} spacing={2}>
                              <div>
                                <FormLabel fontSize='sm' as='b'> 
                                  Planilha: &nbsp;
                                  <a href={process.env.PUBLIC_URL + '/template.xls'} style={{ cursor: 'pointer', color: '#337ab7' }}> 
                                    (Baixar modelo) 
                                  </a>
                                </FormLabel>
                                <Chooser setFile={handleFile} />
                              </div>
                              <div>
                                <FormLabel fontSize='sm' as='b'> Informe a senha: </FormLabel>
                                <Input
                                  size='sm' 
                                  value={password} 
                                  onChange={(event) => setPassword(event.target.value)} 
                                />
                              </div>
                              <SimpleGrid columns={2} spacing={2}>
                                <Button
                                    color='white'
                                    colorScheme="yellow"
                                    isLoading={loading}
                                    size='sm' 
                                    disabled={disableButton}
                                    onClick={handleSubmit}
                                    mt={7}
                                > 
                                    Processar 
                                </Button>
                                <Button
                                    type="reset"
                                    colorScheme="gray"
                                    size='sm'
                                    mt={7}
                                > 
                                    Limpar 
                                </Button>
                              </SimpleGrid>
                          </SimpleGrid>
                        </form>
                    </Box>
                </SimpleGrid>
                <Center>
                  <Table header={header} elements={lines} />
                </Center>
            </Container>
        </Layout>
    )
}