import React from "react";
import { Box, Text } from "@chakra-ui/react";

type Props = {
  yesTotal: number;
  yesPercentage: number;
  noTotal: number;
  noPercentage: number;
};

export const AditionalInfo = ({
  yesTotal,
  yesPercentage,
  noTotal,
  noPercentage,
}: Props) => {
  return (
    <Box mt="10px">
      <Text fontSize="xs">
        <strong>{yesTotal}</strong> ou <strong>{yesPercentage}%</strong>{" "}
        informaram a etnia
      </Text>
      <Text fontSize="xs">
        <strong>{noTotal}</strong> ou <strong>{noPercentage}%</strong> não
        informaram a etnia
      </Text>
    </Box>
  );
};
