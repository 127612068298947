import React from "react";
import { Layout } from "components/Layout";
import { CourseSearch } from "./components/CourseSearch";
import { NewCourse } from "./components/NewCourse";
import { Flex } from "@chakra-ui/react";
import SearchProvider from "./components/CourseSearch/Provider";

export const Course = () => {
  return (
    <Layout header menu footer>
      <Flex direction="column" w="100%">
        <NewCourse />
        <SearchProvider>
          <CourseSearch />
        </SearchProvider>
      </Flex>
    </Layout>
  );
};
