import React, { useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { Course, Institution } from "types";
import { api } from "shared/api";
import { AsyncPicker, Picker } from "./style";
import {
  educationPeriods,
  englishLevels,
  educationConclusions,
  logicLevels,
  statuses,
  findElements,
} from "shared/utils/arrays";

type Props = {
  initial: any;
  handleInput: (event: any) => void;
  handlePicker: (name: string, value: number[]) => void;
};

export const AcademicForm = ({ initial, handleInput, handlePicker }: Props) => {
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);

  const loadInstitutions = async (value: string) => {
    if (value.length > 1) {
      const data = await api.institution.list({ nome: value, sigla: value });
      setInstitutions(data);
      return data;
    }
    return [];
  };

  useEffect(() => {
    api.course.list().then(setCourses);
  }, []);

  return (
    <>
      <SimpleGrid columns={2} spacing={5} style={{ marginBottom: "1rem" }}>
        <AsyncPicker
          value={findElements(institutions, initial.instituicao)}
          onChange={(value: any) =>
            handlePicker(
              "instituicao",
              value.map((v: any) => v.id)
            )
          }
          cacheOptions={true}
          defaultOptions={institutions}
          placeholder="Instituição (digite para pesquisar)"
          loadOptions={loadInstitutions}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
        <Picker
          value={findElements(courses, initial.curso)}
          onChange={(value: any) =>
            handlePicker(
              "curso",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Curso"
          options={courses}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
        <Picker
          value={findElements(statuses, initial.status)}
          onChange={(value: any) =>
            handlePicker(
              "status",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Situação"
          options={statuses}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
        <Picker
          value={findElements(educationConclusions, initial.conclusao)}
          onChange={(value: any) =>
            handlePicker(
              "conclusao",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Conclusão"
          options={educationConclusions}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
      </SimpleGrid>
      <SimpleGrid columns={3} spacing={5} style={{ marginBottom: "1rem" }}>
        <Picker
          value={findElements(educationPeriods, initial.periodo)}
          onChange={(value: any) =>
            handlePicker(
              "periodo",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Periodo Especifico"
          options={educationPeriods}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
        <Picker
          value={findElements(logicLevels, initial.logica)}
          onChange={(value: any) =>
            handlePicker(
              "logica",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Lógica"
          options={logicLevels}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
        <Picker
          value={findElements(englishLevels, initial.ingles)}
          onChange={(value: any) =>
            handlePicker(
              "ingles",
              value.map((v: any) => v.id)
            )
          }
          placeholder="Inglês"
          options={englishLevels}
          isMulti
          getOptionLabel={(data: any) => data.nome}
          getOptionValue={(data: any) => String(data.id)}
        />
      </SimpleGrid>
    </>
  );
};
