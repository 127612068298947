import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box } from "@chakra-ui/react";
import { LineChartProps } from "./types";
import { defaultOptions } from "../config";

export const LineChart = ({ title, labels, datasets }: LineChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels,
    datasets: datasets.map((dataset) => ({
      tension: 0.5,
      borderWidth: 1.5,
      ...dataset,
    })),
  };

  const customOptions = structuredClone(defaultOptions);
  customOptions.plugins.title.text = title;
  customOptions.plugins.tooltip.enabled = true;

  return (
    <Box w="90%">
      <Line
        data={data}
        options={customOptions}
        style={{ maxHeight: "300px" }}
      />
    </Box>
  );
};
