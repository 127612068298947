import { Paginate } from "./style"

type Props = {
  page: number;
  pages: number
  onChange: (page: number) => void;
}

type SelectedItem = {
  selected: number
}

export const Paginator = ({ page, pages, onChange }: Props) => {

  const handleChange = ({ selected}: SelectedItem) => {
    onChange(selected + 1)
  }

  return(
    <Paginate 
      breakLabel="..."
      nextLabel="Póximo >"
      onPageChange={handleChange}
      pageRangeDisplayed={10}
      forcePage={page - 1}
      pageCount={pages}
      previousLabel="< Anterior"
    />
  )
}