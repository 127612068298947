import { useSnackbar } from "hooks";
import React, { useState } from "react";
import { api } from "shared/api";
import { Course, EducationLevel } from "types/scholarity";

export type SearchProviderProps = {
  children: React.ReactElement;
};

type SearchContextType = {
  fetchCourses: (type: string) => void;
  removeCourse: ({ id, nome }: Course) => void;
  setCourse: (course: Course) => void;
  editCourse: (course: Course) => void;
  courses: Course[];
  course: Course;
  isLoading: boolean;
  typep: string;
};

const initialCourse = {
  id: 0,
  nome: "",
  grau: "MEDIO" as EducationLevel,
  peso: 0,
};

const initialContext: SearchContextType = {
  fetchCourses: () => {},
  removeCourse: () => {},
  setCourse: (course: Course) => {},
  editCourse: (course: Course) => {},
  courses: [],
  course: initialCourse,
  isLoading: false,
  typep: "MEDIO",
};

export const SearchContext = React.createContext(initialContext);

const SearchProvider = ({ children }: SearchProviderProps) => {
  const toast = useSnackbar();
  const initialState: Course[] = [];
  const [courses, setCourses] = useState(initialState);
  const [course, setCourse] = useState(initialCourse);
  const [typep, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourses = async (type: string) => {
    setIsLoading(true);
    const result = await api.course.listByLevel(type);
    setIsLoading(false);

    setType(type);
    setCourses(result);
  };

  const removeCourse = async ({ id, nome }: Course) => {
    try {
      await api.course.delete(id);
      toast({
        title: `Curso ${nome} foi removido com sucesso`,
        description: "",
        status: "success",
      });
      await fetchCourses(typep);
    } catch (error) {
      toast({
        title: `Não foi possível remover o curso ${nome}`,
        description: "",
        status: "error",
      });
    }
  };

  const editCourse = async (editedCourse: Course) => {
    try {
      await api.course.update(editedCourse.id, editedCourse);
      toast({
        title: "Curso editado com sucesso",
        description: "",
        status: "success",
      });
      await fetchCourses(typep);
    } catch (error) {
      toast({
        title: "Não foi possível editar curso.",
        description: "",
        status: "error",
      });
    }
  };
  return (
    <SearchContext.Provider
      value={{
        fetchCourses,
        removeCourse,
        setCourse,
        editCourse,
        courses,
        course,
        isLoading,
        typep,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
