import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select
} from '@chakra-ui/react'
import { faFont, faHashtag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Category, Subtheme1, Subtheme2, Subtheme3, Theme } from 'types/content'
import { ItemContext } from './Provider'
import { SearchParams } from './types'

export const ContentForm = () => {
  const [themes, setThemes] = useState([] as Theme[])
  const [subtheme1, setSubtheme1] = useState([] as Subtheme1[])
  const [subtheme2, setSubtheme2] = useState([] as Subtheme2[])
  const [subtheme3, setSubtheme3] = useState([] as Subtheme3[])
  const [categories, setCategories] = useState([] as Category[])
  const [idOnly, setOnlyId] = useState(false)
  const { fetchItems, setItems, setParams, params } = useContext(ItemContext)

  useEffect(() => {
    api.theme.list().then(setThemes)
    api.categories.list().then(setCategories)
  }, [])

  function onThemeChange ({ target }: any) {
    setSubtheme1([])
    setSubtheme2([])
    setSubtheme3([])

    const themeId = parseInt(target.value)
    if (!isNaN(themeId)) {
      api.subtheme1.getSubthemes(themeId).then(setSubtheme1)
      setParams({ ...params, tema: themeId })
      return
    }

    setParams({ ...params, tema: 0 })
  }

  function onSubTheme1Change ({ target }: any) {
    const subtheme1Id = parseInt(target.value)
    if (!isNaN(subtheme1Id)) {
      api.subtheme2.getSubthemes(subtheme1Id).then(setSubtheme2)
      setParams({ ...params, subtema1: subtheme1Id })
      return
    }

    setParams({ ...params, subtema1: 0 })
  }

  function onSubTheme2Change ({ target }: any) {
    const subtheme2Id = parseInt(target.value)
    if (!isNaN(subtheme2Id)) {
      api.subtheme3.get(subtheme2Id).then(setSubtheme3)
      setParams({ ...params, subtema2: subtheme2Id })
      return
    }

    setParams({ ...params, subtema2: 0 })
  }

  function onSubTheme3Change ({ target }: any) {
    const subtheme3Id = parseInt(target.value)
    if (!isNaN(subtheme3Id)) {
      setParams({ ...params, subtema3: subtheme3Id })
    }

    setParams({ ...params, subtema3: 0 })
  }

  return (
    <Flex direction='column' w='40%' ml='30%' mb='30px' mt={5}>
      <FormControl>
        <FormLabel>ID</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={<FontAwesomeIcon color='lightGray' icon={faHashtag} />}
          />
          <Input
            type='number'
            value={params.id || ''}
            onChange={({ target }) =>
              setParams({ ...params, id: parseInt(target.value) })
            }
            onFocus={() => setOnlyId(true)}
            onBlur={() => setOnlyId(false)}
          />
        </InputGroup>
      </FormControl>

      <FormControl mt={2}>
        <FormLabel>Título</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={
              <FontAwesomeIcon
                color='lightGray'
                icon={faFont}
              />
            }
          />
        <Input
          type='text'
          value={params.titulo || ''}
          onChange={({ target }) =>
            setParams({ ...params, titulo: target.value })
          }
          isDisabled={idOnly}
        />
        </InputGroup>
      </FormControl>

      <FormControl mt={2}>
        <FormLabel>Título Reduzido</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={
              <FontAwesomeIcon
                color='lightGray'
                icon={faFont}
              />
            }
          />
        <Input
          type='text'
          value={params.tituloReduzido || ''}
          onChange={({ target }) =>
            setParams({ ...params, tituloReduzido: target.value })
          }
          isDisabled={idOnly}
        />
        </InputGroup>
      </FormControl>

      <FormControl mt={2}>
        <FormLabel>Tipo de Media</FormLabel>
        <Select
          name='media'
          placeholder='Selecione...'
          value={params.media || ''}
          onChange={({ target }) =>
            setParams({
              ...params,
              media: target.value
            })
          }
          isDisabled={idOnly}
        >
          <option value='VIDEO'>Vídeo</option>
          <option value='PDF'>PDF</option>
        </Select>
      </FormControl>

      <FormControl mt={2}>
        <FormLabel>Categoria</FormLabel>
        <Select
          name='categoria'
          placeholder='Selecione...'
          value={params.categoria || ''}
          onChange={({ target }) =>
            setParams({
              ...params,
              categoria: parseInt(target.value)
            })
          }
          isDisabled={idOnly}
        >
          {categories.map(category => (
            <option key={category.id} value={category.id}>
              {category.nome}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt={2}>
        <FormLabel>Tema</FormLabel>
        <Select
          name='tema'
          placeholder='Selecione...'
          value={params.tema || ''}
          onChange={onThemeChange}
          isDisabled={idOnly}
        >
          {themes.map(theme => (
            <option key={theme.id} value={theme.id}>
              {theme.nome}
            </option>
          ))}
        </Select>
      </FormControl>

      {subtheme1.length > 0 && (
        <FormControl mt={2}>
          <FormLabel>Subtema 1</FormLabel>
          <Select
            name='subtema1'
            placeholder='Selecione...'
            value={params.subtema1 || ''}
            onChange={onSubTheme1Change}
            isDisabled={idOnly}
          >
            {subtheme1.map(subtheme1 => (
              <option key={subtheme1.id} value={subtheme1.id}>
                {subtheme1.nome}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      {subtheme2.length > 0 && (
        <FormControl mt={2}>
          <FormLabel>Subtema 2</FormLabel>
          <Select
            name='subtema2'
            placeholder='Selecione...'
            value={params.subtema2 || ''}
            onChange={onSubTheme2Change}
            isDisabled={idOnly}
          >
            {subtheme2.map(subtheme2 => (
              <option key={subtheme2.id} value={subtheme2.id}>
                {subtheme2.nome}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      {subtheme3.length > 0 && (
        <FormControl mt={2}>
          <FormLabel>Subtema 3</FormLabel>
          <Select
            name='subtema3'
            placeholder='Selecione...'
            value={params.subtema3 || ''}
            onChange={onSubTheme3Change}
            isDisabled={idOnly}
          >
            {subtheme3.map(subtheme3 => (
              <option key={subtheme3.id} value={subtheme3.id}>
                {subtheme3.nome}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl mt={2}>
        <Checkbox
          isChecked={params.habilitado || false}
          onChange={({ target }) =>
            setParams({
              ...params,
              habilitado: target.checked
            })
          }
          isDisabled={idOnly}
        >
          Habilitado
        </Checkbox>
      </FormControl>

      <Flex justify='flex-end' gap={2} mt={2}>
        <Button
          colorScheme='yellow'
          variant={'outline'}
          onClick={() => {
            setParams({} as SearchParams)
            setItems([])
            setSubtheme1([])
            setSubtheme2([])
            setSubtheme3([])
          }}
        >
          Limpar
        </Button>
        <Button colorScheme='yellow' onClick={fetchItems}>
          Pesquisar
        </Button>
      </Flex>
    </Flex>
  )
}
