import React, { FC, useMemo, useState } from "react";
import { config } from "config";
import { Avatar } from "@chakra-ui/react";
import { User } from "types";
import { theme } from "shared/theme"
import { Anchor, AvatarBox, Container, ContentBox, ActionBox } from "./style";
import { Profile } from "components/Profile";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  user: User;
  reset: () => void;
};

export const Record: FC<Props> = ({ user, reset }) => {
  const [open, setOpen] = useState(false);

  const avatarUrl = useMemo(
    () => `${config.imagesPath}/usuarios/${user.foto}`,
    [user]
  );

  const ingles = useMemo(() => user.avaliacoes?.ingles, [user]);

  const logica = useMemo(() => user.avaliacoes?.logica, [user]);

  const isPCD = useMemo(() => user.pcd === "SIM", [user]);

  const tipoDeficiencia = useMemo(() => {
    if (!isPCD) return "";
    return !!user.tipoDeficiencia ? user.tipoDeficiencia : "NÃO INFORMADA";
  }, [isPCD, user]);

  const curriculo = useMemo(() => {
    if (!user.curriculo.arquivo) return null;
    return `${config.curriculosPath}/${user.curriculo.arquivo}`;
  }, [user]);

  const bgColor = () => {
    return user.status === 'ATIVA' ? theme.colors.white : theme.colors.lightRed
  }

  return (
    <Container bgColor={bgColor()}>
      <AvatarBox>
        <Avatar size="xl" name={user.nome} src={avatarUrl} />
      </AvatarBox>
      <ContentBox>
        <p>
          <b>ID</b>: {user.id} |&nbsp;
          <b>Nome</b>: {user.nome} |&nbsp;
          <b>Email</b>: {user.email} |&nbsp;
          <b>Gênero</b>: {user.genero} |&nbsp;
          {user.etnia !== "DESCONHECIDA" && (
            <>
              <b>Raça ou cor</b>: {user.etnia} |{" "}
            </>
          )}
          {user.orientacaoSexual !== "DESCONHECIDA" && (
            <>
              <b>Orientação sexual</b>: {user.orientacaoSexual} |{" "}
            </>
          )}
          {isPCD && (
            <>
              <b>PCD</b>: {user.pcd} |{" "}
            </>
          )}
          {!!tipoDeficiencia && (
            <>
              <b>Deficiência</b>: {tipoDeficiencia}{" "}
            </>
          )}
        </p>
        {user.escolaridades.map((e, index) => (
          <p key={index}>
            {e.instituicao && (
              <>
                <b>Instituição:</b> {e.instituicao.nome} |&nbsp;
              </>
            )}
            {e.curso && (
              <>
                <b>Curso:</b> {e.curso.nome} |&nbsp;
              </>
            )}
            {e.grau !== "DESCONHECIDO" && (
              <>
                <b>Grau:</b> {e.grau} |&nbsp;
              </>
            )}
            {e.status !== "DESCONHECIDO" && (
              <>
                <b>Status:</b> {e.status} |&nbsp;
              </>
            )}
            {e.periodo && (
              <>
                <b>Periodo:</b> {e.periodo} |&nbsp;
              </>
            )}
            {e.conclusao && (
              <>
                <b>Conclusão:</b> {e.conclusao} |&nbsp;
              </>
            )}
            {!!curriculo && (
              <>
                <b>Currículo:</b>{" "}
                <Anchor href={curriculo} target="_blank">
                  {" "}
                  Baixar{" "}
                </Anchor>{" "}
                |&nbsp;
              </>
            )}
          </p>
        ))}
        {ingles && (
          <p>
            <b>Avaliação de inglês</b> - <b>Acertos</b>: {ingles.acertos} |{" "}
            <b>Percentual</b>: {ingles.percentual}%
          </p>
        )}
        {logica && (
          <p>
            <b>Avaliação de lógica</b> - <b>Acertos</b>: {logica.acertos} |{" "}
            <b>Percentual</b>: {logica.percentual}%
          </p>
        )}
      </ContentBox>
      <ActionBox>
        <FontAwesomeIcon
          style={{ width: "25px", height: "25px" }}
          icon={faUserEdit}
          onClick={() => setOpen(true)}
        />
      </ActionBox>
      <Profile
        id={user.id}
        open={open}
        onClose={() => {
          reset();
          setOpen(false);
        }}
      />
    </Container>
  );
};
