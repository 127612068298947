import {
  Flex,
  UnorderedList,
  ListItem,
  Box,
  Image,
  Link,
} from '@chakra-ui/react'
import {
  faPenToSquare,
  faTrashCan,
  faExternalLinkSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RemovalConfirmationDialog } from 'components/Dialog/RemovalConfirmation'
import { format, parseISO } from 'date-fns'
import { useSnackbar } from 'hooks'
import { useContext, useState } from 'react'
import { api } from 'shared/api'
import { Item } from 'types'
import { config } from '../../../config'
import { ContentModal } from '../ContentModal'
import { ItemContext } from '../Provider'

type ContentCardProps = {
  item: Item
}

export const ContentCard = ({ item }: ContentCardProps) => {
  const toast = useSnackbar()
  const initialState: Item = {
    titulo: '',
    tituloReduzido: '',
    habilitado: false,
    sinopse: '',
    foto: 'foto',
    link: '',
    media: '',
    nota: 0,
    recomendado: false
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const [pickedItem, setPickedItem] = useState(initialState)
  const { fetchItems } = useContext(ItemContext)
  const youTubePrefix = ''

  const onRemove = async ({ id, tituloReduzido }: Item) => {
    try {
      await api.items.delete(Number(id))
      toast({
        title: `Item ${tituloReduzido} removido com sucesso`,
        status: 'success'
      })
      fetchItems()
    } catch (error) {
      toast({
        title: `Não foi possível remover o item ${tituloReduzido}`,
        status: 'error'
      })
    } finally {
    }
  }

  return (
    <Flex
      key={item.id}
      bgColor='rgba(250, 250, 250)'
      _hover={{ boxShadow: '0 0 5px 2px #b7d7e8' }}
      boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
      p={2}
    >
      <Flex ml={2}>
        <Image
          boxSize='150px'
          objectFit='scale-down'
          src={`${config.api.baseUrl}/static/images/uploads/${item.foto}`}
          alt={item.titulo}
        />
      </Flex>

      <Box w='100%'>
        <UnorderedList listStyleType='none' fontSize='11px'>
          <ListItem>
            <strong>Identicador: </strong>
            {item.id}
          </ListItem>        

          <ListItem>
            <strong>Título Reduzido: </strong>
            {item.tituloReduzido}
          </ListItem>

          <ListItem>
            <strong>Incluído em: </strong>
            {item.incluidoEm
              ? format(parseISO(item.incluidoEm), 'dd/MM/yyyy')
              : ''}
          </ListItem>

          <ListItem>
            <strong>Tipo de Media: </strong>
            {item.media}
          </ListItem>

          <ListItem>
            <strong>Link: </strong>
            <Link href={item.media === 'VIDEO' ? youTubePrefix + item.link : item.link} isExternal>
              Clique aqui
              <FontAwesomeIcon color='gray' icon={faExternalLinkSquare} />
            </Link>
          </ListItem>

          <ListItem>
            <strong>Habilitado: </strong>
            {item.habilitado ? 'Sim' : 'Não'}
          </ListItem>

          <ListItem>
            <strong>Visualizações: </strong>
            {item.visualizacoes}
          </ListItem>

          <ListItem>
            <strong>Categoria: </strong>
            {item.categoria?.nome}
          </ListItem>

          <ListItem>
            <strong>Tema: </strong>
            {item.tema?.nome}
          </ListItem>
        </UnorderedList>

        <Flex justify='flex-end' mb='8px' mr='5px'>
          <FontAwesomeIcon
            color='gray'
            icon={faPenToSquare}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            title='Editar'
            onClick={() => {
              setPickedItem(item)
              openModal(true)
            }}
          />
          <FontAwesomeIcon
            color='red'
            icon={faTrashCan}
            title='Remover'
            onClick={() => {
              setPickedItem(item)
              setIsDialogOpen(true)
            }}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      </Box>

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName='Instituição'
          confirmationMessage={`Deseja realmente remover o item <strong>${pickedItem.titulo}</strong>?`}
          targetId={pickedItem.id!!}
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            setPickedItem(initialState)
          }}
          onRemoveTarget={() => onRemove(pickedItem)}
        />
      )}

      {isModalOpen && (
        <ContentModal
          isOpen={isModalOpen}
          onClose={() => {
            setPickedItem(initialState)
            openModal(false)
          }}
          itemToEdit={pickedItem}
        />
      )}
    </Flex>
  )
}
