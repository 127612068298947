import { useSnackbar } from "hooks";
import React, { useEffect, useState } from "react";
import { api } from "shared/api";
import { Country, State } from "types/place";
import { InstitutionFormValue } from "types/scholarity";
import { initialState } from "./types";

type InstitutionsProviderProps = {
  children: React.ReactElement;
};

type IntitutionsContextType = {
  uploadImage: (id: number, event: any) => void;
  fetchInstitutions: (searchParams: InstitutionFormValue) => void;
  institutions: InstitutionFormValue[];
  setInstitutions: (institutions: InstitutionFormValue[]) => void;
  loading: boolean;
  isLoading: (toggle: boolean) => void;
  states: State[];
  countries: Country[];
  searchParams: InstitutionFormValue;
};

const initialContext: IntitutionsContextType = {
  uploadImage: (id: number, event: any) => {},
  fetchInstitutions: (searchParams: InstitutionFormValue) => {},
  institutions: [],
  setInstitutions: (institutions: InstitutionFormValue[]) => {},
  loading: false,
  isLoading: (toggle: boolean) => {},
  states: [],
  countries: [],
  searchParams: {} as InstitutionFormValue,
};

export const InstitutionsContext = React.createContext(initialContext);

const InstitutionsProvider = ({ children }: InstitutionsProviderProps) => {
  const toast = useSnackbar();
  const [loading, isLoading] = useState(false);
  const [institutions, setInstitutions] = useState(
    [] as InstitutionFormValue[]
  );
  const [searchParams, setSearchParamsForFutureUse] = useState(initialState);
  const [countries, setCountries] = useState([] as Country[]);
  const [states, setStates] = useState([] as State[]);

  const fetchStates = () => api.state.list();

  const fetchCountries = () => api.country.list();

  const uploadImage = async (institutionId: number, event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("imagem", file, file.name);

    try {
      await api.institution.upload(institutionId, formData);
      toast({
        title: `Imagem ${file.name} cadastrada com sucesso`,
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Não foi possível cadastrar a imagem.",
        status: "error",
      });
    }
  };

  const fetchInstitutions = async (searchParams: InstitutionFormValue) => {
    isLoading(true);
    try {
      const result = await api.institution.list(searchParams);
      setInstitutions(result);

      if (result.length === 0) {
        toast({
          title: "Nenhum resultado encontrado.",
          status: "warning",
        });
      }
      setSearchParamsForFutureUse(searchParams);
    } catch (error) {
      toast({
        title: "Não foi possível concluir a pesquisa.",
        status: "error",
      });
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    fetchStates().then(setStates);
    fetchCountries().then(setCountries);
  }, []);

  return (
    <InstitutionsContext.Provider
      value={{
        uploadImage,
        fetchInstitutions,
        institutions,
        setInstitutions,
        loading,
        isLoading,
        states,
        countries,
        searchParams,
      }}
    >
      {children}
    </InstitutionsContext.Provider>
  );
};

export default InstitutionsProvider;
