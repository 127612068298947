import { useContext, useState } from "react";
import { Box, Flex, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import {
  faLayerGroup,
  faPenToSquare,
  faTrashCan,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RemovalConfirmationDialog } from "components/Dialog/RemovalConfirmation";
import { config } from "config";
import { useSnackbar } from "hooks";
import { api } from "shared/api";
import { CompaniesContext } from "../CompaniesProvider";
import { initialState } from "../types";
import { EditModal } from "./EditModal";
import { Area, CompanyFormValue } from "types/company";
import { ManagerModal } from "./ManagerModal";

type SearchResultProps = {
  companies: CompanyFormValue[];
};

export const SearchResult = ({ companies }: SearchResultProps) => {
  const toast = useSnackbar();
  const [pickedCompany, setPickedCompany] = useState(initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditModalOpen, openEditModal] = useState(false);
  const [isManagerModalOpen, openManagerModal] = useState(false);
  const { fetchCompanies, searchParams } = useContext(CompaniesContext);

  const onRemove = (company: CompanyFormValue) => {
    setPickedCompany(company);
    setIsDialogOpen(true);
  };

  const onEdit = (company: CompanyFormValue) => {
    setPickedCompany(company);
    openEditModal(true);
  };

  const remove = async ({ nome, id }: CompanyFormValue) => {
    try {
      await api.company.delete(Number(id));
      toast({
        title: `Empresa ${nome} removida com sucesso`,
        status: "success",
      });
      fetchCompanies(searchParams);
    } catch (error) {
      toast({
        title: `Não foi possível remover a empresa ${nome}`,
        status: "error",
      });
    }
  };

  const upgrade = async (company: CompanyFormValue, isClient: boolean) => {
    const action = isClient ? "promovida" : "rebaixada";
    try {
      await api.company.upgrade(Number(company.id), isClient);
      toast({
        title: `A empresa ${company.nome} foi ${action} com sucesso`,
        status: "success",
      });
      fetchCompanies(searchParams);
    } catch (error) {
      toast({
        title: `Não foi possível atualizar a empresa ${company.nome}`,
        status: "error",
      });
    }
  };

  return (
    <>
      {companies.map((company) => (
        <Flex
          key={company.id}
          bgColor="rgba(250, 250, 250)"
          _hover={{ boxShadow: "0 0 5px 2px #b7d7e8" }}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        >
          <Box
            style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}
          >
            <Image
              boxSize="100px"
              objectFit="scale-down"
              src={`${config.api.baseUrl}/static/images/empresas/${company.foto}`}
              alt={company.nome}
            />
          </Box>
          <Box w="100%">
            <UnorderedList listStyleType="none" fontSize="11px">
              <ListItem>
                <strong>ID: </strong>
                {company.id}
              </ListItem>
              <ListItem>
                <strong>Nome: </strong>
                {company.nome}
              </ListItem>
              <ListItem>
                <strong>Área de atuação: </strong>
                {(company.area as Area)?.nome}
              </ListItem>
              <ListItem>
                <strong>Pacote: </strong>
                {company.pacote === "NAOCONTRATADO"
                  ? "NÃO CONTRATADO"
                  : company.pacote}
              </ListItem>
              <ListItem>
                <strong>Cliente: </strong>
                {company.cliente ? "Sim" : "Não"}
              </ListItem>
              <ListItem>
                <strong>Nacionalidade: </strong>
                {company.nacionalidade}
              </ListItem>
              <ListItem>
                <strong>Capacitação: </strong>
                {company.capacitacao ? "LIBERADA" : "BLOQUEADA"}
              </ListItem>
            </UnorderedList>
            <Flex justify="flex-end" mb="8px" mr="5px">
              <FontAwesomeIcon
                color="gray"
                icon={faPenToSquare}
                style={{ marginRight: "10px", cursor: "pointer" }}
                title="Editar"
                onClick={() => onEdit(company)}
              />
              <FontAwesomeIcon
                color="lightBlue"
                icon={faLayerGroup}
                style={{ marginRight: "10px", cursor: "pointer" }}
                title={company.cliente ? "Rebaixar" : "Promover"}
                onClick={() => upgrade(company, !company.cliente)}
              />
              {company.cliente && (
                <FontAwesomeIcon
                  color="brown"
                  icon={faUsers}
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  title="Cadastrar Gestores"
                  onClick={() => {
                    setPickedCompany(company);
                    openManagerModal(true);
                  }}
                />
              )}
              <FontAwesomeIcon
                color="red"
                icon={faTrashCan}
                title="Remover"
                onClick={() => onRemove(company)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </Box>
        </Flex>
      ))}

      {isDialogOpen && (
        <RemovalConfirmationDialog
          targetName="Instituição"
          confirmationMessage={`Deseja realmente remover a empresa <strong>${pickedCompany.nome}</strong>?`}
          targetId={pickedCompany.id!!}
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            setPickedCompany(initialState);
          }}
          onRemoveTarget={() => remove(pickedCompany)}
        />
      )}

      {isEditModalOpen && (
        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setPickedCompany(initialState);
            openEditModal(false);
          }}
          company={pickedCompany}
        />
      )}

      {isManagerModalOpen && (
        <ManagerModal
          isOpen={isManagerModalOpen}
          onClose={() => openManagerModal(false)}
          companyId={Number(pickedCompany.id)}
          resetCompany={() => setPickedCompany(initialState)}
        />
      )}
    </>
  );
};
