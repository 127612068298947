import { InstitutionFormValue } from "types/scholarity";

export const initialState: InstitutionFormValue = {
  nome: "",
  sigla: "",
  estado: "",
  pais: "",
  tipo: "",
  nivel: "",
  peso: "",
};

export type FormProps = {
  okLabel: string;
  cancelLabel?: string;
  loading: boolean;
  institutionToEdit?: InstitutionFormValue;
  create?: (institution: InstitutionFormValue) => void;
  search?: (institution: InstitutionFormValue) => void;
  update?: (institution: InstitutionFormValue) => void;
  onResetResult?: () => void;
  uploadImage?: (event: any) => void;
  handleClose?: () => void;
};
