import React, { useEffect, useState } from "react";
import { SimpleGrid, FormControl, Input } from "@chakra-ui/react";
import { Label } from "./styles";
import { EnglishExamLevel, User } from "types";
import { Table } from "components/Table";
import { TableElement } from "./types";

const header = [
  { label: "ID", field: "id" },
  { label: "Processo Seletivo", field: "process" },
  { label: "Empresa", field: "company" },
];

type Props = {
  user?: User;
  onClose: () => void;
};

export const Selection = ({ user }: Props) => {
  const [logic, setLogic] = useState("");
  const [english, setEnglish] = useState<EnglishExamLevel | string>();
  const [enrollments, setEnrollments] = useState<TableElement[]>([]);

  useEffect(() => {
    if (!user) return;
    const currentLogic = user.avaliacoes?.logica
      ? `${user.avaliacoes?.logica.percentual} %`
      : "Não realizado";
    const currentEnglish = user.avaliacoes?.ingles
      ? user.avaliacoes?.ingles.nivelamento
      : "Não realizado";
    setLogic(currentLogic);
    setEnglish(currentEnglish);

    const participations = user.participacoes || [];

    const parsed = participations.map((el) => ({
      id: el.id,
      process: el.nome,
      company: el.empresa.nome,
    }));
    setEnrollments(parsed);
  }, [user]);

  return (
    <SimpleGrid columns={1} spacing="10px">
      <SimpleGrid columns={2} spacing="10px">
        <FormControl>
          <Label>Avaliação de Lógica</Label>
          <Input size="sm" name="nome" value={logic} disabled />
        </FormControl>
        <FormControl>
          <Label>Avaliação de Inglês</Label>
          <Input size="sm" name="email" value={english} disabled />
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={1} spacing="10px">
        <Table header={header} elements={enrollments} />
      </SimpleGrid>
    </SimpleGrid>
  );
};
