import {
  Flex,
  Card,
  CardHeader,
  Heading,
  CardBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button
} from '@chakra-ui/react'
import { useSnackbar } from 'hooks'
import { useLocalStorage } from 'hooks/localStorage'
import { useEffect, useState } from 'react'
import { api } from 'shared/api'
import { Company, CreateTrail } from 'types/trail'

type NewTrailProps = {
  refreshGroupedTrails: () => void
}

export const NewTrail = ({ refreshGroupedTrails }: NewTrailProps) => {
  const toast = useSnackbar()
  const loggedUser = useLocalStorage()
  const [companies, setCompanies] = useState([] as Company[])
  const [selectedCompanyId, setSelectedCompanyId] = useState(0)
  const [newTrailName, setNewTraitName] = useState('')

  const createTrail = async () => {
    try {
      const newTrail: CreateTrail = {
        nome: newTrailName,
        empresa: { id: selectedCompanyId },
        criadaPor: {
          id: loggedUser.id
        }
      }
      await api.trail.create(newTrail)
      toast({
        title: `Trilha ${newTrailName} cadastrada com sucesso`,
        status: 'success'
      })
    } catch (error) {
      toast({
        title: 'Não foi possível cadastrar trilha.',
        status: 'error'
      })
    } finally {
      reset()
      refreshGroupedTrails()
    }
  }

  const reset = () => {
    setNewTraitName('')
    setSelectedCompanyId(0)
  }

  useEffect(() => {
    api.company.list().then(setCompanies)
  }, [])

  return (
    <Flex
      direction={'column'}
      gap={2}
      style={{ marginLeft: '30%' }}
      w='40%'
      mt={5}
    >
      <Card w='100%'>
        <CardHeader>
          <Heading size='sm'>Criar Trilha</Heading>
        </CardHeader>

        <CardBody>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              type='text'
              value={newTrailName}
              onChange={({ target }) => setNewTraitName(target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Empresa</FormLabel>
            <Select
              name='company'
              placeholder='Selecione...'
              value={selectedCompanyId.toString()}
              onChange={({ target }) =>
                setSelectedCompanyId(parseInt(target.value))
              }
            >
              {companies.map(company => (
                <option key={company.id} value={company.id}>
                  {company.nome}
                </option>
              ))}
            </Select>
          </FormControl>
          <Flex justify='flex-end' mt={4}>
            <Button size={'sm'} colorScheme='yellow' onClick={createTrail}>
              Salvar
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  )
}
